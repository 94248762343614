import { css, Theme } from "@emotion/react";
import { theme } from "styles/theme";

export const MDrawerStyle = {
  self: css`
    /*  */
  `,

  headerWrapper: (isFull: boolean) => css`
    padding: ${isFull ? "20px" : "0"} 8px;
  `,

  header: css`
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${theme.colors.main};
    height: 52px;
    border-radius: 6px;
  `,
  title: ({ colors }: Theme) => css`
    color: ${colors.white};
    font-size: 20px;
    flex: 1;
    text-align: center;
  `,

  backIcon: ({ colors }: Theme) => css`
    width: 20px;
    svg,
    path {
      fill: ${colors.white};
    }
  `,
};
