import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const LayoutStyle = {
  root: css`
    position: relative;
  `,
  main: () => css`
    max-width: 1024px;
    margin: auto;
    overflow-y: auto;
    background-color: ${theme.colors.neutrals[950]};
    height: calc(100vh - 60px);

    ::-webkit-scrollbar {
      display: none;
    }
  `,
};
