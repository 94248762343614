import * as slotsImageSidebar from "assets/common/images/game/desktop/page/slots/sidebar";
import * as slotsImageTitle from "assets/common/images/game/desktop/page/slots/title";
import { DetailProviderSlotType } from "types/game";

export const sidebarSlot: DetailProviderSlotType[] = [
  {
    provider_code: "DGG",
    provider_name: "DGG Game",
    pictureSidebar: slotsImageSidebar.desktopSlotsProviderDGG,
    pictureTitle: slotsImageTitle.desktopSlotsTitleDGG,
    backgroundColor: "#000",
    hoverBackgroundColor: "#cfab54",
  },
  {
    provider_code: "FTG",
    provider_name: "FTG Game",
    pictureSidebar: slotsImageSidebar.desktopSlotsProviderFTG,
    pictureTitle: slotsImageTitle.desktopSlotsTitleFTG,
    backgroundColor: "#64127e",
    hoverBackgroundColor: "#ff800d",
  },
  {
    provider_code: "FC",
    provider_name: "FC Game",
    pictureSidebar: slotsImageSidebar.desktopSlotsProviderFC,
    pictureTitle: slotsImageTitle.desktopSlotsTitleFC,
    backgroundColor: "#000",
    hoverBackgroundColor: "#fa0",
  },
  {
    provider_code: "RSG",
    provider_name: "RSG Game",
    pictureSidebar: slotsImageSidebar.desktopSlotsProviderRSG,
    pictureTitle: slotsImageTitle.desktopSlotsTitleRSG,
    backgroundColor: "#ff800d",
    hoverBackgroundColor: "#ff800d",
  },
  {
    provider_code: "CQ9",
    provider_name: "CQ9 Game",
    pictureSidebar: slotsImageSidebar.desktopSlotsProviderCQ9,
    pictureTitle: slotsImageTitle.desktopSlotsTitleCQ9,
    backgroundColor: "#f2941a",
    hoverBackgroundColor: "#fddb00",
  },

  {
    provider_code: "PGS2",
    provider_name: "PG Game",
    pictureSidebar: slotsImageSidebar.desktopSlotsProviderPG,
    pictureTitle: slotsImageTitle.desktopSlotsTitlePG,
    backgroundColor: "#000",
    hoverBackgroundColor: "#ff1c4f",
  },
  {
    provider_code: "JILI",
    provider_name: "JILI Game",
    pictureSidebar: slotsImageSidebar.desktopSlotsProviderJILI,
    pictureTitle: slotsImageTitle.desktopSlotsTitleJILI,
    backgroundColor:
      "linear-gradient(to bottom, #fff468 0, #f3b720 33%, #eaa000 66%)",
    hoverBackgroundColor: "#f3b720",
  },
  {
    provider_code: "JDB",
    provider_name: "JDB Game",
    pictureSidebar: slotsImageSidebar.desktopSlotsProviderJDB,
    pictureTitle: slotsImageTitle.desktopSlotsTitleJDB,
    backgroundColor: "#60bb00",
    hoverBackgroundColor: "#ffc600",
  },
  {
    provider_code: "BNG",
    provider_name: "BNG Game",
    pictureSidebar: slotsImageSidebar.desktopSlotsProviderBNG,
    pictureTitle: slotsImageTitle.desktopSlotsTitleBNG,
    backgroundColor: "#3ce897",
    hoverBackgroundColor: "#ffd000",
  },
  {
    provider_code: "MG",
    provider_name: "MG Game",
    pictureSidebar: slotsImageSidebar.desktopSlotsProviderMG,
    pictureTitle: slotsImageTitle.desktopSlotsTitleMG,
    backgroundColor: "#32b5e9",
    hoverBackgroundColor: "#21e519",
  },
  // {
  //   provider_code: "TP",
  //   provider_name: "TP Điện tử",
  //   pictureSidebar: slotsImageSidebar.desktopSlotsProviderTP,
  //   pictureTitle: slotsImageTitle.desktopSlotsTitleTP,
  //   backgroundColor: "#0550bb",
  //   hoverBackgroundColor:
  //     "linear-gradient(to bottom, #11dac9 10%, #389dff 55%, #0550bb 100%)",
  // },
  {
    provider_code: "PP",
    provider_name: "PP Điện tử",
    pictureSidebar: slotsImageSidebar.desktopSlotsProviderPP,
    pictureTitle: slotsImageTitle.desktopSlotsTitlePP,
    backgroundColor: "#ff800d",
    hoverBackgroundColor: "#ff800d",
  },

  // {
  //   provider_code: "SPRIBE",
  //   provider_name: "SPRIBE điện tử",
  //   pictureSidebar: slotsImageSidebar.desktopSlotsProviderSPRIBE,
  //   pictureTitle: slotsImageTitle.desktopSlotsTitleSPRIBE,
  //   backgroundColor: "#dbdeee",
  //   hoverBackgroundColor: "#2e2691",
  // },

  // {
  //   provider_code: "VA",
  //   provider_name: "VA Điện tử",
  //   pictureSidebar: slotsImageSidebar.desktopSlotsProviderVA,
  //   pictureTitle: slotsImageTitle.desktopSlotsTitleVA,
  //   backgroundColor: "#33b5e9",
  //   hoverBackgroundColor: "#0062d5",
  // },

  // {
  //   provider_code: "KA",
  //   provider_name: "KA Điện tử",
  //   pictureSidebar: slotsImageSidebar.desktopSlotsProviderKA,
  //   pictureTitle: slotsImageTitle.desktopSlotsTitleKA,
  //   backgroundColor: "#bc0007",
  //   hoverBackgroundColor: "#bc0007",
  // },
  // {
  //   provider_code: "PS",
  //   provider_name: "PS Điện tử",
  //   pictureSidebar: slotsImageSidebar.desktopSlotsProviderPS,
  //   pictureTitle: slotsImageTitle.desktopSlotsTitlePS,
  //   backgroundColor: "#333",
  //   hoverBackgroundColor: "#333",
  // },
  // {
  //   provider_code: "RICH88",
  //   provider_name: "RICH88 Điện tử",
  //   pictureSidebar: slotsImageSidebar.desktopSlotsProviderRICH88,
  //   pictureTitle: slotsImageTitle.desktopSlotsTitleRICH88,
  //   backgroundColor: "#601982",
  //   hoverBackgroundColor: "#ffd900",
  // },
  // {
  //   provider_code: "PT",
  //   provider_name: "PT Điện tử",
  //   pictureSidebar: slotsImageSidebar.desktopSlotsProviderPT,
  //   pictureTitle: slotsImageTitle.desktopSlotsTitlePT,
  //   backgroundColor: "#eee",
  //   hoverBackgroundColor: "#312f6e",
  // },

  // {
  //   provider_code: "NE",
  //   provider_name: "NE Điện tử",
  //   pictureSidebar: slotsImageSidebar.desktopSlotsProviderNE,
  //   pictureTitle: slotsImageTitle.desktopSlotsTitleNE,
  //   backgroundColor: "#68b022",
  //   hoverBackgroundColor: "#ff800d",
  // },
  // {
  //   provider_code: "HB",
  //   provider_name: "HB Điện tử",
  //   pictureSidebar: slotsImageSidebar.desktopSlotsProviderHB,
  //   pictureTitle: slotsImageTitle.desktopSlotsTitleHB,
  //   backgroundColor: "#ff800d",
  //   hoverBackgroundColor: "#ff800d",
  // },
  // {
  //   provider_code: "RTG",
  //   provider_name: "RTG Điện tử",
  //   pictureSidebar: slotsImageSidebar.desktopSlotsProviderRTG,
  //   pictureTitle: slotsImageTitle.desktopSlotsTitleRTG,
  //   backgroundColor: "#000",
  //   hoverBackgroundColor: "#b61f27",
  // },
  // {
  //   provider_code: "DGG",
  //   provider_name: "DGG Điện tử",
  //   pictureSidebar: slotsImageSidebar.desktopSlotsProviderDGG,
  //   pictureTitle: slotsImageTitle.desktopSlotsTitleDGG,
  //   backgroundColor: "#000",
  //   hoverBackgroundColor: "#cfab54",
  // },
  // {
  //   provider_code: "RSG",
  //   provider_name: "RSG Điện tử",
  //   pictureSidebar: slotsImageSidebar.desktopSlotsProviderRSG,
  //   pictureTitle: slotsImageTitle.desktopSlotsTitleRSG,
  //   backgroundColor: "#ff800d",
  //   hoverBackgroundColor: "#ff800d",
  // },
  // {
  //   provider_code: "GEM",
  //   provider_name: "GEM Điện tử",
  //   pictureSidebar: slotsImageSidebar.desktopSlotsProviderGEM,
  //   pictureTitle: slotsImageTitle.desktopSlotsTitleGEM,
  //   backgroundColor: "#ff800d",
  //   hoverBackgroundColor: "#ff800d",
  // },
  // {
  //   provider_code: "T1",
  //   provider_name: "T1 Điện tử",
  //   pictureSidebar: slotsImageSidebar.desktopSlotsProviderT1,
  //   pictureTitle: slotsImageTitle.desktopSlotsTitleT1,
  //   backgroundColor: "#ff800d",
  //   hoverBackgroundColor: "#ff800d",
  // },
];
