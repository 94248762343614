import * as slots from "assets/common/images/game/desktop/menu/slots";
import { SubGameType } from "types/game";

export const dataSlots: SubGameType[] = [
  {
    game_code: "",
    picture: slots.desktopSlotsDGG,
    provider_code: "DGG",
    type: "SL",
    name: "",
    featured: "",
    new_arrive: "",
  },
  {
    game_code: "",
    picture: slots.desktopSlotsFG,
    provider_code: "FTG",
    type: "SL",
    name: "",
    featured: "",
    new_arrive: "",
  },
  {
    game_code: "",
    picture: slots.desktopSlotsFC,
    provider_code: "FC",
    type: "SL",
    name: "",
    featured: "",
    new_arrive: "",
  },
  {
    game_code: "",
    picture: slots.desktopSlotsRSG,
    provider_code: "RSG",
    type: "SL",
    name: "",
    featured: "",
    new_arrive: "",
  },
  {
    game_code: "",
    picture: slots.desktopSlotsCQ9,
    provider_code: "CQ9",
    type: "SL",
    name: "",
    featured: "",
    new_arrive: "",
  },
  {
    game_code: "",
    picture: slots.desktopSlotsPGS,
    provider_code: "PGS2",
    type: "SL",
    name: "",
    featured: "",
    new_arrive: "",
  },
  {
    game_code: "",
    picture: slots.desktopSlotsJILI,
    provider_code: "JILI",
    type: "SL",
    name: "",
    featured: "",
    new_arrive: "",
  },
  {
    game_code: "",
    picture: slots.desktopSlotsJDB,
    provider_code: "JDB",
    type: "SL",
    name: "",
    featured: "",
    new_arrive: "",
  },
  {
    game_code: "",
    picture: slots.desktopSlotsBNG,
    provider_code: "BNG",
    type: "SL",
    name: "",
    featured: "",
    new_arrive: "",
  },
  {
    game_code: "",
    picture: slots.desktopSlotsMG,
    provider_code: "MG",
    type: "SL",
    name: "",
    featured: "",
    new_arrive: "",
  },
  {
    game_code: "",
    picture: slots.desktopSlotsPP,
    provider_code: "pp",
    type: "SL",
    name: "",
    featured: "",
    new_arrive: "",
  },
];
