import { createAsyncThunk } from "@reduxjs/toolkit";
import { AuthApi } from "api/auth";
import { LoginType, RegisterType } from "api/auth/type";
import { getProfile } from "store/user/user.thunk";

export const signInWithCredentials = createAsyncThunk(
  "auth/signInWithCredentials",
  async (formData: LoginType, { rejectWithValue, dispatch }) => {
    try {
      const response = await AuthApi.login(formData.username, formData);
      const responseData = response.data;
      if (!responseData.status) {
        dispatch(getProfile(formData.username));
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const registerMemberDetail = createAsyncThunk(
  "auth/registerMemberDetail",
  async (formData: RegisterType, thunkApi) => {
    const response = await AuthApi.register(formData.username, formData);
    const responseData = response.data;

    if (!responseData.status) {
      const { dispatch } = thunkApi;
      dispatch(
        signInWithCredentials({
          username: formData.username,
          password: formData.password,
        }),
      );
    }

    return responseData;
  },
);
