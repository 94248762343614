import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";

import { MessagePayload, MessageResponse } from "./type";

export const messageApi = {
  async MemberMessage(username: string, payload: MessagePayload) {
    const response = await axiosRequest.post<MessageResponse>(
      "",
      payloadHelper("MemberMessage", username, payload),
    );
    return response.data;
  },
};
