/** @jsxImportSource @emotion/react */

import { Checkbox, Modal } from "antd";
import { ModalTypeValue } from "enums/modalType";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { selectOpenModal, setOpenModal } from "store/master";

import { ModalMobileNotificationStyle } from "./style.index";

const ModalMobileNotification = () => {
  const dispatch = useAppDispatch();

  const openModal = useAppSelector(selectOpenModal);
  return (
    <Modal
      title="NEW NOTIFICATION"
      open={openModal === ModalTypeValue.MOBILE_NEWS}
      onCancel={() => dispatch(setOpenModal(ModalTypeValue.CLOSE))}
      css={ModalMobileNotificationStyle.wrapper}
      footer={
        <div css={ModalMobileNotificationStyle.close}>
          <div css={ModalMobileNotificationStyle.label}>
            <span onClick={() => dispatch(setOpenModal(ModalTypeValue.CLOSE))}>
              Close
            </span>
          </div>
          <div css={ModalMobileNotificationStyle.checkBox}>
            <Checkbox /> do not show again
          </div>
        </div>
      }
    >
      <div css={ModalMobileNotificationStyle.mainContent}>
        <img
          src="https://0kqo9br0eyii.jquut.net/system-assets/PortalManagement/Image/Announcement/22f31f57a2804b9e8b7cd7a7cdfacf59.jpg"
          alt=""
        />
        <p>
          <strong>
            JMC88 ANNOUNCEMENT: PROMOTIONAL REWARDS &nbsp; PROFITABLE INVESTMENT
            FROM CASINO EVERY FRIDAY.
          </strong>
          <br />
          Members participating in JMC88 each week participate in betting at
          [Casino],&nbsp;Have a total deposit of 1 million or more and valid
          bets 3 times will receive weekly bonus up to&nbsp;7,888&nbsp;point.
          Don't forget to text&nbsp;
          <strong>friend</strong>&nbsp;Come join the investment and earn profits
          every time week!
        </p>
        <p>
          Bonuses are counted and distributed automatically into
          envelopes&nbsp;Li Hi, valid for 7 days, expiration is considered a
          refusal to receive the promotion forever.
        </p>
      </div>
    </Modal>
  );
};

export default ModalMobileNotification;
