/** @jsxImportSource @emotion/react */

import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import { LoadingCustomStyle } from "./index.style";

export interface Props {
  center?: boolean;
  fontSize?: number;
  width?: number | string;
  height?: number | string;
}

const LoadingCustom = (props: Props) => {
  const { fontSize = 40 } = props;
  return (
    <div css={LoadingCustomStyle.self(props)}>
      <Spin
        indicator={
          <LoadingOutlined style={{ fontSize: `${fontSize}px` }} spin />
        }
      />
    </div>
  );
};

export default LoadingCustom;
