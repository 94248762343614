import { useEffect } from "react";
import { isMobile } from "router";

interface DenpendenciesType<T> {
  dependencyList: T[];
}
export const useScrollTop = <T>(
  dependencies: DenpendenciesType<T> = { dependencyList: [] },
) => {
  useEffect(() => {
    if (isMobile) {
      document?.getElementById("main")?.scroll({
        top: 0,
        behavior: "smooth",
      });
      return;
    }

    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, dependencies.dependencyList);
};
