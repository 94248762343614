/** @jsxImportSource @emotion/react */

import { Modal } from "antd";

import { ModalRuleRegisterStyle } from "./style.index";

interface Props {
  isOpenModal: boolean;
  isCancel: () => void;
}

const ModalRuleRegister = ({ isCancel, isOpenModal }: Props) => {
  return (
    <>
      <Modal
        css={ModalRuleRegisterStyle.wrapper}
        title={null}
        open={isOpenModal}
        onCancel={isCancel}
        centered
        footer={null}
      >
        <div css={ModalRuleRegisterStyle.mainContent}>
          <div css={ModalRuleRegisterStyle.header}>
            User registration agreement
          </div>

          <div css={ModalRuleRegisterStyle.content}>
            <p>
              Congratulations on becoming a new member of JMC88 - a reputable
              bookmaker Leading online game market, fast deposit and withdrawal,
              great incentives, is The ideal place for players to participate in
              entertainment, register and log in fast, in addition there are
              many attractive game products. Let's Quickly join to experience
              the number 1 top entertainment service!
            </p>
            <p>
              After you successfully register for a member account, you will Use
              all of our company's services. When using those services, it means
              that you have agreed and complied with them The regulations that
              our company sets out such as (Game rules Casino, sports betting
              rules, announcements, items of note) and some related notes. All
              related services such as products products, customer service,
              software systems, betting odds, games, casino, our company's
              network structure is monitored by Gambling and Amusement
              Commission of the Philippines. So when precious Customers using
              our services must comply with them legal ordinances of the native
              country, if there is any violation are not within the scope of our
              service responsibility.
            </p>
            <p>
              As for the service items that our company has provided, as follows
              When you agree to register, please follow the order Instructions
              to provide personal information accurately and most authentic;
              When your personal information is not accurate If so, please
              contact 24/7 online Customer Care Update to maintain the most
              accurate and complete personal information. If the registered
              information is incorrect or information is impersonated of another
              person that harms that person's rights or vi If you violate the
              law, you must be fully responsible the law regarding that act.
              Once you have provided fish information Inaccurate personal
              information or irregularities in personal information Refusing to
              update results in mismatching personal information initial
              registration, our company has the right to terminate Your
              membership terms and benefits.
            </p>
            <p>
              Members must keep their account information and password
              confidential shall be responsible for all actions after the
              account and password is logged in. To ensure the rights of the
              association itself members, please do not disclose or provide your
              account and password to others know and should not be lent or
              transferred to others others use in any form.&nbsp;We are
              committed to protecting the customer privacy to provide safe games
              Overall, the information collected from this website will help We
              serve you with the best services. We will not sell or rent
              customer information to third parties Third, the personal
              information provided by customers will be processed Secure
              encryption using SSL128 technology and information will be stored
              Very safe, no leaks when operating the system. In case Have the
              opportunity to contact customers' personal information and receive
              support Help from partner fees is still subject to separate terms
              advice that we offer.
            </p>
            <p>
              Members are strictly prohibited from registering an account more
              than once, for any reason For any reason, we may block or close
              any account. Every player, every address, every email address,
              every phone number, same payment card/credit card number and
              computer environment shared use (such as Internet cafes, public
              places computer application, etc.) has only one account
              data.&nbsp;If successful Members intentionally create multiple
              accounts, JMC88 has the right to cancel or&nbsp;freeze the account
              balance without notice first.&nbsp;Regardless of whether it is an
              individual or a group, if there is any threat any threats or abuse
              towards JMC88, we reserve the right to cancel and withdraw player
              account.
            </p>
            <p>
              To avoid controversy when betting on various types of games As
              well as soccer competitions, we respectfully hope that members
              should participate Refer to the relevant rules for details, once
              you are a member Once you start using this service, you will be
              deemed to have agreed to it all of our regulations.
            </p>
            <p>
              Some national regions have unclear rules regarding counting The
              legality of online gambling, some countries even have regulations
              specifically instructs that this type of gambling is illegal. We
              do not intentionally invite any individuals to participate in
              using our services We across regions and countries have
              regulations of this type illegal. Individuals using this service
              must clearly identify that Is it illegal or illegal in the region
              or country where the service is being used? no and take full
              responsibility. Individuals using the service This should comply
              with relevant local ordinances, If there is any violation, we will
              not be responsible.
            </p>
            <p>
              When a player's registration information is disputed, to ensure
              benefit both parties and prevent identity theft, reserves the
              right to require customers to provide us with files fully and
              effectively, and determine whether the customer has enough qualify
              for any of our Offers.
            </p>
            <p>In any case, the final decision belongs to you JMC88</p>
            <p>&nbsp;</p>
          </div>

          <div css={ModalRuleRegisterStyle.footer}>
            <span onClick={isCancel}>I understand</span>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalRuleRegister;
