import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";

import {
  DepositFastSubmissionForm,
  DepositFormSettingResponse,
  DepositOfflineSubmissionForm,
  DepositOnlineSubmissionForm,
  DepositResponse,
} from "./type";

export const DepositApi = {
  getDepositForm: (username: string) =>
    axiosRequest.post<DepositFormSettingResponse>(
      "",
      payloadHelper("DepositForm", username, {
        username,
      }),
    ),
  OnlineDepositSubmission: (
    typename: string,
    payload: DepositOnlineSubmissionForm,
  ) =>
    axiosRequest.post<DepositFastSubmissionForm>(
      "/",
      payloadHelper("GatewayDeposit", typename, payload),
    ),
  depositSubmission: (
    typename: string,
    payload: DepositOfflineSubmissionForm,
  ) =>
    axiosRequest.post<DepositResponse>(
      "/",
      payloadHelper("Deposit", typename, payload),
    ),
};
