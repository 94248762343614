import { handleResetData } from "helpers/handleResetData";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useAppDispatch } from "./app-hook";

export const useAppLogout = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return useCallback(() => {
    handleResetData(dispatch, navigate);
  }, []);
};
