import { createSlice } from "@reduxjs/toolkit";
import { ApplicationRootState } from "types";
import { MessageStateType } from "types/message";

import { extraReducers } from "./message.extraReducers";

export const initialState: MessageStateType = {
  isLoading: false,
  dataMessage: {
    pageSize: 10,
    totalPages: 0,
    currentPage: 1,
    data: [],
    totalCount: 0,
  },
};

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {},
  extraReducers,
});

export const selectDataMessage = (state: ApplicationRootState) =>
  state.message.dataMessage;

export const selectIsLoading = (state: ApplicationRootState) =>
  state.message.isLoading;

export default messageSlice.reducer;
