import { createAsyncThunk } from "@reduxjs/toolkit";
import { AnnouncementApi } from "api/announcement";
import { AnnouncementFormType } from "api/announcement/type";

export const getAnnouncement = createAsyncThunk(
  "announcement/getAnnouncement",
  async (formData: AnnouncementFormType, { rejectWithValue }) => {
    try {
      const response = await AnnouncementApi.getAnnouncement(formData);
      return { ...response.data, type: formData.type, page: formData.page };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
