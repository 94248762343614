import { css } from "@emotion/react";
import { media } from "styles/breakpoints";

export const AddBankStyle = {
  modalContainer: css`
    ${media.md} {
      max-width: 100vw !important;
    }
    .ant-modal-content {
      border-radius: 12px !important;
      overflow: hidden;
      padding: 0px !important;
      background-color: transparent !important;
      ${media.sm} {
        border-radius: 0px !important;
        height: 100vh !important;
        width: 100vw !important;
        overflow-y: auto;
        margin: 0px !important;
        padding: 0px !important;
      }
    }
    .ant-modal-footer {
      display: none !important;
    }
  `,
};
