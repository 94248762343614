import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";

import {
  MemberOrderPayload,
  MemberOrderRebateResponseType,
  MemberOrderResponseType,
  MemberPromotionPayload,
  MemberPromotionResponse,
} from "./type";

export const ordersApi = {
  async memberOrder(username: string, payload: MemberOrderPayload) {
    const response = await axiosRequest.post<MemberOrderResponseType>(
      "",
      payloadHelper("MemberOrder", username, payload),
    );
    return response.data;
  },

  async memberOrderRebate(username: string, payload: MemberOrderPayload) {
    const response = await axiosRequest.post<MemberOrderRebateResponseType>(
      "",
      payloadHelper("MemberOrder", username, payload),
    );
    return response.data;
  },

  async memberPromotion(username: string, payload: MemberPromotionPayload) {
    const response = await axiosRequest.post<MemberPromotionResponse>(
      "",
      payloadHelper("MemberPromotion", username, payload),
    );
    return response.data;
  },
};
