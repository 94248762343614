import { useAppSelector } from "hooks/app-hook";
import { selectDetailProviderSlot } from "store/game";

import { RenderGameSlots } from "./RenderGameSlots";

const GameSlotPage = () => {
  const detailProviderSlot = useAppSelector(selectDetailProviderSlot);

  return <RenderGameSlots detailProvider={detailProviderSlot} />;
};

export default GameSlotPage;
