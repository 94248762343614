import { css } from "@emotion/react";
import { ImageBgFormAuth } from "assets/desktop/images/homepage";

export const WrapperAuthFormStyle = {
  index: css`
    background: #e7e7e7;
    border-radius: 8px;
    position: relative;
  `,
  closeButton: css`
    position: absolute;
    right: -16px;
    top: -16px;
    cursor: pointer;
  `,
  content: css`
    display: flex;
    flex-direction: row;
  `,
  leftForm: css`
    flex: 1;
  `,
  header: css`
    border-bottom: 1px solid #ccc;
    padding: 0 30px;
    .title-box {
      padding: 16px 0;
      border-bottom: 2px solid #fd7900;
      width: fit-content;

      span {
        color: #fd7900;
        font-size: 20px;
        font-weight: 700;
        line-height: 22px; /* 110% */
      }
    }
  `,
  form: css``,
  rightBg: css`
    background-image: url(${ImageBgFormAuth});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-bottom-right-radius: 8px;
  `,
};
