import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { GetPromotionListResponse } from "api/retrive/type";
import { ResponseCode } from "contants/response";
import { NoInfer } from "react-redux";
import { RetriveStateType } from "types/retrive";

import { getPromotionList } from "./retrive.thunk";

export const extraReducers = (
  builder: ActionReducerMapBuilder<NoInfer<RetriveStateType>>,
) => {
  builder
    .addCase(getPromotionList.pending, (state: RetriveStateType) => {
      state.promotions.isLoading = true;
    })
    .addCase(
      getPromotionList.fulfilled,
      (
        state: RetriveStateType,
        action: PayloadAction<GetPromotionListResponse>,
      ) => {
        const { status, data } = action.payload;
        state.promotions.isLoading = false;
        if (status === ResponseCode.SUCCESS) {
          state.promotions.data = data;
        }
      },
    );
};
