import { css } from "@emotion/react";
import { theme } from "styles/theme";
export const HeaderWebsiteStyle = {
  header: css`
    background: #333;
    height: 100px;
  `,
  content: css`
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  `,
  left: css`
    display: flex;
    flex-direction: row;
    align-items: center;

    a {
      text-decoration: none;
    }

    .logoApp {
      width: 160px;
      height: 76px;
      object-fit: contain;
      margin-right: 8px;
    }
    .img-header {
      width: 265px;
      height: 92.47px;
      object-fit: contain;
      margin-right: 8px;
    }
    .sub-logoApp {
      width: 265px;
      height: 92px;
      object-fit: contain;
    }
  `,
  right: css``,
  right_top: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .translate {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 24px;
      gap: 6px;

      span {
        color: ${theme.colors.white};
        font-size: 20px;
        font-weight: 400;
        font-family: timenew;
      }

      img {
        width: 18px;
        height: 18px;
        object-fit: contain;
      }
    }
    .item {
      display: flex;
      align-items: center;
      justify-content: center;

      gap: 10px;

      border-left: 1px solid ${theme.colors.white};
      padding-left: 24px;

      svg {
        width: 16px;
        height: 16px;
      }
      &.no-border {
        border-left: 0;
        padding-left: 0;
        padding-right: 24px;

        span {
          font-size: 16px;
        }
      }

      span {
        color: ${theme.colors.white};
        font-size: 20px;
        font-weight: 400;
        font-family: timenew;
      }
    }

    .item-date {
      .date {
        color: ${theme.colors.white};
        font-size: 12px;
        font-weight: 400;
      }
    }
  `,
  right_bottom: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
    .button-custom {
      border-radius: 8px;
      display: flex;
      width: 189px;
      height: 34px;
      text-transform: uppercase;
      padding: 11px 17.3px 8px 17.48px;
      justify-content: center;
      align-items: center;
    }
    .login {
      background-color: ${theme.colors.secondary[1200]};

      border: 0;
      margin-right: 20px;

      span {
        color: ${theme.colors.white};
        font-size: 18px;
        font-weight: bold;
        font-family: timenew;
      }
    }
    .register {
      border: 0;
      border-radius: 8px;
      background: transparent;
      margin-right: 20px;
      background-color: ${theme.colors.secondary[1100]};

      span {
        color: ${theme.colors.white};
        font-size: 18px;
        font-weight: bold;
        font-family: timenew;
      }
    }
  `,
  infoProfile: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 16px;

    .info {
      color: ${theme.colors.white};
      margin-right: 24px;
      font-size: 14px;
      line-height: 18px;
      p {
        &:nth-of-type(2) {
          display: flex;
          align-items: center;

          gap: 4px;
        }
      }
      .wallet {
        color: #ff9800;
        display: inline-block;
        max-width: 5em;
        overflow: hidden;

        display: flex;
        align-items: center;
        .icon-sync {
          margin-left: 6px;
          cursor: pointer;
        }
      }
    }
    .dropdown {
      width: 120px;
      height: 40px;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      border: none;
      border-radius: 7px;
      background-color: #ff4435;
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: center;
      .icon {
        margin-left: 8px;
      }
    }
  `,
};
