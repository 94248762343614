import { icons } from "assets/mobile";
import { PATH, URL_DOWNLOAD_APP, userPath } from "contants/path";

export interface ItemBottomTab {
  label: string;
  icon: React.ReactNode;
  link: string;
  key: string;
}
export const menuBottomTabs: ItemBottomTab[] = [
  {
    label: "Home",
    key: "Home",
    icon: <icons.HomeBottomTab />,
    link: PATH.Home,
  },
  {
    label: "App",
    key: "APP",
    icon: <icons.AgencyBottomTab />,
    link: URL_DOWNLOAD_APP,
  },
  {
    label: "Promotion",
    key: "Promotion",
    icon: <icons.PromotionBottomTab />,
    link: PATH.Promotion,
  },
  {
    label: "Support",
    key: "Support",
    icon: <icons.LiveChatBottomTab />,
    link: "#",
  },
  {
    label: "Account",
    key: "Account",
    icon: <icons.UserBottomTab />,
    link: userPath,
  },
];
