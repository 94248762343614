/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form, Image, message, Modal } from "antd";
import { LoginFormType, LoginResponse } from "api/auth/type";
import { IconEyeSlashSolid, IconEyeSolid } from "assets/common/icons";
import {
  ModalIconPassword,
  ModalKeySignIn,
  ModalLogoVillarreal,
  ModalLogoVillarrealOk,
  ModalUserIcon,
} from "assets/common/images/modal";
import { Logo } from "assets/desktop/images";
import InputCustomAuth from "components/common/InputCustomAuth";
import InputPassword from "components/desktop/base/InputPassword";
import { LIST_BACKGROUND_CAPTCHA } from "contants/captcha";
import { ResponseCode } from "contants/response";
import { ModalType, ModalTypeValue } from "enums/modalType";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { selectIsLoading } from "store/auth";
import { signInWithCredentials } from "store/auth/auth.thunk";
import { selectOpenModal, setOpenModal, setOpenModalNoti } from "store/master";
import { theme } from "styles/theme";
import * as yup from "yup";

import ModalNoti from "../ModalNoti";
import ModalSignInStyle from "./style.index";

interface State {
  valueCaptcha: string;
  numberBg: number;
  title: string;
}

const schema = yup
  .object({
    username: yup.string().trim().required("Please enter account"),
    password: yup.string().trim().required("Please enter a password"),
    captcha: yup
      .string()
      .trim()
      .required(
        "The confirmation code cannot be left blank, please get the confirmation code",
      ),
  })
  .required();

const ModalSignin = () => {
  const dispatch = useAppDispatch();

  const openModal = useAppSelector(selectOpenModal);

  const isLoading = useAppSelector(selectIsLoading);

  const [state, setState] = useState<State>({
    valueCaptcha: "",
    numberBg: 0,
    title: "",
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    getValues,
  } = useForm<LoginFormType>({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const onSubmit: SubmitHandler<LoginFormType> = async (
    data: LoginFormType,
  ) => {
    if (state.valueCaptcha !== data.captcha) {
      setState((prev) => ({
        ...prev,
        title: "Verification code error or input error, please try again",
      }));
      return;
    }

    setState((prev) => ({
      ...prev,
      title: "",
    }));

    const res = await dispatch(signInWithCredentials(data));

    const resData = res.payload as LoginResponse;
    if (resData.status !== ResponseCode.SUCCESS) {
      setState((prev) => ({
        ...prev,
        title: "Invalid account or password. Please check again!",
      }));
      return;
    }

    message.success({
      key: "Login",
      content: "Login is success!!",
    });
    reset();
    dispatch(setOpenModal(ModalTypeValue.CLOSE));
    return;
  };
  const generateRandomNumbers = () => {
    const newRandomNumbers: number[] = [];

    while (newRandomNumbers.length < 4) {
      const randomNumber = Math.floor(Math.random() * 10);
      newRandomNumbers.push(randomNumber);
    }

    setState((prev) => ({
      ...prev,
      numberBg: Math.floor(Math.random() * 7),
      valueCaptcha: newRandomNumbers.join(""),
    }));
  };

  const renderTitle = () => {
    const form = getValues();

    if (!form.username) {
      setState((prev) => ({
        ...prev,
        title: "Please enter your account",
      }));
      return;
    }
    if (!form.password) {
      setState((prev) => ({
        ...prev,
        title: "Please enter a password",
      }));
      return;
    }
    if (!form.captcha) {
      setState((prev) => ({
        ...prev,
        title:
          "The confirmation code cannot be left blank, please get the confirmation code",
      }));
      return;
    }
    if (errors.username) {
      setState((prev) => ({
        ...prev,
        title: errors.username?.message as string,
      }));
      return;
    }

    if (errors.password || !form.password) {
      setState((prev) => ({
        ...prev,
        title: errors.password?.message as string,
      }));
      return;
    }

    setState((prev) => ({
      ...prev,
      title: errors.captcha?.message as string,
    }));
    return;
  };

  return (
    <Modal
      open={openModal === ModalTypeValue.LOGIN}
      onCancel={() => dispatch(setOpenModal(ModalTypeValue.CLOSE))}
      css={ModalSignInStyle.wrapper}
      footer={<></>}
      centered
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 15px;
        `}
      >
        <Image
          src={Logo}
          height={80}
          css={ModalSignInStyle.logo}
          alt=""
          preview={false}
        />
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
            `}
          >
            <p
              css={css`
                font-size: 14px;
                margin: 0 0 10px;
                color: white;
                font-weight: 700;
              `}
            >
              Official Partner
            </p>
            <p
              css={css`
                color: white;
                font-size: 14px;
                font-weight: 300;
              `}
            >
              Villarreal CF & OKVIP
            </p>
          </div>

          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <div
              css={css`
                border-right: 1px solid #2d7699;
                padding-right: 18px;
              `}
            >
              <Image
                src={ModalLogoVillarreal}
                alt=""
                height={65}
                width={51}
                preview={false}
              />
            </div>
            <div
              css={css`
                padding-left: 18px;
                max-height: 100px;
              `}
            >
              <Image
                src={ModalLogoVillarrealOk}
                alt=""
                height={65}
                width={175}
                preview={false}
              />
            </div>
          </div>
        </div>

        <Form onSubmitCapture={handleSubmit(onSubmit)}>
          <div
            css={css`
              margin-top: 10px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              width: 412px;
              gap: 20px;

              & .ant-form-item-control-input {
                .ant-input-password {
                  background-color: #505050;
                  border-radius: 30px;
                  .ant-input-prefix {
                    margin-left: 10px;
                  }
                  .ant-input {
                    &::placeholder {
                      color: white;
                    }
                    color: white;
                    margin-left: 8px;
                    font-size: 16px;
                  }
                }
                & .ant-input-affix-wrapper {
                  height: 46px !important;
                  border-color: transparent !important;
                  color: white;

                  input {
                    border-radius: 0;
                    margin-right: 10px;
                    background-color: #505050;
                  }
                }
              }
            `}
          >
            <InputCustomAuth
              disabled={isLoading}
              placeholder={"Please enter your account name"}
              name={"username"}
              control={control}
              margin_bottom="12px"
              id={"username"}
              // errors={errors.username?.message}
              // validate_status={errors.username ? "error" : "success"}
              prefix={
                <Image
                  src={ModalUserIcon}
                  alt=""
                  height={30}
                  width={30}
                  preview={false}
                />
              }
            />

            <InputPassword
              disabled={isLoading}
              placeholder={"Please enter password"}
              name={"password"}
              control={control}
              margin_bottom="12px"
              id={"password"}
              type="password"
              // errors={errors.password?.message}
              // validate_status={errors.password ? "error" : "success"}
              prefix={
                <Image
                  src={ModalIconPassword}
                  alt=""
                  height={30}
                  width={30}
                  preview={false}
                />
              }
              iconRender={(visible) =>
                visible ? (
                  <IconEyeSolid css={ModalSignInStyle.eye} />
                ) : (
                  <IconEyeSlashSolid css={ModalSignInStyle.eye} />
                )
              }
            />
            <InputCustomAuth
              onClick={generateRandomNumbers}
              disabled={isLoading}
              placeholder={"Please enter verification code"}
              name={"captcha"}
              control={control}
              margin_bottom="12px"
              id={"captcha"}
              // errors={errors.captcha?.message}
              // validate_status={errors.captcha ? "error" : "success"}
              prefix={
                <Image
                  src={ModalKeySignIn}
                  alt=""
                  height={30}
                  width={30}
                  preview={false}
                />
              }
              suffix={
                state.valueCaptcha && (
                  <div
                    css={ModalSignInStyle.blockCaptcha(
                      LIST_BACKGROUND_CAPTCHA[state.numberBg],
                    )}
                    onClick={generateRandomNumbers}
                  >
                    <span>{state.valueCaptcha}</span>
                  </div>
                )
              }
            />

            <div>
              <p
                css={css`
                  font-size: 16px;
                  text-align: center;
                  line-height: 1.2;
                  font-weight: 400;
                  color: white;
                `}
              >
                Please take care of your account information <br />
                For security for your entire account
              </p>
            </div>

            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 20px;
              `}
            >
              <Button
                css={css`
                  width: 100%;
                  height: 50px;
                  color: white;
                  border-radius: 50px;
                  background-color: ${theme.colors.main};
                  text-transform: uppercase;
                  font-size: 20px;
                  border-color: transparent !important;
                  :hover {
                    color: white !important;
                    border-color: transparent !important;
                  }
                `}
                htmlType="submit"
                onClick={() => {
                  renderTitle();
                  dispatch(setOpenModalNoti(ModalType.NOTI));
                }}
              >
                Login
              </Button>
              <Button
                css={css`
                  width: 100%;
                  height: 50px;
                  color: white;
                  border-radius: 50px;
                  background-color: #404040;
                  text-transform: uppercase;
                  font-size: 20px;
                  border-color: transparent !important;
                  :hover {
                    color: white !important;
                    border-color: transparent !important;
                  }
                `}
                onClick={() => {
                  dispatch(setOpenModal(ModalTypeValue.REGISTER));
                }}
              >
                Register
              </Button>
            </div>

            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 20px;
              `}
            >
              {/* <div
                onClick={() => dispatch(setOpenModalForgot(ModalType.FORGOT))}
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  color: white;
                  background-color: #404040;
                  border-radius: 20px;
                  font-weight: 500;
                  font-size: 16px;
                  width: 227px;
                  height: 62px;
                `}
              >
                <p>Recover password</p>
                <p>Via Phone Number</p>
              </div>

              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  color: white;
                  background-color: #404040;
                  border-radius: 20px;
                  font-weight: 500;
                  font-size: 16px;
                  width: 227px;
                  height: 62px;
                `}
                onClick={() => dispatch(setOpenModalForgot(ModalType.FORGOT))}
              >
                <p>Recover password</p>
                <p>Via Email Address</p>
              </div> */}
              <div css={ModalSignInStyle.forgetButton}>
                <div
                  onClick={() =>
                    dispatch(setOpenModal(ModalTypeValue.FORGET_PASSWORD))
                  }
                  className="link"
                >
                  Forgot password ?
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
      {!!state.title && <ModalNoti title={state.title} />}
    </Modal>
  );
};

export default ModalSignin;
