import { css } from "@emotion/react";

export const ModalForgotStyle = {
  wrapper: css`
    /* width: 300px !important;
    height: 185px !important; */

    .ant-modal-content {
      padding: 0 !important;
    }
  `,

  mainContent: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
  `,
  header: css`
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    background-color: #f5f5f5;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    span {
      color: #333333;
      font-size: 14px;
      line-height: 1.42857143;
    }
  `,
  body: css`
    display: flex;
    padding: 15px;
  `,
  footer: css`
    height: 50px;
    display: flex;
    justify-content: flex-end;

    div {
      width: 83px;
      height: 34px;
      padding: 6px 12px !important;
      background-color: #337ab7;
      color: white;

      font-weight: normal;
      text-align: center;
      white-space: nowrap;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      border-radius: 4px;

      &:hover {
        background-color: #286090;
      }
    }
  `,
};
