import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";
import {
  CategoryDetailsResponseType,
  LoginGameResponseType,
  LoginGameType,
  SubGamePayLoad,
  SubGameResponse,
} from "types/game";

export const gameApi = {
  loginGame: (username: string, payload: LoginGameType) =>
    axiosRequest.post<LoginGameResponseType>(
      "",
      payloadHelper("LoginGame", username, payload),
      {
        cancelToken: payload.source?.token,
      },
    ),

  getSubGameList: (payload: SubGamePayLoad) =>
    axiosRequest.post<SubGameResponse>(
      "",
      payloadHelper("GetSubGameList", "", payload),
    ),

  getGameList: (game_type: string) =>
    axiosRequest.post<CategoryDetailsResponseType>(
      "",
      payloadHelper("GetGameList", game_type, { game_type }),
    ),
};
