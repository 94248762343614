import { css, keyframes, Theme } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

const scrollingText = keyframes`
  0% {
    transform: translateX(30%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

export const NotifyMarqueStyle = {
  item: css`
    /* min-width: 1200px; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    border-radius: 1.5rem;
    padding: 0.4rem;

    overflow: hidden;
    img {
      width: 16px !important;
    }

    & .marquee-container {
      overflow: hidden;
    }
  `,
  text: css`
    overflow: hidden;
    width: 100%;
    margin: 0 10px;
    height: 100%;
    display: flex;

    span {
      overflow: hidden;
      white-space: nowrap;

      display: flex;
      min-width: max-content;
      gap: 50px;
      animation: ${scrollingText} 45s linear infinite;
      .width-text {
        min-width: max-content;

        &:hover {
          text-decoration: underline;
        }
      }

      align-items: center;
      width: 100%;
      color: ${theme.colors.white};

      &:hover {
        animation-play-state: paused;
      }
    }
  `,

  marqueeItem: ({ colors }: Theme) => css`
    color: ${colors.white};
    font-size: 13px;
    margin-right: 15px;
    ${media.md} {
      font-size: 12px;
    }
  `,
};
