import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const ModalMobileNotificationStyle = {
  wrapper: css`
    top: 330px;
    left: 50%;
    transform: translate(-50%, -50%);
    .ant-modal {
      &-content {
        padding: 0;
        background-color: transparent;
        box-shadow: unset;
      }
      &-header {
        margin-bottom: unset;
        background-color: #6bb0c8;
      }
      &-footer {
        margin-top: unset;
      }
      &-title {
        color: ${theme.colors.white};
        text-align: center;
        padding: 10px 0;
        font-size: 18px;
        font-weight: 700;
      }
      &-close {
        top: 11px;
        color: white;
        font-weight: 700;
      }
    }
  `,

  close: css``,

  label: css`
    background-color: #6bb0c8;
    text-align: center;

    padding: 10px 0;

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    span {
      color: white;
      font-size: 18px;
      font-weight: 600;
    }
  `,

  checkBox: css`
    background-color: transparent;

    color: white;
  `,

  mainContent: css`
    background-color: white;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `,
};
