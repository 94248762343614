const MESSAGE = {
  notification: "Notification",
  confirm: "Confirm",
  updateSuccessfully: "Update successful",

  loginGame: {
    requiredLoginGame: "You must be logged in to participate in this game!!",
    loadingLoginGame: "Loading game ...",
    cancelRequired: "The operation was canceled due to a new request.",
    errorLoginGame:
      "The game is currently under maintenance, please try again later.",
    successLoginGame: "Successfully logged into the game!!",
    serverErrorLoginGame:
      "The server is currently under maintenance, please try again later.",
  },

  changePassword: {
    warning:
      "Please note that for the security of your account, please do not use the same new password as your old password. After changing your password, you will need to log in again with the new password immediately.",
    success:
      "Your password has been successfully changed, please log in again with the new password",
  },

  logout: {
    title: "Do you want to log out?",
  },

  alertPopup:
    "Popup is blocked by the browser. Please allow pop-ups from this site to open.",
};
export default MESSAGE;
