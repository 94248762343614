import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationRootState } from "types";
import { AuthStateType } from "types/auth";

import { extraReducers } from "./auth.extraReducers";

export const initialState: AuthStateType = {
  username: "",
  token: "",
  isLoading: false,
  isAuthenticated: false,
  messageRes: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateIsAuthenticated(
      state: AuthStateType,
      { payload }: PayloadAction<boolean>,
    ) {
      state.isAuthenticated = payload;
    },
    updateAuthInformation(state: AuthStateType) {
      state.isAuthenticated = true;
      state.username = localStorage.getItem("username") || "";
      state.token = localStorage.getItem("token") || "";
    },
    resetAuth: () => initialState,
  },
  extraReducers: extraReducers,
});

export const selectIsAuthenticated = (state: ApplicationRootState) =>
  state.auth.isAuthenticated;

export const selectIsLoading = (state: ApplicationRootState) =>
  state.auth.isLoading;

export const selectMessageRes = (state: ApplicationRootState) =>
  state.auth.messageRes;

export const selectUsername = (state: ApplicationRootState) =>
  state.auth.username;

export const selectToken = (state: ApplicationRootState) => state.auth.token;
export const { resetAuth, updateAuthInformation, updateIsAuthenticated } =
  authSlice.actions;
export default authSlice.reducer;
