import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { MessageResponse } from "api/message/type";
import { NoInfer } from "react-redux";
import { MessageStateType } from "types/message";

import { getMessage } from "./message.thunk";

export const extraReducers = (
  builder: ActionReducerMapBuilder<NoInfer<MessageStateType>>,
) => {
  builder
    .addCase(getMessage.pending, (state: MessageStateType) => {
      state.isLoading = true;
    })
    .addCase(
      getMessage.fulfilled,
      (state: MessageStateType, action: PayloadAction<MessageResponse>) => {
        state.isLoading = false;
        state.dataMessage = { ...action.payload };
      },
    );
};
