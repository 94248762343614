import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const FooterStyles = {
  index: css``,
  content: css`
    padding-top: 50px;
    background-color: ${theme.colors.black};
  `,
  box1: css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;

    .left {
      display: flex;
      flex-direction: column;
      flex: 2;

      .note {
        margin-top: 14px;
        width: 550px;
        color: ${theme.colors.white};
        div {
          color: ${theme.colors.white};
          font-family: "Roboto";
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px; /* 162.5% */

          .read-more {
            color: ${theme.colors.main};
            font-family: "Roboto";
            font-size: 18px;
            margin-left: 12px;
            text-decoration: underline;
            cursor: pointer;

            &:hover {
              color: ${theme.colors.secondary[700]};
            }
          }
        }
      }
      img {
        width: 141px;
        height: 91px;

        object-fit: contain;
      }
    }
    .right {
      flex: 1;

      p {
        color: ${theme.colors.white};
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
      }

      .list-info {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 8px;

        .item-info {
          text-decoration: none;
          padding: 8px 0;

          span {
            color: #666;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
  `,
  box2: css`
    background: #333;
    .image {
      max-width: 1200px;
      margin: 0 auto;
      padding: 50px 0;

      p {
        color: ${theme.colors.white};
        font-size: 15px;
        font-weight: 700;
        text-transform: uppercase;
      }

      /* img {
        margin-top: 31px;
        width: 1140px;
        height: 101px;
        object-fit: contain;
      } */
      img {
        display: inline-block;
        width: 100%;
      }
    }
  `,
  box3: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 0;
    .image1 {
      p {
        color: ${theme.colors.white};
        font-size: 15px;
        font-weight: 700;
        text-transform: uppercase;
      }

      img {
        margin-top: 29px;
        width: 350px;
        height: 48px;
      }
    }
    .image2 {
      height: 100%;
      min-width: 400px;

      p {
        color: ${theme.colors.white};
        font-size: 15px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  `,

  contactUs: css`
    display: flex;
    flex-direction: row;
    gap: 20px;
  `,

  itemContactUs: css`
    cursor: pointer;
    height: 50px;
    width: 50px;
    margin-top: 29px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `,

  copyright: css`
    padding: 0 12px;
    width: 100%;
    color: #666;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  `,
  girdImg: css`
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    gap: 20px;
    align-items: center;
    img {
      width: calc((100% / 10) - 10px) !important;
      height: 50px;
      object-fit: contain;
    }
  `,
};
