import { useAppSelector } from "hooks/app-hook";
import { selectDetailProviderBoard } from "store/game";

import { RenderGameBoard } from "./RenderGameBoard";

const GameBoardPage = () => {
  const detailProvider = useAppSelector(selectDetailProviderBoard);

  return <RenderGameBoard detailProvider={detailProvider} />;
};

export default GameBoardPage;
