import { css } from "@emotion/react";
import { theme } from "styles/theme";

interface Props {
  backgroundImage?: string;
  isActive: boolean;
}

export const MenuStyles = {
  index: css`
    /* background: rgba(252, 143, 0, 1); */
    background: ${theme.colors.main};
  `,
  content: css`
    width: 1200px;
    display: flex;
    margin: auto;
    justify-content: space-between;
  `,
  menuItem: ({ isActive }: Props) => css`
    text-decoration: none;
    color: ${isActive ? theme.colors.black : theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 0;
    transition: 0.25s;

    cursor: pointer;
    user-select: none;

    &:hover {
      [data-comp="icon-eff"] {
        background-position: center bottom;
      }
      span {
        color: ${theme.colors.black};
      }
    }

    span {
      font-size: 16px;
      font-weight: 400;
      text-transform: uppercase;
      margin-left: 4px;
    }
  `,

  icon: ({ isActive, backgroundImage }: Props) => css`
    width: 16px;
    height: 16px;

    background-image: url(${backgroundImage});
    background-position: ${isActive ? "center bottom" : "center top"};
    background-repeat: no-repeat;
    background-size: cover;
    font-size: 30px;
  `,
};
