import { css, Theme } from "@emotion/react";

export const UserLayoutStyle = {
  self: ({ colors }: Theme) => css`
    color: ${colors.white};
  `,

  contentWrapper: css`
    padding: 15px 0;
    background-color: #0b0a0a;
  `,

  mainContent: css`
    display: flex;
    gap: 30px;
    color: white;
  `,

  outlet: css`
    flex: 1;
  `,
};
