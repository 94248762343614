/** @jsxImportSource @emotion/react */

import { allListGameMenu } from "contants/game/desktop/menu";
import { CategoryGameType } from "contants/menu-game";
import { GameTypeEnum } from "enums/game";
import handleLoginGame from "helpers/handleLoginGame";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { filter } from "lodash";
import { selectUsername } from "store/auth";
// import { selectDataGameAll } from "store/game";
import { SubGameType } from "types/game";

import { DropdownGameStyle } from "./index.style";

type Props = {
  itemFocus: CategoryGameType | null;
  isFocused: boolean;
  handleRedirectSubGame: (
    idGame: string,
    path: string,
    provider_code: string,
  ) => void;
};

const DropdownGame = ({
  itemFocus,
  isFocused,
  handleRedirectSubGame,
}: Props) => {
  // const dataGameAll = useAppSelector(selectDataGameAll);
  const username = useAppSelector(selectUsername);

  const dispatch = useAppDispatch();

  const dataGame = filter(
    allListGameMenu,
    (item: SubGameType) => item.type === itemFocus?.id,
  );

  const handleClickGame = (item: SubGameType) => {
    if (
      item.type === GameTypeEnum.Slot ||
      item.type === GameTypeEnum.Fishing ||
      item.type === GameTypeEnum.Board ||
      item.type === "BGC"
    ) {
      handleRedirectSubGame(
        item.type,
        itemFocus?.path + "",
        item.provider_code,
      );
      return;
    }
    handleLoginGame(item, username, dispatch);
  };

  const isCenter = dataGame.length <= 5;
  return (
    <div css={DropdownGameStyle.index(isFocused)}>
      <div css={DropdownGameStyle.listGame(isCenter)}>
        {dataGame.map((item, index) => {
          return (
            <div
              css={DropdownGameStyle.itemGame}
              key={`item-menu-game-${index}`}
              onClick={() => handleClickGame(item)}
            >
              <img src={item.picture} alt="" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DropdownGame;
