import * as boardGameMobile from "assets/common/images/game/mobile/board-game";
import { SubGameType } from "types/game";

export const dataBoardGameMobile: SubGameType[] = [
  {
    game_code: "",
    picture: boardGameMobile.mobileBoardGameJILI,
    provider_code: "JILI",
    type: "BG",
    name: "JILI Game",
    featured: "",
    new_arrive: "",
  },
];
