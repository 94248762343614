import { css } from "@emotion/react";

import { theme } from "./theme";

export const DepositModalOveride = css`
  & .modal__signup {
    &.ant-modal {
      width: 914px !important;
      padding-bottom: 0;
    }
  }
`;

export const DropDownHeaderOverride = css`
  & .ant-dropdown {
    margin-top: 10px;
    &-menu {
      width: unset !important;
      padding: 8px 12px !important;
      &-item {
        background-color: white !important;
        padding: 5px 12px 5px 22px !important;
        &:last-of-type {
          padding: unset !important;
          margin-top: 5px !important;
          span {
            padding-left: 5px;
          }
        }
        span {
          padding-left: 20px;
        }
        &:hover {
          &::before {
            color: ${theme.colors.main};
          }
          span {
            color: ${theme.colors.main};
          }
        }
        &:not(:last-of-type) {
          &::before {
            content: "•";
            font-weight: 900;
            display: inline-block;
            text-align: center;
            margin-left: -15px;
          }
        }
      }
    }
  }
`;
