import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ModalType, ModalTypeValue } from "enums/modalType";
import { ApplicationRootState } from "types";
import { masterStateType } from "types/master";

export const initialState: masterStateType = {
  clientCountryCode: "",
  openModal: ModalTypeValue.CLOSE,
  openModalNoti: ModalType.CLOSE_NOTI,
  openModalNotiMobile: ModalType.CLOSE_NOTI_MOBILE,
  openModalForgot: ModalType.CLOSE_FORGOT,
};

const master = createSlice({
  name: "master",
  initialState,
  reducers: {
    setClientCountryCode(
      state: masterStateType,
      action: PayloadAction<string>,
    ) {
      localStorage.setItem("i18nLng", action.payload);
      state.clientCountryCode = action.payload;
    },
    openModal(state: masterStateType, action: PayloadAction<ModalTypeValue>) {
      state.openModal = action.payload;
    },
    setOpenModal(
      state: masterStateType,
      action: PayloadAction<ModalTypeValue>,
    ) {
      state.openModal = action.payload;
    },
    setOpenModalNoti(state: masterStateType, action: PayloadAction<ModalType>) {
      state.openModalNoti = action.payload;
    },
    setOpenModalNotiMobile(
      state: masterStateType,
      action: PayloadAction<ModalType>,
    ) {
      state.openModalNotiMobile = action.payload;
    },

    setOpenModalForgot(
      state: masterStateType,
      action: PayloadAction<ModalType>,
    ) {
      state.openModalForgot = action.payload;
    },
  },
});

export const selectClientCountryCode = (state: ApplicationRootState) =>
  state.master.clientCountryCode;
export const selectOpenModal = (state: ApplicationRootState) =>
  state.master.openModal;
export const selectOpenNotiModal = (state: ApplicationRootState) =>
  state.master.openModalNoti;
export const selectOpenForGotModal = (state: ApplicationRootState) =>
  state.master.openModalForgot;
export const selectOpenModalNotiMobile = (state: ApplicationRootState) =>
  state.master.openModalNotiMobile;

export const {
  setClientCountryCode,
  setOpenModal,
  setOpenModalNoti,
  setOpenModalNotiMobile,
  setOpenModalForgot,
  openModal,
} = master.actions;

export default master.reducer;
