/** @jsxImportSource @emotion/react */

import Container from "components/common/Container";
import { Footer } from "components/desktop/ui/Footer";
import { HeaderWebsite } from "components/desktop/ui/Header";
import { Outlet, useOutletContext } from "react-router";
import { outletContextType } from "types";

import { UserLayoutStyle } from "./index.style";
import Sider from "./Sider";

const UserLayout = () => {
  const { openNotification } = useOutletContext<outletContextType>();
  return (
    <div css={UserLayoutStyle.self}>
      <HeaderWebsite />
      <div css={UserLayoutStyle.contentWrapper}>
        <Container>
          <main css={UserLayoutStyle.mainContent}>
            <Sider />
            <div css={UserLayoutStyle.outlet}>
              <Outlet context={{ openNotification } as outletContextType} />
            </div>
          </main>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default UserLayout;
