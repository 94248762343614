import { css } from "@emotion/react";

export const ModalRuleRegisterStyle = {
  wrapper: css`
    .ant-modal {
      &-close {
        display: none;
      }
      &-content {
        height: 439px;
        overflow: hidden;
        padding: 24px !important;
        border-radius: 4px;
      }
    }
  `,

  mainContent: css``,

  header: css`
    font:
      400 16px/28px Roboto,
      Helvetica Neue,
      sans-serif;
    letter-spacing: normal;
    padding: 0 0 16px;
    border-bottom: 1px solid #e5e7eb;
  `,

  content: css`
    height: 300px;
    overflow-y: scroll;
    padding: 1rem;
    border-bottom: 1px solid #e5e7eb;
  `,

  footer: css`
    text-align: center;
    margin-top: 20px;
    span {
      padding: 0.25rem 1rem 0.25rem 1rem;

      border-radius: 4px;
      border: 1px solid #e5e7eb;
    }
  `,
};
