/** @jsxImportSource @emotion/react */

import { Modal } from "antd";
import { ModalType } from "enums/modalType";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { selectOpenNotiModal, setOpenModalNoti } from "store/master";

import { ModalNotiStyle } from "./style.index";
interface Props {
  title: string;
}
const ModalNoti: React.FC<Props> = ({ title }) => {
  const dispatch = useAppDispatch();

  const openNotiModal = useAppSelector(selectOpenNotiModal);

  return (
    <Modal
      open={openNotiModal === ModalType.NOTI}
      // onCancel={() => dispatch(setOpenModalNoti(ModalType.CLOSE_NOTI))}
      footer={null}
      css={ModalNotiStyle.wrapper}
      closable={false}
      centered
      style={{ top: "-35%", left: "2%" }}
    >
      <div css={ModalNotiStyle.mainContent}>
        <div css={ModalNotiStyle.header}>
          <h4>suggest</h4>
          <span>42594CC9AG12665A40B7AE</span>
        </div>
        <div css={ModalNotiStyle.body}>
          <span>{title}</span>
        </div>
        <div css={ModalNotiStyle.footer}>
          <div onClick={() => dispatch(setOpenModalNoti(ModalType.CLOSE_NOTI))}>
            confirm
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalNoti;
