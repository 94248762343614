/** @jsxImportSource @emotion/react */

import MenuPayment from "../MenuPayment";
import MenuUser from "../MenuUser";
import Surplus from "../Surplus";
import { SiderStyle } from "./index.style";

const Sider = () => {
  return (
    <div css={SiderStyle.self}>
      <Surplus />
      <MenuPayment />
      <MenuUser />
    </div>
  );
};

export default Sider;
