/** @jsxImportSource @emotion/react */
import "swiper/css";
import "swiper/css/navigation";

import {
  CategoryGameType,
  LIST_GAME_CATEGORY_DETAILS_M,
} from "contants/menu-game";
import { GameTypeEnum } from "enums/game";
import { useAppDispatch } from "hooks/app-hook";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getSubGameList } from "store/game/game.thunk";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { CategoryGameSwiperStyle } from "./style.index";

const CategoryGameSwiper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  // const dataGameAll = useAppSelector(selectDataGameAll);

  useEffect(() => {
    dispatch(
      getSubGameList({
        lobby: 1,
        page: 1,
        size: 100,
      }),
    );
  }, []);

  const getGameLobby = async (idGame: string, path: string) => {
    // const dataGame = find(
    //   dataGameAll,
    //   (item: SubGameType) =>
    //     item.type === (idGame === GameTypeEnum.Crash ? "BG" : idGame),
    // );

    // if (!dataGame) return;
    // const code = dataGame.provider_code;

    // navigate(`${path}/${code}`);

    if (idGame === "BGC") {
      navigate(`${path}/T1G`);
      return;
    }

    if (idGame === GameTypeEnum.Slot) {
      navigate(`${path}/DGG`);
      return;
    }

    navigate(`${path}/JILI`);
  };

  const handleNavigateCategory = (props: CategoryGameType) => {
    const { id, path } = props;
    if (
      id === GameTypeEnum.Slot ||
      id === GameTypeEnum.BoardGame ||
      id === GameTypeEnum.Crash
    ) {
      getGameLobby(id, path);
      return;
    }

    navigate(path);
  };

  return (
    <div css={CategoryGameSwiperStyle.categoryGame.index}>
      <Swiper
        modules={[Navigation]}
        navigation
        slidesPerView={5}
        css={CategoryGameSwiperStyle.categoryGame.index}
      >
        {LIST_GAME_CATEGORY_DETAILS_M.map((item) => {
          const isActive = pathname.includes(item.path);
          return (
            <SwiperSlide key={item.id}>
              <div
                onClick={() =>
                  handleNavigateCategory({
                    id: item.id,
                    path: item.path,
                    name: item.name,
                  })
                }
                css={CategoryGameSwiperStyle.categoryGame.item({
                  isActive: isActive,
                })}
              >
                <div
                  css={CategoryGameSwiperStyle.categoryGame.icon({
                    backgroundImage: item.icon_m,
                    isActive: isActive,
                  })}
                />
                <span>{item.name}</span>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default CategoryGameSwiper;
