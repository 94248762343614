import { createAsyncThunk } from "@reduxjs/toolkit";
import { gameApi } from "api/game";
import { SubGamePayLoad } from "types/game";

export const getGameList = createAsyncThunk(
  "game/getGameList",
  async (game_type: string, { rejectWithValue }) => {
    try {
      const response = await gameApi.getGameList(game_type);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getSubGameList = createAsyncThunk(
  "game/getSubGameList",
  async (dataParameter: SubGamePayLoad, { rejectWithValue }) => {
    try {
      const response = await gameApi.getSubGameList(dataParameter);
      return { ...response.data, ...dataParameter };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
