/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Button, Modal } from "antd";
import { countDate } from "helpers";
import { handleTextTime } from "helpers/handleTextTime";
import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import { MessageType } from "types/message";

interface Props {
  isOpenModalDetail: boolean;
  setIsOpenModalDetail: Dispatch<SetStateAction<boolean>>;
  detailMessage: MessageType;
}

export const MMessageModalDetail = (props: Props) => {
  const { isOpenModalDetail, setIsOpenModalDetail, detailMessage } = props;
  return (
    <Modal
      open={isOpenModalDetail}
      centered
      onCancel={() => setIsOpenModalDetail(false)}
      css={css`
        .ant-modal-close {
          display: none;
        }

        .ant-modal-content {
          padding: 0;
        }
      `}
      footer={<></>}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <div
          css={css`
            padding: 15px;
            color: #0080e3;
            font-weight: bold;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          `}
        >
          {detailMessage.title}
          <div
            css={css`
              color: black;
              opacity: 0.2;
              text-shadow: 0 1px 0 #fff;
            `}
            onClick={() => setIsOpenModalDetail(false)}
          >
            x
          </div>
        </div>
        <div
          css={css`
            padding: 15px;
            border-top: 1px solid #e5e5e5;
            border-bottom: 1px solid #e5e5e5;
            display: flex;
            flex-direction: column;
          `}
        >
          <div
            css={css`
              margin: 10px 0;
            `}
          >
            <p
              css={css`
                font-size: 14px;
                font-weight: 500;
              `}
            >
              System At {moment(detailMessage.create_date).format("YYYY-MM-DD")}{" "}
              send -{" "}
              {countDate(detailMessage.create_date) > "0" && (
                <>{countDate(detailMessage.create_date)}</>
              )}{" "}
              <>{handleTextTime(countDate(detailMessage.create_date))}</>
            </p>
          </div>
          <div
            css={css`
              background-color: #f7f7f9;
              border: 1px solid #e1e1e8;
              max-height: 360px;
              border-radius: 4px;
              padding: 20px;
              overflow-y: scroll;
            `}
            dangerouslySetInnerHTML={{ __html: detailMessage.content }}
          />
        </div>
        <div
          css={css`
            padding: 15px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          `}
        >
          <Button
            css={css`
              padding: 10px 16px;
              font-size: 18px;
              border-radius: 6px;
              color: #333;
              border-color: #ccc;
              background-color: white;
              width: max-content;
              height: 100%;
            `}
            onClick={() => setIsOpenModalDetail(false)}
          >
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};
