import * as crash from "assets/common/images/game/desktop/menu/crash";
import { SubGameType } from "types/game";

export const dataCrash: SubGameType[] = [
  {
    game_code: "",
    picture: crash.desktopCasinoT1,
    provider_code: "T1G",
    type: "BGC",
    name: "",
    featured: "",
    new_arrive: "",
  },
];
