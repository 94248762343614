/** @jsxImportSource @emotion/react */

import { icons } from "assets/mobile";
import { LogoMobileHeader } from "assets/mobile/images";
import { PATH, URL_DOWNLOAD_APP } from "contants/path";
import { Link } from "react-router-dom";

import { HeaderStyle } from "./index.style";
const { AppDownload } = icons;
export const HeaderMobile = () => {
  return (
    <>
      <div css={HeaderStyle.index}>
        <div css={HeaderStyle.content}>
          <div className="logo">
            <Link to={PATH.Home}>
              <img src={LogoMobileHeader} alt="" />
            </Link>
          </div>
          <div
            onClick={() => window.open(URL_DOWNLOAD_APP)}
            className="download"
          >
            <AppDownload />
          </div>
        </div>
      </div>
    </>
  );
};
