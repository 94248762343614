/** @jsxImportSource @emotion/react */

import { RedoOutlined } from "@ant-design/icons";
import { Image } from "antd";
import { Rectangle } from "assets/mobile/images";
import {
  IconDeposit,
  IconWithdraw,
  RectangleDeposit,
  RectangleWithdraw,
} from "assets/mobile/images/homepage";
import { PATH } from "contants/path";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { Link, useNavigate } from "react-router-dom";
import { selectIsAuthenticated, selectUsername } from "store/auth";
import { selectBalance, selectBalanceLoading, selectProfile } from "store/user";
import { getMemberBalance } from "store/user/user.thunk";

import { FuncUserStyle } from "./style.index";

const menus = [
  // {
  //   name: "To return",
  //   link: PATH.User.Refund,
  //   icon: icons.discount,
  // },
  // {
  //   name: "Get rewarded",
  //   link: PATH.Promotion,
  //   icon: icons.gift,
  // },
  // {
  //   name: "Agent",
  //   link: PATH.daily,
  //   icon: icons.daily,
  // },
  {
    name: "Deposit",
    link: PATH.User.Financial,
    background: RectangleDeposit,
    icon: IconDeposit,
  },
  {
    name: "WithDraw",
    link: PATH.User.FinancialWithdraw,
    background: RectangleWithdraw,
    icon: IconWithdraw,
  },
];
const FuncUser = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const username = useAppSelector(selectUsername);
  const isAuth = useAppSelector(selectIsAuthenticated);
  const memberBalance = useAppSelector(selectBalance);
  const profile = useAppSelector(selectProfile);
  const isLoading = useAppSelector(selectBalanceLoading);
  if (isAuth) {
    return (
      <div css={FuncUserStyle.auth.index}>
        <div css={FuncUserStyle.auth.content}>
          <div css={FuncUserStyle.auth.info}>
            <div
              onClick={() => dispatch(getMemberBalance({ username }))}
              className="points"
            >
              <span>$ {memberBalance?.total}</span>
              <RedoOutlined className="icon" spin={isLoading} />
            </div>
            <div className="username">
              <span>{profile?.username}</span>
            </div>
          </div>
          <div css={FuncUserStyle.auth.menu}>
            {menus.map((item, index) => {
              return (
                <Link
                  css={FuncUserStyle.auth.itemMenu}
                  to={item.link}
                  key={`item-menu-${index}`}
                >
                  <img
                    alt=""
                    src={item.background}
                    css={FuncUserStyle.auth.imageBlock}
                  />
                  <div className="icon">
                    <img src={item.icon} alt="" />
                  </div>
                  <p>{item.name}</p>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div css={FuncUserStyle.noLogin.fcUser.index}>
      <div
        onClick={
          () => navigate(PATH.Account.login)
          // dispatch(openModal(ModalTypeValue.REGISTER))
        }
        css={FuncUserStyle.noLogin.fcUser.itemLogin}
      >
        Login
      </div>
      <div
        onClick={
          () => navigate(PATH.Account.register)
          // dispatch(openModal(ModalTypeValue.LOGIN))
        }
        css={FuncUserStyle.noLogin.fcUser.itemSignUp}
      >
        <Image
          css={FuncUserStyle.noLogin.fcUser.itemBackground}
          src={Rectangle}
          alt=""
          preview={false}
        />

        <span>Sign up</span>
      </div>
    </div>
  );
};

export default FuncUser;
