/** @jsxImportSource @emotion/react */

import { Footer } from "components/desktop/ui/Footer";
import { HeaderWebsiteAgent } from "components/desktop/ui/HeaderAgent";
import ModalGroup from "components/desktop/ui/ModalGroup";
import { Outlet, useOutletContext } from "react-router-dom";
import { outletContextType } from "types";

import { DefaultLayoutStyle } from "./index.style";

export const AgentLayout = () => {
  const { openNotification } = useOutletContext<outletContextType>();

  return (
    <div css={DefaultLayoutStyle.index}>
      <HeaderWebsiteAgent />
      <main css={DefaultLayoutStyle.main}>
        <Outlet context={{ openNotification } as outletContextType} />
      </main>
      <Footer />
      {/* <SocialBox /> */}
      <ModalGroup />
    </div>
  );
};

export default AgentLayout;
