import { css } from "@emotion/react";

export const InputPasswordStyle = {
  self: () => css`
    &.ant-input-affix-wrapper,
    .ant-input-affix-wrapper {
      border: 0px;
      font-size: 14px;
      border-radius: 50px;
      background: #4f4f4f;
      color: #fff;
      outline: none;
      box-shadow: none;
      font-weight: 500;
      overflow: hidden;

      .ant-input-suffix {
        padding-right: 4px;
        svg {
          color: #fff;
        }
      }

      input {
        background-color: transparent !important;
        color: #fff;
        width: 100%;
        height: 50px;
        padding-left: 8px !important;
        &::placeholder {
          color: #fff;
          opacity: 1; /* Firefox */
          font-weight: 500 !important;
        }

        &:-ms-input-placeholder {
          color: #fff;
        }

        &::-ms-input-placeholder {
          color: #fff;
        }
      }

      &:focus,
      &:hover {
        border-color: rgba(0, 0, 0, 0.1);
      }
      &-disabled {
        opacity: 0.5;
      }

      &-status-error {
        border-color: var(--error-color) !important;
        input::placeholder {
          color: var(--error-color);
        }
        .ant-input-suffix {
          svg {
            color: var(--error-color);
          }
        }
      }
    }
    & .ant-form-item-explain-error {
      color: var(--error-color) !important;
    }
  `,
};
