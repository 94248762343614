/** @jsxImportSource @emotion/react */
import {
  CategoryGameType,
  LIST_GAME_CATEGORY_DETAILS,
} from "contants/menu-game";
import { GameTypeEnum } from "enums/game";
import { useAppDispatch } from "hooks/app-hook";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { setCurrentPage } from "store/game";
import { getSubGameList } from "store/game/game.thunk";

import DropdownGame from "./DropdownGame";
import { MenuStyles as styles } from "./index.style";

function Menu() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  // const dataGameAll = useAppSelector(selectDataGameAll);

  const [itemFocus, setItemFocus] = useState<CategoryGameType | null>(null);

  useEffect(() => {
    dispatch(
      getSubGameList({
        lobby: 1,
        page: 1,
        size: 100,
      }),
    );
  }, []);

  const getGameLobby = async (idGame: string, path: string) => {
    // const dataGame = find(dataGameAll, (item: SubGameType) => {
    //   return item.type === (idGame === "BGC" ? "BG" : idGame);
    // });

    // if (!dataGame) return;
    // const code = dataGame.provider_code;

    // navigate(`${path}/${code}`);

    if (idGame === "BGC") {
      navigate(`${path}/T1G`);
      return;
    }

    if (idGame === GameTypeEnum.Slot) {
      navigate(`${path}/DGG`);
      return;
    }

    navigate(`${path}/JILI`);
  };

  const handleRedirectSubGame = (
    idGame: string,
    path: string,
    provider_code?: string,
  ) => {
    dispatch(setCurrentPage(1));

    if (provider_code) {
      navigate(`${path}/${provider_code}`);
      return;
    }
    if (
      idGame === GameTypeEnum.Fishing
      // idGame === GameTypeEnum.Slot ||
      // idGame === GameTypeEnum.BoardGame ||
      // idGame === "BGC"
    ) {
      getGameLobby(idGame, path);
      return;
    }

    navigate(path);
  };

  return (
    <div onMouseLeave={() => setItemFocus(null)} css={styles.index}>
      <div css={styles.content}>
        {LIST_GAME_CATEGORY_DETAILS.map((item: CategoryGameType) => {
          const isActive = pathname.includes(item.path);

          return (
            <div onMouseEnter={() => setItemFocus(item)} key={item.id}>
              <div
                onClick={() => {
                  if (item.id === "DL") {
                    return window.open(item.path);
                  }
                  handleRedirectSubGame(item.id, item.path);
                }}
                css={styles.menuItem({ isActive: isActive })}
              >
                <div
                  data-comp="icon-eff"
                  css={styles.icon({
                    backgroundImage: item.icon,
                    isActive: isActive,
                  })}
                />
                <span>{item.name}</span>
              </div>
              {item.isHover && (
                <DropdownGame
                  isFocused={item.id === itemFocus?.id}
                  itemFocus={item}
                  handleRedirectSubGame={handleRedirectSubGame}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Menu;
