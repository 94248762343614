/** @jsxImportSource @emotion/react */

import { NotifyMarque } from "components/desktop/NotiMarque";
import CategoryGameSwiper from "components/mobile/CategoryGameSwiper";
import FuncUser from "components/mobile/FuncUser";
import { dataCockFightingMobile } from "contants/game/mobile/contents/cock-fighting";
import handleLoginGame from "helpers/handleLoginGame";
import { useAppDispatch } from "hooks/app-hook";
import { SwiperBanner } from "pages/desktop/HomePage/SwiperBanner/SwiperPagination";
import { useSelector } from "react-redux";
import { selectUsername } from "store/auth";

import { CockFightingMobileGameStyle } from "./style.index";

export const CockFightingMobileGame = () => {
  const username = useSelector(selectUsername);
  const dispatch = useAppDispatch();
  return (
    <div css={CockFightingMobileGameStyle.wrapper}>
      <NotifyMarque />
      <SwiperBanner />
      <FuncUser />
      <CategoryGameSwiper />

      <div css={CockFightingMobileGameStyle.mainContent}>
        {dataCockFightingMobile.map((items, index) => (
          <div
            onClick={() => {
              handleLoginGame(items, username, dispatch);
            }}
            key={index}
            css={CockFightingMobileGameStyle.item}
          >
            <img src={items.picture} alt="" />
            <span>{items.name}</span>
          </div>
        ))}
        {/* <div css={CockFightingMobileGameStyle.item}>
          <img src={mobileCockFightingUPDATE} alt="" />
          <span>updating...</span>
        </div> */}
      </div>
    </div>
  );
};
