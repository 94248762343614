import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslation from "./translation/en.json";
import viTranslation from "./translation/vi.json";

i18n.use(initReactI18next).init({
  fallbackLng: "vi",
  debug: false,
  resources: {
    en: {
      translation: enTranslation,
    },
    vi: {
      translation: viTranslation,
    },
  },
});
export default i18n;
