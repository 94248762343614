import { dataBoard } from "./board";
import { dataCasino } from "./casino";
import { dataCockFighting } from "./cockfighting";
import { dataCrash } from "./crash";
import { dataFishing } from "./fishing";
import { dataLottery } from "./lottery";
import { dataSlots } from "./slots";
import { dataSports } from "./sports";

export const allListGameMenu = [
  ...dataSports,
  ...dataCasino,
  ...dataSlots,
  ...dataFishing,
  ...dataCrash,
  ...dataBoard,
  ...dataCockFighting,
  ...dataLottery,
];
