import { css } from "@emotion/react";

export const InputCustomStyle = {
  self: () => css`
    &.ant-input,
    .ant-input {
      width: 100%;
      height: 50px;
      border: 0;
      padding: 5px 10px;
      font-size: 14px;
      border-radius: 50px;
      background: #4f4f4f;
      color: #fff;
      outline: none;
      box-shadow: none;
      font-weight: 500;

      &:-webkit-autofill {
        -webkit-background-clip: text;
      }

      &::placeholder {
        color: #fff;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        color: #fff;
      }

      &::-ms-input-placeholder {
        color: #fff;
      }

      &:focus,
      &:hover {
        border-color: rgba(0, 0, 0, 0.1);
      }
      &-disabled {
        opacity: 0.5;
      }

      &-status-error {
        border-color: var(--error-color) !important;
        &::placeholder {
          color: var(--error-color);
        }
      }
    }
    & .ant-form-item-explain-error {
      color: var(--error-color) !important;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Works for Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  `,
};
