export const userPath = "/user";
export const URL_DOWNLOAD_APP = window.location.origin + "/app/jmc88.apk";

export const PATH = {
  Home: "/",

  //game
  LiveGame: "/live",
  LotteryGame: "/lottery",
  SlotsGame: "/slots",
  SportGame: "/sports",
  BoardGame: "/board-game",
  CrashGame: "/crash-game",
  FishingGame: "/fishing",
  CockFightingGame: "/cock-fighting",
  CategoryGame: "/:categoryName/:providerId",
  CategoryGameFilter: "/:categoryName",
  daily: "/daily",
  Promotion: "/promotion",
  ContactUs: "/contacts",
  Info: "/info/:id",
  forgotPassword: "/forgot-password",
  User: {
    Profile: userPath + "/profile",
    Deposit: userPath + "/deposit",
    Withdraw: userPath + "/withdraw",
    SecuritySetting: userPath + "/security-setting",
    Notifications: userPath + "/notifications",
    Announcement: userPath + "/announcement",
    InternalMail: userPath + "/internal-mail",
    Event: userPath + "/event",
    Transactions: userPath + "/transactions",
    OrderHistory: userPath + "/order-history",
    Refund: userPath + "/refund",
    Favourite: userPath + "/favourite",
    Financial: userPath + "/financial",
    FinancialWithdraw: userPath + "/financial?open=withdraw",
    BettingRecord: userPath + "/betting-record",
    TransactionHistory: userPath + "/transaction-history",
  },
  Account: {
    login: "/account/login",
    register: "/account/register",
    forgetPassword: "/account/forget-password",
    forgotPassword: "/account/forgot-password",
  },
};
