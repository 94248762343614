/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form, Modal, notification } from "antd";
import { PlayerBankFormType } from "api/bank/type";
import InputCustom from "components/desktop/base/InputCustom";
import SelectCustom from "components/desktop/base/SelectCustom";
import { ModalTypeValue } from "enums/modalType";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import _map from "lodash/map";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { selectUsername } from "store/auth";
import { selectBanks } from "store/bank";
import { addPlayerBank, getListBank } from "store/bank/bank.thunk";
import { openModal } from "store/master";
import { theme } from "styles/theme";
import { StatusNotification } from "types";
import { BankType } from "types/bank";
import * as yup from "yup";

import { AddBankStyle } from "./index.style";

const schema = yup.object({
  account_no: yup.string().required("This field is required"),
  account_name: yup.string().required("This field is required"),
  id: yup.string().required("Bank cannot be blank"),
  username: yup.string().required("This field is required"),
});

export const ModalAddBank = () => {
  const dispatch = useAppDispatch();

  const username = useAppSelector(selectUsername);
  const banks = useAppSelector(selectBanks);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const listOptionBank = useMemo(
    () =>
      _map(banks, (item: BankType) => {
        return {
          label: item.bank_name,
          value: item.bank_id,
        };
      }),
    [banks],
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<PlayerBankFormType>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      username,
    },
  });

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (status: StatusNotification, message: string) => {
    api[status]({
      message,
      description: "",
    });
  };

  useEffect(() => {
    if (!username) return;

    if (banks.length > 0) return;

    dispatch(getListBank(username));
  }, [username, banks]);

  const onSubmit = async (data: PlayerBankFormType) => {
    setIsLoading(true);

    const res = await dispatch(addPlayerBank(data));
    setIsLoading(false);

    const resData = res.payload;

    if (!resData) {
      openNotification("error", "Add bank player failed!!!");
      return;
    }

    openNotification("success", "Add bank player success!!!");
    dispatch(openModal(ModalTypeValue.CLOSE));
    reset();
  };

  return (
    <Modal
      open={true}
      closable={false}
      onCancel={() => dispatch(openModal(ModalTypeValue.CLOSE))}
      css={AddBankStyle.modalContainer}
      footer={<></>}
      centered
    >
      {contextHolder}
      <div
        css={css`
          display: flex;
          width: 100%;
          justify-content: flex-start;
          align-items: center;
          font-size: 20px;
          font-weight: 700;
          color: #fd7900;
        `}
      >
        Add Bank
      </div>
      <Form className="deposit_form" onSubmitCapture={handleSubmit(onSubmit)}>
        <div
          css={css`
            margin: 12px 0;
          `}
        >
          <SelectCustom
            control={control}
            disabled={isLoading}
            name={"id"}
            options={listOptionBank}
            placeholder="Select Bank"
            validate_status={errors.id && "error"}
            error={errors.id?.message}
          />
        </div>

        <InputCustom
          control={control}
          name="account_no"
          errors={errors.account_no?.message}
          validate_status={errors.account_no && "error"}
          margin_bottom="12px"
          placeholder="Bank account number"
        />

        <InputCustom
          control={control}
          name="account_name"
          errors={errors.account_name?.message}
          validate_status={errors.account_name && "error"}
          placeholder="Bank account name"
        />

        <div>
          <Button
            loading={isLoading}
            css={css`
              width: 100%;
              background-color: ${theme.colors.secondary[650]};
              border-color: transparent;
              height: 38px;
              font-size: 16px;

              &:hover {
                color: black !important;
                border-color: transparent !important;
                transform: scale(1.05);
              }
            `}
            htmlType="submit"
          >
            Add bank
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
