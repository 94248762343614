import { css } from "@emotion/react";

export const InputCustomStyle = {
  self: (isPrefix: boolean) => css`
    & .ant-form-item-control-input {
      & .ant-input-affix-wrapper {
        padding: 4px 4px 4px 20px;
        border-radius: 65px;
        box-shadow: 0px 5.05263px 5.05263px 0px rgba(0, 0, 0, 0.25);
        background-color: #505050;
        height: 56px;
        input {
          border-radius: 65px;
          font-size: 16px;
          width: 100%;
          height: 100%;
          color: white !important;
          margin: 0 4px;
          padding-left: 4px;
          background-color: #505050;
          &::placeholder {
            color: white;
          }
          &:-webkit-autofill,
          &:-webkit-autofill:focus {
            transition:
              background-color 600000s 0s,
              color 600000s 0s;
          }
        }
      }
    }

    &.ant-input,
    .ant-input {
      padding: ${isPrefix && "4px 4px 4px 20px"};
      border-radius: ${isPrefix && "65px"};
      box-shadow: ${isPrefix &&
      "0px 5.05263px 5.05263px 0px rgba(0, 0, 0, 0.25)"} !important;

      height: 37px;
      &:-webkit-autofill {
        -webkit-background-clip: text;
      }

      &:focus,
      &:hover {
        border-color: rgba(0, 0, 0, 0.1);
      }
      &-disabled {
        opacity: 0.5;
      }
      &::placeholder {
        color: black;
      }

      &-status-error {
        /* border-color: var(--error-color) !important;
        &::placeholder {
          color: var(--error-color);
        } */
      }
    }

    & .ant-input-status-error {
      border-color: transparent !important;
      box-shadow:
        inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 6px #ce8483;

      &:focus {
        border: red;
      }
    }

    & .ant-form-item-explain-error {
      color: var(--error-color) !important;
      position: absolute;
      top: -30px;
      right: 0;
      font-size: 12px;
      height: 30px;
      padding: 0 5px;
      white-space: nowrap;
      border-radius: 4px 4px 0 0;
      border-width: 1px;
      border-top-style: solid;
      border-right-style: solid;
      border-left-style: solid;
      border-bottom-style: initial;
      border-top-color: rgba(255, 255, 255, 0.5);
      border-right-color: rgba(255, 255, 255, 0.5);
      border-left-color: rgba(255, 255, 255, 0.5);
      border-bottom-color: initial;
      background-color: rgb(178, 0, 0);
      line-height: 29px;
      color: white !important;
    }
  `,
};
