import { PayloadAction } from "@reduxjs/toolkit";
import {
  DetailProviderBoardType,
  DetailProviderCrashType,
  DetailProviderSlotType,
  GameStateType,
} from "types/game";

import { initialState } from ".";

export const reducers = {
  resetDataCategoryGameFilter: (state: GameStateType) => {
    state.dataCategoryGameFilter = initialState.dataCategoryGameFilter;
  },
  setCategoryCurrent: (state: GameStateType, action: PayloadAction<string>) => {
    state.categoryCurrent = action.payload;
  },
  resetDataSubGame: (state: GameStateType) => {
    state.dataSubGame = { ...initialState.dataSubGame };
  },
  resetFilterSubGame: (state: GameStateType) => {
    state.filterSubGame = initialState.filterSubGame;
  },
  setDetailProviderSlot: (
    state: GameStateType,
    action: PayloadAction<DetailProviderSlotType>,
  ) => {
    state.detailProviderSlot = { ...action.payload };
  },

  setDetailProviderBoard: (
    state: GameStateType,
    action: PayloadAction<DetailProviderBoardType>,
  ) => {
    state.detailProviderBoard = { ...action.payload };
  },
  setDetailProviderCrash: (
    state: GameStateType,
    action: PayloadAction<DetailProviderCrashType>,
  ) => {
    state.detailProviderCrash = { ...action.payload };
  },
  setCurrentPage: (state: GameStateType, action: PayloadAction<number>) => {
    state.currentPage = action.payload;
  },

  resetCurrentPage: (state: GameStateType) => {
    state.currentPage = initialState.currentPage;
  },

  setFilterSubGame: (state: GameStateType, action: PayloadAction<string>) => {
    state.filterSubGame = action.payload;

    // if (!action.payload) {
    //   state.dataSubGame.data = state.dataSubGameFilter;
    //   return;
    // }

    // const findGameByName = state.dataSubGame.data.filter((item: SubGameType) =>
    //   item.name.toLowerCase().includes(action.payload.toLowerCase()),
    // );

    // if (!findGameByName) return;

    // state.dataSubGame.data = findGameByName;
  },
};
