import { ReactComponent as i_search } from "assets/common/icons/i-search.svg";
import { ReactComponent as LeftIcon } from "assets/desktop/icons/leftIcon.svg";
import { ReactComponent as RightIcon } from "assets/desktop/icons/rightIcon.svg";
import PromosImageDefault from "assets/desktop/images/promotions/promos-default.png";
import CardGameHome from "components/desktop/CardGame";

import { ReactComponent as ArrowLeft } from "./icons/ArrowLeft.svg";
import { ReactComponent as IconClose } from "./icons/icon-close.svg";
import { ReactComponent as AgencyPolicy } from "./icons/menus/agency-policy.svg";
import { ReactComponent as CardGame } from "./icons/menus/card-games.svg";
import { ReactComponent as NotificationIcon } from "./icons/notification_icon.svg";
import { ReactComponent as StartIconForm } from "./icons/star-icon-form.svg";
import { ReactComponent as DepositOfflineIcon } from "./icons/transaction/deposit-offline.svg";
import { ReactComponent as DepositOnlineIcon } from "./icons/transaction/deposit-online.svg";

export const images = {
  PromosImageDefault: PromosImageDefault,

  //home
  CardGameHome: CardGameHome,
};

export const icons = {
  i_search: i_search,

  IconClose: IconClose,
  StartIconForm: StartIconForm,
  // menu
  agencyPolicy: AgencyPolicy,
  cardGame: CardGame,

  //home
  RightIcon: RightIcon,
  LeftIcon: LeftIcon,

  //History

  ArrowLeft: ArrowLeft,

  //Announcement

  NotificationIcon: NotificationIcon,

  //transaction
  DepositOfflineIcon: DepositOfflineIcon,
  DepositOnlineIcon: DepositOnlineIcon,
};
