import { css, keyframes, Theme } from "@emotion/react";

const spin = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const SurplusStyle = {
  self: css`
    width: 100%;
    height: 218px;
    background-color: #545454;
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  `,

  username: ({ fontSize }: Theme) => css`
    font-size: ${fontSize[15]};
    font-weight: 700;
    margin-top: 10px;
  `,

  surplus: ({ fontSize }: Theme) => css`
    font-size: ${fontSize[16]};
    font-weight: 700;
    margin-top: 15px;
  `,

  refresh: css`
    width: 14px;
    height: 14px;
    vertical-align: middle;
    margin: 0 0 3px 10px;
    cursor: pointer;
    transform: rotate(0);
    &[data-loading="loading"] {
      animation: ${spin} 1s linear infinite;
    }
  `,
};
