import { css } from "@emotion/react";

export const MUserLayoutStyle = {
  self: css`
    // height: calc(100vh - 60px);
    background-color: #f1f1e1;
    overflow: hidden;
    padding-bottom: 60px;
  `,
};
