/** @jsxImportSource @emotion/react */
// import { DownOutlined, SyncOutlined } from "@ant-design/icons";
// import { Button, Dropdown } from "antd";
import { MobileAgentIcon } from "assets/desktop/icons";
import { Logo } from "assets/desktop/images";
import { HeaderAgentImg } from "assets/desktop/images/agent";
import { useModalConfirm } from "components/common/ModalConfirm";
// import MESSAGE from "contants/message";
import { PATH, URL_DOWNLOAD_APP } from "contants/path";
// import { useAppLogout } from "hooks/useLogout";
// import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// import { selectBalance, selectBalanceLoading, selectProfile } from "store/user";
// import { getMemberBalance } from "store/user/user.thunk";
import { HeaderWebsiteStyle as styles } from "./index.style";
// import { listMenuProfileHeader } from "./menu";
import RenderTime from "./RenderTime";
export const HeaderWebsiteAgent = () => {
  // const dispatch = useAppDispatch();
  // const navigate = useNavigate();

  // const username = useAppSelector(selectUsername);
  // const isAuth = useAppSelector(selectIsAuthenticated);
  // const profile = useAppSelector(selectProfile);
  // const memberBalance = useAppSelector(selectBalance);
  // const isLoading = useAppSelector(selectBalanceLoading);
  // const username = useAppSelector(selectUsername);

  const { contextHolder } = useModalConfirm();
  // const onLogout = useAppLogout();

  // useEffect(() => {
  //   if (!username) return;
  //   dispatch(getMemberBalance({ username }));
  // }, [username]);

  // const onClickMenu = (key: string) => {
  //   switch (key) {
  //     case "logout":
  //       onLogoutAction();
  //       break;
  //     default:
  //       break;
  //   }
  // };
  // const onLogoutAction = () => {
  //   modalConfig({
  //     title: MESSAGE.logout.title,
  //     onSuccess: () => {
  //       onLogout();
  //     },
  //   });
  // };

  // const handleRedirect = (path: string) => {
  //   if (!isAuth) {
  //     dispatch(openModal(ModalTypeValue.LOGIN));
  //     return;
  //   }

  //   navigate(path);
  // };

  return (
    <div css={styles.header}>
      <div css={styles.content}>
        <div css={styles.left}>
          <Link to={PATH.Home}>
            <img src={Logo} alt="" className="logoApp" />
          </Link>

          <img alt="" src={HeaderAgentImg} className="img-header" />
        </div>
        <div css={styles.right}>
          <div css={styles.right_top}>
            <div className="item no-border">
              <span>
                <RenderTime />
              </span>
            </div>
            <div onClick={() => window.open(URL_DOWNLOAD_APP)} className="item">
              <MobileAgentIcon fill="white" />
              <span>Download app</span>
            </div>
          </div>
          <div css={styles.right_bottom}>
            {/* {isAuth ? (
              <div css={styles.infoProfile}>
                <div className="info">
                  <p>Account: {profile?.username}</p>
                  <p>
                    Account wallet:
                    <span className="wallet">
                      {memberBalance?.total}
                      <SyncOutlined
                        onClick={() => {
                          if (!profile?.username) return;
                          dispatch(getMemberBalance({ username }));
                        }}
                        spin={isLoading}
                        className="icon-sync"
                      />
                    </span>
                  </p>
                </div>
                <Dropdown
                  className="dropdown"
                  menu={{
                    items: listMenuProfileHeader,
                    triggerSubMenuAction: "click",
                    style: {
                      width: 160,
                    },
                    onClick: (e) => {
                      onClickMenu(e.key);
                    },
                  }}
                  trigger={["click"]}
                >
                  <span>
                    Information
                    <DownOutlined className="icon" />
                  </span>
                </Dropdown>
              </div>
            ) : (
              <>
                <Button
                  onClick={() => dispatch(openModal(ModalTypeValue.LOGIN))}
                  className="login button-custom"
                >
                  Login
                </Button>
                <Button
                  onClick={() => dispatch(openModal(ModalTypeValue.REGISTER))}
                  className="register button-custom"
                >
                  Register
                </Button>
              </>
            )} */}
          </div>
        </div>
      </div>
      {contextHolder}
    </div>
  );
};
