import * as sports from "assets/common/images/game/desktop/menu/sports";
import { SubGameType } from "types/game";

export const dataSports: SubGameType[] = [
  {
    game_code: "",
    picture: sports.desktopSportUG,
    provider_code: "UG",
    type: "SB",
    name: "",
    featured: "",
    new_arrive: "",
  },
];
