import { SubGameType } from "types/game";

export const dataLottery: SubGameType[] = [
  // {
  //   game_code: "",
  //   picture: lottery.desktopLotteryGW,
  //   provider_code: "",
  //   type: "LT",
  //   name: "",
  //   featured: "",
  //   new_arrive: "",
  // },
  // {
  //   game_code: "",
  //   picture: lottery.desktopLotteryVR,
  //   provider_code: "",
  //   type: "LT",
  //   name: "",
  //   featured: "",
  //   new_arrive: "",
  // },
  // {
  //   game_code: "",
  //   picture: lottery.desktopLotteryP,
  //   provider_code: "",
  //   type: "LT",
  //   name: "",
  //   featured: "",
  //   new_arrive: "",
  // },
];
