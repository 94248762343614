/** @jsxImportSource @emotion/react */

import { Outlet } from "react-router-dom";

import { MUserLayoutStyle } from "./index.style";
import MemberMenu from "./MemberMenu";
import MSurplus from "./MSurplus";

const MUserLayout = () => {
  return (
    <div css={MUserLayoutStyle.self}>
      <MSurplus />
      <MemberMenu />
      <Outlet />
    </div>
  );
};

export default MUserLayout;
