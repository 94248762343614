import { css, keyframes, Theme } from "@emotion/react";
import { theme } from "styles/theme";

const spin = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const MSurplusStyle = {
  self: css`
    /*  */
  `,

  cardUserInfo: css`
    width: 100%;
    height: 50vw;
    padding-top: 80px;
    margin-top: -50px;
    border-radius: 0 0 100% 100%;
    color: white;
    background-color: ${theme.colors.main};
  `,
  userInfo: ({ fontSize }: Theme) => css`
    height: 100%;
    display: flex;
    justify-content: space-between;
    font-size: ${fontSize[14]};
  `,
  signoutBtn: ({ colors }: Theme) => css`
    display: flex;
    align-items: center;
    gap: 4px;
    color: ${colors.white} !important;
    height: fit-content;
    margin-top: -4px;
  `,
  signOutIcon: ({ colors }: Theme) => css`
    fill: ${colors.white};
    font-size: 20px;
  `,

  paymentCard: css`
    width: 100%;
    padding: 15px;
    border-radius: 12px;
    border: 1px solid rgb(204 204 204);
    background-color: white;
    margin-top: -80px;
  `,
  balanceInfo: css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  `,
  balanceValue: ({ fontSize }: Theme) => css`
    color: ${theme.colors.main};
    font-size: ${fontSize[24]};
    font-weight: 700;
  `,
  currency: ({ fontSize, colors }: Theme) => css`
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: ${fontSize[16]};
    svg {
      path {
        fill: ${colors.black};
      }
    }
  `,
  paymentGroupBtn: css`
    padding: 0 15px;
    display: flex;
    gap: 10px;
  `,
  paymentBtn: css`
    width: 100%;
    height: 36px;
    background-image: linear-gradient(to right, #ffb627, #ad1403);
    border-radius: 18px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  `,

  refresh: css`
    transform: rotate(0);
    &[data-loading="loading"] {
      animation: ${spin} 1s linear infinite;
    }
  `,
};
