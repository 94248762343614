import { ReactComponent as StarIcon } from "assets/common/icons/star-solid.svg";

import DefaultAvatar from "./avatar-default.png";
import { ReactComponent as DepositIcon } from "./deposit.svg";
import { ReactComponent as FacebookIcon } from "./facebook.svg";
import { ReactComponent as RefreshIcon } from "./refresh.svg";
import { ReactComponent as TelegramIcon } from "./telegram.svg";
import { ReactComponent as WithdrawIcon } from "./withdraw.svg";
import { ReactComponent as YoutubeIcon } from "./youtube.svg";

export { ReactComponent as IconEyeSlashSolid } from "assets/common/icons/eye-slash-solid.svg";
export { ReactComponent as IconEyeSolid } from "assets/common/icons/eye-solid.svg";
export { ReactComponent as IconDeposit } from "assets/common/icons/icon_deposit-mobile.svg";
export { ReactComponent as IconDepositNew } from "assets/common/icons/icon_deposit-mobileNew.svg";
export { ReactComponent as IconWithdraw } from "assets/common/icons/icon_withdraw-mobile.svg";
export { ReactComponent as IconWithdrawNew } from "assets/common/icons/icon_withdraw-mobileNew.svg";
export { ReactComponent as IconPhone } from "assets/common/icons/phone.svg";
export { ReactComponent as IconWarning } from "assets/common/icons/warning.svg";

export const CommonIcons = {
  Facebook: FacebookIcon,
  Youtube: YoutubeIcon,
  Telegram: TelegramIcon,
  Deposit: DepositIcon,
  Withdraw: WithdrawIcon,
  Refresh: RefreshIcon,
  DefaultAvatar: DefaultAvatar,
  StarIcon: StarIcon,
};
