import { ModalType, ModalTypeValue } from "enums/modalType";
import { useAppSelector } from "hooks/app-hook";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import {
  selectOpenForGotModal,
  selectOpenModal,
  selectOpenModalNotiMobile,
} from "store/master";

import ModalForgetPassword from "./ModalForgetPassword";
import ModalForgot from "./ModalForgot";
import ModalMobileNotification from "./ModalMobileNotification";
import ModalNotiGame from "./ModalNotiGame";
import ModalSignIn from "./ModalSigninNew";
import ModalSignUp from "./ModalSignupNew";

function ModalGroup() {
  const openModal = useAppSelector(selectOpenModal);
  const openModalForgot = useAppSelector(selectOpenForGotModal);
  const openModalMobileNoti = useAppSelector(selectOpenModalNotiMobile);
  if (!openModal) return null;
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_CLIENT_ID_CAPTCHA || ""}
      scriptProps={{
        async: true,
        defer: false,
        nonce: undefined,
      }}
    >
      {openModal === ModalTypeValue.LOGIN && <ModalSignIn />}
      {openModal === ModalTypeValue.REGISTER && <ModalSignUp />}
      {openModal === ModalTypeValue.NOTIGAME && <ModalNotiGame />}
      {openModal === ModalTypeValue.FORGET_PASSWORD && <ModalForgetPassword />}
      {openModalForgot === ModalType.FORGOT && <ModalForgot />}
      {openModalMobileNoti === ModalType.NOTI_MOBILE && (
        <ModalMobileNotification />
      )}
    </GoogleReCaptchaProvider>
  );
}

export default ModalGroup;
