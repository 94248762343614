import { createSlice } from "@reduxjs/toolkit";
import { ApplicationRootState } from "types";
import { BankStateType } from "types/bank";

import { extraReducers } from "./bank.extraReducers";

const initialState: BankStateType = {
  isLoading: false,
  banks: [],
  playerBanks: [],
};

const bankSlice = createSlice({
  name: "bank",
  initialState,
  reducers: {
    resetBank: () => initialState,
  },
  extraReducers,
});

export const selectBanks = (state: ApplicationRootState) => state.bank.banks;

export const selectPlayerBanks = (state: ApplicationRootState) =>
  state.bank.playerBanks;

export const selectIsLoading = (state: ApplicationRootState) =>
  state.bank.isLoading;

export const { resetBank } = bankSlice.actions;

export default bankSlice.reducer;
