/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form, message, Modal } from "antd";
import { AuthApi } from "api/auth";
import { ForgotPasswordFormType } from "api/auth/type";
import { icons } from "assets/mobile";
import InputCustom from "components/mobile/Base/InputCustom";
import { REGEX_USERNAME } from "contants/regex";
import { ResponseCode } from "contants/response";
import { ModalTypeValue } from "enums/modalType";
import { useAppDispatch } from "hooks/app-hook";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { openModal } from "store/master";
import { ApiResponse } from "types/api";
import * as yup from "yup";

import { ModalForgetPasswordStyle } from "./index.style";
interface Props {
  isPage?: boolean;
}
const schema = yup
  .object({
    username: yup
      .string()
      .trim()
      .matches(
        REGEX_USERNAME,
        "Includes alphanumeric, lowercase and 6 to 16 characters",
      )
      .required(),
    mobile: yup.string().trim().required(),
    email: yup.string().trim().email().required(),
  })
  .required();
const ModalForgetPassword: React.FC<Props> = ({ isPage = false }) => {
  const dispatch = useAppDispatch();

  const [dataPassword, setDataPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [valueMobile, setValueMobile] = useState<string>("");

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<ForgotPasswordFormType>({
    resolver: yupResolver(schema),
    mode: "all",
  });
  const onSubmit: SubmitHandler<ForgotPasswordFormType> = async (
    data: ForgotPasswordFormType,
  ) => {
    setIsLoading(true);
    const datForm = {
      ...data,
      mobile: valueMobile.replace("+", ""),
    };
    const res = await AuthApi.forgotPassword(datForm.username, datForm);

    const dataResult = res.data as ApiResponse;

    if (dataResult.status !== ResponseCode.SUCCESS) {
      message.error({
        key: "forgotPassword",
        content: dataResult.msg,
      });
      setDataPassword("");
      setIsLoading(false);
      return;
    }

    message.success({
      key: "forgotPassword",
      content: "Recovery Success!",
    });
    setDataPassword(dataResult.msg);
    setIsLoading(false);
    // reset();
  };

  return (
    <Modal
      open
      centered
      footer={<></>}
      focusTriggerAfterClose={false}
      forceRender
      closable={false}
      css={ModalForgetPasswordStyle.modalStyle}
      onCancel={() => dispatch(openModal(ModalTypeValue.CLOSE))}
    >
      <div
        onClick={() => dispatch(openModal(ModalTypeValue.CLOSE))}
        css={ModalForgetPasswordStyle.closeButton}
      >
        <icons.IconFormClose />
      </div>
      <div css={ModalForgetPasswordStyle.content}>
        <div
          css={css`
            flex: 1;
          `}
        />
        <div css={ModalForgetPasswordStyle.formContainer}>
          <Form
            css={ModalForgetPasswordStyle.form}
            onSubmitCapture={handleSubmit(onSubmit)}
            autoComplete="off"
          >
            <div className="input-col">
              <label>
                <icons.StarIconForm />
                <span>Username</span>
              </label>
              <InputCustom
                disabled={isLoading || dataPassword.length > 0}
                placeholder={""}
                name={"username"}
                control={control}
                margin_bottom="6px"
                role="presentation"
                id={"username"}
                autoComplete="new-password"
                errors={errors.username?.message}
                validate_status={errors.username ? "error" : "success"}
              />
              <p className="note">
                Must Be Greater Than 5 Characters, Must Begin With A Letter, No
                Additional Characters Special.
              </p>
            </div>
            <div className="input-col">
              <label>
                <icons.StarIconForm />
                <span>Phone number</span>
              </label>
              <InputCustom
                value={valueMobile}
                disabled={isLoading || dataPassword.length > 0}
                placeholder={""}
                name={"mobile"}
                control={control}
                margin_bottom="6px"
                role="presentation"
                type="number"
                id={"mobile"}
                autoComplete="new-password"
                errors={errors.mobile?.message}
                validate_status={errors.mobile ? "error" : "success"}
                setValueProps={(value: string) => {
                  if (value.length > 10) return;
                  const regex = new RegExp(/^[0-9]+$/);

                  if (!regex.test(value)) {
                    if (value.length === 0 && value === "") setValueMobile("");
                    return;
                  }

                  setValueMobile(value);
                }}
              />
              <p className="note">
                Enter phone number starting with 84，For example 84868888789
              </p>
            </div>
            <div className="input-col">
              <label>
                <icons.StarIconForm />
                <span>Email address</span>
              </label>
              <InputCustom
                disabled={isLoading || dataPassword.length > 0}
                placeholder={""}
                name={"email"}
                control={control}
                margin_bottom="6px"
                role="presentation"
                id={"email"}
                autoComplete="new-password"
                errors={errors.email?.message}
                validate_status={errors.email ? "error" : "success"}
              />
              <p className="note">
                Email needs to be filled in correctly to assist in retrieving
                your password when you forget it (Obligatory )
              </p>
            </div>
            {dataPassword && (
              <div css={ModalForgetPasswordStyle.resultPassword}>
                {dataPassword}
              </div>
            )}
            <div css={ModalForgetPasswordStyle.groupButton}>
              <Button
                onClick={() => {
                  if (isPage) {
                    dispatch(openModal(ModalTypeValue.CLOSE));
                    return;
                  }
                  dispatch(openModal(ModalTypeValue.LOGIN));
                }}
                className="tButton btn-login"
              >
                <span>Login</span>
              </Button>
              <Button
                disabled={!isValid}
                loading={isLoading}
                htmlType="submit"
                className="tButton btn-register"
              >
                <span>Confirm</span>
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default ModalForgetPassword;
