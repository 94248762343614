import { ReactComponent as EventIcon } from "assets/mobile/icons/menu/event.svg";
import { ReactComponent as FavouritIcon } from "assets/mobile/icons/menu/favourite.svg";
import { ReactComponent as HistoryIcon } from "assets/mobile/icons/menu/history.svg";
import { ReactComponent as ListIcon } from "assets/mobile/icons/menu/list.svg";
import { ReactComponent as MailIcon } from "assets/mobile/icons/menu/mail.svg";
import { ReactComponent as NotificationIcon } from "assets/mobile/icons/menu/notification.svg";
import { ReactComponent as RefundIcon } from "assets/mobile/icons/menu/refund.svg";
import { ReactComponent as SecurityIcon } from "assets/mobile/icons/menu/security.svg";

import { ReactComponent as MessageIcon } from "./icons/annoucement/message-icon.svg";
import { ReactComponent as AppDownload } from "./icons/app-download.svg";
import { ReactComponent as ArrowLeftSidebar } from "./icons/arrow-left-sidebar.svg";
import { ReactComponent as ArrowRightIcon } from "./icons/arrow-right-icon.svg";
import { ReactComponent as AgencyBottomTab } from "./icons/bottom-tab/agency.svg";
import { ReactComponent as HomeBottomTab } from "./icons/bottom-tab/home.svg";
import { ReactComponent as LiveChatBottomTab } from "./icons/bottom-tab/livechat.svg";
import { ReactComponent as PromotionBottomTab } from "./icons/bottom-tab/promotion.svg";
import { ReactComponent as UserBottomTab } from "./icons/bottom-tab/user.svg";
import { ReactComponent as CloseDrawIcon } from "./icons/close-draw.svg";
import { ReactComponent as IconInputAccount } from "./icons/form/account.svg";
import { ReactComponent as IconFormClose } from "./icons/form/form-close.svg";
import { ReactComponent as IconInputPassword } from "./icons/form/password.svg";
import { ReactComponent as StarIconForm } from "./icons/form/star-icon-form.svg";
import { ReactComponent as AgencyCommission } from "./icons/icon-sidebar/agency-commission.svg";
import { ReactComponent as AgencyRegistration } from "./icons/icon-sidebar/agency-registration.svg";
import { ReactComponent as BeautifulGirl } from "./icons/icon-sidebar/beautiful-girl.svg";
import { ReactComponent as BrandPartner } from "./icons/icon-sidebar/brand-partner.svg";
import { ReactComponent as Desktop } from "./icons/icon-sidebar/desktop.svg";
import { ReactComponent as DownloadApp } from "./icons/icon-sidebar/dowload-app.svg";
import { ReactComponent as Hotline } from "./icons/icon-sidebar/hotline.svg";
import { ReactComponent as Introduction } from "./icons/icon-sidebar/introduction.svg";
import { ReactComponent as Movie18 } from "./icons/icon-sidebar/movie-18+.svg";
import { ReactComponent as Telegram } from "./icons/icon-sidebar/telegram.svg";
import daily from "./icons/menu/daily.png";
import discount from "./icons/menu/discount.png";
import gift from "./icons/menu/gift.png";
import { ReactComponent as MenuButton } from "./icons/menuButton.svg";
import { ReactComponent as SignOutIcon } from "./icons/sign-out.svg";
import { ReactComponent as DepositOfflineIcon } from "./icons/transaction/deposit-offline.svg";
import { ReactComponent as DepositOnlineIcon } from "./icons/transaction/deposit-online.svg";

export const icons = {
  //
  gift: gift,
  discount: discount,
  daily: daily,
  //
  MenuButton: MenuButton,
  AppDownload: AppDownload,
  ArrowLeftSidebar: ArrowLeftSidebar,
  SignOut: SignOutIcon,
  ArrowRightIcon: ArrowRightIcon,
  closeDrawIcon: CloseDrawIcon,
  // sidebar
  AgencyCommission: AgencyCommission,
  AgencyRegistration: AgencyRegistration,
  BeautifulGirl: BeautifulGirl,
  BrandPartner: BrandPartner,
  Desktop: Desktop,
  DownloadApp: DownloadApp,
  Hotline: Hotline,
  Introduction: Introduction,
  Movie18: Movie18,
  Telegram: Telegram,
  // bottom tabs
  AgencyBottomTab: AgencyBottomTab,
  HomeBottomTab: HomeBottomTab,
  LiveChatBottomTab: LiveChatBottomTab,
  PromotionBottomTab: PromotionBottomTab,
  UserBottomTab: UserBottomTab,
  //form
  IconInputAccount: IconInputAccount,
  IconInputPassword: IconInputPassword,
  IconFormClose: IconFormClose,
  StarIconForm: StarIconForm,
  // member menu
  mailIcon: MailIcon,
  historyIcon: HistoryIcon,
  listIcon: ListIcon,
  eventIcon: EventIcon,
  favouriteIcon: FavouritIcon,
  securityIcon: SecurityIcon,
  notificationIcon: NotificationIcon,
  refundIcon: RefundIcon,
  // announcement
  MessageIcon: MessageIcon,
  //transaction
  DepositOfflineIcon: DepositOfflineIcon,
  DepositOnlineIcon: DepositOnlineIcon,
};
