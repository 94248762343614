/** @jsxImportSource @emotion/react */

import { Modal } from "antd";
import { ModalType } from "enums/modalType";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import {
  selectOpenModalNotiMobile,
  setOpenModalNotiMobile,
} from "store/master";

import { ModalNotiStyle } from "./style.index";
interface Props {
  title: string;
}
const ModalNotiMobile: React.FC<Props> = ({ title }) => {
  const dispatch = useAppDispatch();

  const openNotiMobileModal = useAppSelector(selectOpenModalNotiMobile);

  return (
    <Modal
      open={openNotiMobileModal === ModalType.NOTI_MOBILE}
      // onCancel={() => dispatch(setOpenModalNoti(ModalType.CLOSE_NOTI))}
      footer={null}
      css={ModalNotiStyle.wrapper}
      closable={false}
      centered
    >
      <div css={ModalNotiStyle.mainContent}>
        <div css={ModalNotiStyle.body}>
          <span>{title}</span>
        </div>
        <div css={ModalNotiStyle.footer}>
          <div
            onClick={() =>
              dispatch(setOpenModalNotiMobile(ModalType.CLOSE_NOTI))
            }
          >
            Confirm
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalNotiMobile;
