import * as cockFighting from "assets/common/images/game/desktop/menu/cockFighting";
import { SubGameType } from "types/game";

export const dataCockFighting: SubGameType[] = [
  {
    game_code: "",
    picture: cockFighting.desktopCockFightingWS,
    provider_code: "DS88",
    type: "CF",
    name: "",
    featured: "",
    new_arrive: "",
  },
];
