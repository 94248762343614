import axios from "axios";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setClientCountryCode } from "store/master";

import { useAppDispatch } from "./app-hook";

const LangsKey: {
  [key: string]: string;
} = {
  VN: "vi",
  EN: "en",
};

const languageDefault = localStorage.getItem("i18nLng");

export const useIP = () => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();

  const handleLanguage = useCallback(async () => {
    if (languageDefault) {
      dispatch(setClientCountryCode(languageDefault));
      i18n.changeLanguage(languageDefault);
      return;
    }

    const { data } = await axios.get("https://geolocation-db.com/json/");

    const language = LangsKey[data.country_code as string];

    if (!language) {
      dispatch(setClientCountryCode("vi"));
      i18n.changeLanguage("vi");
      return;
    }

    dispatch(setClientCountryCode(language));
    i18n.changeLanguage(language);
  }, [i18n]);

  useEffect(() => {
    handleLanguage();
  }, [handleLanguage]);
};
