/** @jsxImportSource @emotion/react */

import { SearchOutlined } from "@ant-design/icons";
import { Empty, Input } from "antd";
import LoadingCustom from "components/common/LoadingCustom";
import CategoryGameSwiper from "components/mobile/CategoryGameSwiper";
import { LIST_GAME_FILTER_M } from "contants/game-filter";
import handleLoginGame from "helpers/handleLoginGame";
import { filterSubGame } from "helpers/utilFilterSubGame";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useCallback, useEffect, useState } from "react";
import { selectUsername } from "store/auth";
import { selectDataSubGame, selectIsLoading } from "store/game";
import { getSubGameList } from "store/game/game.thunk";
import { SubGameType } from "types/game";
import { TypeSubGameProps } from "types/sub-game";

import { FishingGameMobileStyle } from "./style.index";

const FishingGame = () => {
  const dispatch = useAppDispatch();

  const dataSubGame = useAppSelector(selectDataSubGame);
  const isLoading = useAppSelector(selectIsLoading);
  const username = useAppSelector(selectUsername);

  const [state, setState] = useState<TypeSubGameProps>({
    activeSortId: "all",
    search: "",
    sortId: "",
    currentPage: 1,
    sortTitle: "All Game",
  });

  useEffect(() => {
    dispatch(
      getSubGameList({
        provider: "",
        featured: state.sortId,
        game_type: "FH",
        lobby: 0,
        new: "",
        page: state.currentPage,
        size: 100,
      }),
    );
  }, [state.currentPage, state.sortId]);

  const listFilter = dataSubGame.data.filter((itemFilter: SubGameType) =>
    filterSubGame(itemFilter, state.search),
  );

  const renderSubGameList = useCallback(() => {
    if (isLoading) return <LoadingCustom width={"100%"} center={true} />;

    if (dataSubGame?.data?.length <= 0)
      return <Empty css={FishingGameMobileStyle.empty} />;

    if (listFilter.length <= 0)
      return <Empty css={FishingGameMobileStyle.empty} />;

    return (
      <div css={FishingGameMobileStyle.listSubGame}>
        {listFilter?.map((itemSubGame, index) => (
          <div
            onClick={() => {
              handleLoginGame(itemSubGame, username, dispatch);
            }}
            key={index}
            css={FishingGameMobileStyle.cardSubGame}
          >
            <img src={itemSubGame.picture} alt="" />
            <div css={FishingGameMobileStyle.nameCardItem}>
              {itemSubGame.name}
            </div>
          </div>
        ))}
      </div>
    );
  }, [isLoading, dataSubGame, state.search]);

  const handleSortSubGame = (valueSort: string) => {
    if (valueSort === "hot") {
      setState((prev) => ({
        ...prev,
        sortId: "1",
        activeSortId: "hot",
        sortTitle: "Hot game",
      }));
      return;
    }
    setState((prev) => ({
      ...prev,
      sortId: "",
      activeSortId: "all",
      sortTitle: "All Game",
    }));
  };

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      search: e.target.value,
    }));
  };

  return (
    <div css={FishingGameMobileStyle.wrapper}>
      <CategoryGameSwiper />

      <div css={FishingGameMobileStyle.filterGame.index}>
        <div css={FishingGameMobileStyle.filterGame.search}>
          <Input
            onChange={onChange}
            value={state.search}
            placeholder="VD: Dragon Ball"
            suffix={
              <SearchOutlined
                style={{
                  fontSize: "14px",
                  color: "black",
                }}
              />
            }
          />
        </div>
        <div css={FishingGameMobileStyle.filterGame.boxWrap}>
          {LIST_GAME_FILTER_M.map((item, index) => (
            <div
              key={index}
              css={FishingGameMobileStyle.boxFilter(
                item.id === state.activeSortId,
              )}
              onClick={() => {
                handleSortSubGame(item.id);
              }}
            >
              <img src={item.icon} alt="" />
              <span>{item.name}</span>
            </div>
          ))}
        </div>
      </div>
      <div css={FishingGameMobileStyle.titleSubGame}>
        <span>{state.sortTitle}</span>
      </div>

      {renderSubGameList()}
    </div>
  );
};

export default FishingGame;
