import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import {
  ListBankResponseType,
  ListPlayerBankResponseType,
} from "api/bank/type";
import { ResponseText } from "contants/response";
import { NoInfer } from "react-redux";
import { BankStateType } from "types/bank";

import { getListBank, getListPlayerBank } from "./bank.thunk";

export const extraReducers = (
  builder: ActionReducerMapBuilder<NoInfer<BankStateType>>,
) => {
  builder
    .addCase(getListBank.pending, (state: BankStateType) => {
      state.isLoading = true;
    })
    .addCase(
      getListBank.fulfilled,
      (state: BankStateType, action: PayloadAction<ListBankResponseType>) => {
        state.isLoading = false;
        if (action.payload.msg !== ResponseText.SUCCESS) return;
        state.banks = [...action.payload.data];
      },
    );

  builder
    .addCase(getListPlayerBank.pending, (state: BankStateType) => {
      state.isLoading = true;
    })
    .addCase(
      getListPlayerBank.fulfilled,
      (
        state: BankStateType,
        action: PayloadAction<ListPlayerBankResponseType>,
      ) => {
        state.isLoading = false;
        if (action.payload.msg !== ResponseText.SUCCESS) return;
        state.playerBanks = [...action.payload.data];
      },
    );
};
