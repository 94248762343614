/** @jsxImportSource @emotion/react */

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form, Modal } from "antd";
import { AuthApi } from "api/auth";
import { ForgotPasswordFormType } from "api/auth/type";
import { icons } from "assets/desktop";
import InputCustom from "components/desktop/base/InputCustom";
import { REGEX_USERNAME } from "contants/regex";
import { ResponseCode } from "contants/response";
import { ModalTypeValue } from "enums/modalType";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { selectIsLoading } from "store/auth";
import { openModal } from "store/master";
import { outletContextType } from "types";
import { ApiResponse } from "types/api";
import * as yup from "yup";

import WrapperAuthForm from "../WrapperAuthForm";
import { ModalForgetPasswordStyle } from "./index.style";
const schema = yup
  .object({
    username: yup
      .string()
      .trim()
      .matches(
        REGEX_USERNAME,
        "Includes alphanumeric, lowercase and 6 to 16 characters",
      )
      .required(),
    mobile: yup.string().trim().required(),
    email: yup.string().trim().email().required(),
  })
  .required();
function ModalForgetPassword() {
  const { openNotification } = useOutletContext<outletContextType>();

  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsLoading);

  const [dataPassword, setDataPassword] = useState<string>("");
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [valueMobile, setValueMobile] = useState<string>("");

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<ForgotPasswordFormType>({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const onSubmit: SubmitHandler<ForgotPasswordFormType> = async (
    data: ForgotPasswordFormType,
  ) => {
    const datForm = {
      ...data,
      mobile: valueMobile.replace("+", ""),
    };
    const res = await AuthApi.forgotPassword(datForm.username, datForm);

    const dataResult = res.data as ApiResponse;

    if (dataResult.status !== ResponseCode.SUCCESS) {
      openNotification("error", dataResult.msg);
      setDataPassword("");
      return;
    }
    setIsDisable(true);
    openNotification("success", "Recovery Success!");
    setDataPassword(dataResult.msg);
  };

  return (
    <Modal
      open
      centered
      footer={<></>}
      focusTriggerAfterClose={false}
      forceRender
      closable={false}
      css={ModalForgetPasswordStyle.modalStyle}
      onCancel={() => dispatch(openModal(ModalTypeValue.CLOSE))}
    >
      <WrapperAuthForm title="Forgot password">
        <Form
          css={ModalForgetPasswordStyle.formContainer}
          onSubmitCapture={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <div css={ModalForgetPasswordStyle.formInput}>
            <label>
              <icons.StartIconForm />
              <span>Account name</span>
            </label>
            <InputCustom
              disabled={isLoading || !!dataPassword}
              name={"username"}
              control={control}
              margin_bottom="7px"
              autoComplete="off"
              validate_status={errors.username ? "error" : "success"}
            />
            <p className="note">
              Must be greater than 5 characters, must begin with a letter, do
              not include characters special self.
            </p>
          </div>
          <div css={ModalForgetPasswordStyle.formInput}>
            <label>
              <icons.StartIconForm />
              <span>Phone number</span>
            </label>
            <InputCustom
              disabled={isLoading || !!dataPassword}
              name={"mobile"}
              control={control}
              margin_bottom="7px"
              autoComplete="off"
              validate_status={errors.mobile ? "error" : "success"}
              value={valueMobile}
              setValueProps={(value: string) => {
                if (value.length > 10) return;
                const regex = new RegExp(/^[0-9]+$/);

                if (!regex.test(value)) {
                  if (value.length === 0 && value === "") setValueMobile("");
                  return;
                }

                setValueMobile(value);
              }}
            />
            <p className="note">
              Phone Numbers Starting With 84, For example 84868789789
            </p>
          </div>
          <div css={ModalForgetPasswordStyle.formInput}>
            <label>
              <icons.StartIconForm />
              <span>Email address</span>
            </label>
            <InputCustom
              disabled={isLoading || !!dataPassword}
              name={"email"}
              control={control}
              margin_bottom="7px"
              type="email"
              autoComplete="off"
              validate_status={errors.email ? "error" : "success"}
            />
            <p className="note">
              Email must be filled in correctly to assist in retrieving your
              password when you forget it (Obligatory)
            </p>
          </div>
          {dataPassword && (
            <div css={ModalForgetPasswordStyle.valuePass}>
              <span>{dataPassword}</span>
            </div>
          )}
          <div css={ModalForgetPasswordStyle.buttons}>
            <Button
              htmlType="submit"
              disabled={!isValid || isDisable}
              loading={isLoading}
            >
              <span>Confirm</span>
            </Button>
          </div>
          <div css={ModalForgetPasswordStyle.loginRequest}>
            Do you already have an account?{" "}
            <span onClick={() => dispatch(openModal(ModalTypeValue.LOGIN))}>
              Log in now
            </span>
          </div>
        </Form>
      </WrapperAuthForm>
    </Modal>
  );
}

export default ModalForgetPassword;
