/** @jsxImportSource @emotion/react */

import { Modal } from "antd";
import { ModalType } from "enums/modalType";
import { useAppDispatch } from "hooks/app-hook";
import { useSelector } from "react-redux";
import { selectOpenForGotModal, setOpenModalForgot } from "store/master";

import { ModalForgotStyle } from "./style.index";

const ModalForgot = () => {
  const dispatch = useAppDispatch();

  const openForgotModal = useSelector(selectOpenForGotModal);

  return (
    <Modal
      open={openForgotModal === ModalType.FORGOT}
      // onCancel={() => dispatch(setOpenModalNoti(ModalType.CLOSE_NOTI))}
      footer={null}
      css={ModalForgotStyle.wrapper}
      closable={false}
      centered
      style={{ top: "-35%", left: "2%" }}
    >
      <div css={ModalForgotStyle.mainContent}>
        <div css={ModalForgotStyle.header}>
          <span>Forget</span>
        </div>
        <div css={ModalForgotStyle.body}>
          {/* <InfoCircleFilled style={{ color: "#31708f" }} /> */}
          <span>Please contact customer service for support</span>
        </div>
        <div css={ModalForgotStyle.footer}>
          <div
            onClick={() => dispatch(setOpenModalForgot(ModalType.CLOSE_FORGOT))}
          >
            confirm
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalForgot;
