/** @jsxImportSource @emotion/react */
import Icon from "@ant-design/icons";
import { css } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form, message, Modal } from "antd";
import { LoginFormType, LoginResponse } from "api/auth/type";
import { ReactComponent as RefreshIcon } from "assets/common/icons/refresh.svg";
import { ModalKeySignIn } from "assets/common/images/modal";
import { icons } from "assets/mobile";
import InputCustom from "components/mobile/Base/InputCustom";
import InputPassword from "components/mobile/Base/InputPassword";
import { LIST_BACKGROUND_CAPTCHA } from "contants/captcha";
import { REGEX_PASSWORD, REGEX_USERNAME } from "contants/regex";
import { ResponseCode } from "contants/response";
import { ModalTypeValue } from "enums/modalType";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { selectIsLoading } from "store/auth";
import { signInWithCredentials } from "store/auth/auth.thunk";
import { openModal } from "store/master";
import * as yup from "yup";

import { ModalSignInStyle } from "./index.style";
interface State {
  valueCaptcha: string;
  numberBg: number;
  title: string;
}
const schema = yup
  .object({
    username: yup
      .string()
      .trim()
      .matches(
        REGEX_USERNAME,
        `Must be greater than 5 characters, must begin with a letter, do not include special characters.`,
      )
      .required("Please enter your account"),
    password: yup
      .string()
      .trim()
      .matches(
        REGEX_PASSWORD,
        "More than 6 characters must include letters and numbers",
      )
      .required("Please enter a password"),
    captcha: yup
      .string()
      .trim()
      .required(
        "The confirmation code cannot be left blank, please get the confirmation code",
      ),
  })
  .required();
function ModalSignIn() {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsLoading);

  const [state, setState] = useState<State>({
    valueCaptcha: "",
    numberBg: 0,
    title: "",
  });

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors, isValid },
  } = useForm<LoginFormType>({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const [isCaptcha] = useState(true);
  // const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit: SubmitHandler<LoginFormType> = async (
    data: LoginFormType,
  ) => {
    // if (!executeRecaptcha) {
    //   setIsCaptcha(false);
    //   return;
    // }
    // const gReCaptchaToken = await executeRecaptcha("Signup");
    // if (!gReCaptchaToken) {
    //   setIsCaptcha(false);
    //   return;
    // }
    // setIsCaptcha(true);
    if (!data.captcha) {
      setError("captcha", {
        type: "custom",
        message:
          "The confirmation code cannot be left blank, please get the confirmation code",
      });

      return;
    }

    if (!(state.valueCaptcha === data.captcha)) {
      setError("captcha", {
        type: "custom",
        message: "Verification code error or input error, please try again",
      });
      return;
    }
    const res = await dispatch(
      signInWithCredentials({
        password: data.password,
        username: data.username,
      }),
    );
    const resData = res.payload as LoginResponse;
    if (resData.status === ResponseCode.SUCCESS) {
      message.success({
        key: "Login",
        content: `Login is success, Welcome ${data.username}`,
      });
      dispatch(openModal(ModalTypeValue.CLOSE));
      return;
    }
    message.error({
      key: "Login",
      content: resData.msg,
    });
  };

  const generateRandomNumbers = () => {
    const newRandomNumbers: number[] = [];

    while (newRandomNumbers.length < 4) {
      const randomNumber = Math.floor(Math.random() * 10);
      newRandomNumbers.push(randomNumber);
    }

    setState((prev) => ({
      ...prev,
      numberBg: Math.floor(Math.random() * 7),
      valueCaptcha: newRandomNumbers.join(""),
    }));
  };
  return (
    <Modal
      open
      centered
      footer={<></>}
      focusTriggerAfterClose={false}
      forceRender
      closable={false}
      css={ModalSignInStyle.modalStyle}
      onCancel={() => dispatch(openModal(ModalTypeValue.CLOSE))}
    >
      <div
        onClick={() => dispatch(openModal(ModalTypeValue.CLOSE))}
        css={ModalSignInStyle.closeButton}
      >
        <icons.IconFormClose />
      </div>
      <div css={ModalSignInStyle.content}>
        <div
          css={css`
            flex: 1;
          `}
        />
        <div css={ModalSignInStyle.formContainer}>
          <Form
            css={ModalSignInStyle.form}
            onSubmitCapture={handleSubmit(onSubmit)}
            autoComplete="off"
          >
            <div className="input-row">
              <Icon className="icon" component={icons.IconInputAccount} />
              <InputCustom
                disabled={isLoading}
                placeholder={"Please enter your account name"}
                name={"username"}
                control={control}
                margin_bottom="12px"
                role="presentation"
                id={"username"}
                autoComplete="new-password"
                errors={errors.username?.message}
                validate_status={errors.username ? "error" : "success"}
              />
            </div>
            <div className="input-row input-pass">
              <Icon className="icon" component={icons.IconInputPassword} />
              <InputPassword
                disabled={isLoading}
                placeholder={"Please enter a password"}
                name={"password"}
                role="presentation"
                id={"password"}
                control={control}
                margin_bottom="12px"
                error={errors.password?.message}
                autoComplete="new-password"
                validate_status={errors.password ? "error" : "success"}
              />
            </div>
            <div className="input-row">
              <img src={ModalKeySignIn} alt="" className="icon-img" />

              <InputCustom
                disabled={isLoading}
                placeholder={"Please enter verification code"}
                name={"captcha"}
                onClick={generateRandomNumbers}
                type="number"
                control={control}
                margin_bottom="12px"
                role="presentation"
                id={"captcha"}
                autoComplete="new-password"
                errors={errors.captcha?.message}
                validate_status={errors.captcha ? "error" : "success"}
              />
              <div
                css={css`
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  gap: 5px;
                  position: absolute;
                  z-index: 99;
                  top: ${state.valueCaptcha
                    ? "calc((50px - 46px) / 2)"
                    : "16px"};
                  right: 0;
                  /* transform: translateY(80%); */

                  svg {
                    width: 18px;
                    height: 18px;
                    margin-right: 5px;
                  }
                `}
              >
                {state.valueCaptcha && (
                  <div
                    css={ModalSignInStyle.blockCaptcha(
                      LIST_BACKGROUND_CAPTCHA[state.numberBg],
                    )}
                    onClick={generateRandomNumbers}
                  >
                    <span>{state.valueCaptcha}</span>
                  </div>
                )}
                <RefreshIcon onClick={generateRandomNumbers} />
              </div>
            </div>
            <div css={ModalSignInStyle.note}>
              <span>
                Please preserve your account information to ensure safety your
                account
              </span>
            </div>
            {!isCaptcha && (
              <div css={ModalSignInStyle.recaptcha}>
                Unable to log in to account. Please try again later.
              </div>
            )}
            <div css={ModalSignInStyle.buttons}>
              <Button
                onClick={() => dispatch(openModal(ModalTypeValue.REGISTER))}
                className="button button-register"
              >
                <span>Register</span>
              </Button>
              <Button
                disabled={!isValid}
                loading={isLoading}
                htmlType="submit"
                className="button button-login"
              >
                <span>Login</span>
              </Button>
            </div>
            <div css={ModalSignInStyle.forgetButton}>
              <div
                onClick={() =>
                  dispatch(openModal(ModalTypeValue.FORGET_PASSWORD))
                }
                className="link"
              >
                Forgot password ?
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
}

export default ModalSignIn;
