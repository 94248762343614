/** @jsxImportSource @emotion/react */

import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { useAppSelector } from "hooks/app-hook";
import { selectFilterSubGame } from "store/game";

import { SearchStyle } from "./style.index";

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  color: string;
}

const Search = ({ onChange, color }: Props) => {
  const filterSubGame = useAppSelector(selectFilterSubGame);
  return (
    <Input
      value={filterSubGame}
      css={SearchStyle.wrapper(color)}
      onChange={onChange}
      placeholder="Please enter game name ..."
      suffix={
        <SearchOutlined
          style={{
            fontSize: "20px",
            color: "white",
          }}
        />
      }
    />
  );
};

export default Search;
