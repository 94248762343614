import { css } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const CardGameStyle = {
  wrapper: css`
    position: relative;
    overflow: hidden;
    cursor: pointer;

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 10px;

      display: flex;
      align-items: flex-end;
      justify-content: center;

      opacity: 0;
      visibility: hidden;
      transition-duration: 0.3s;
    }

    .iconGame {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 10px;

      display: flex;
      align-items: flex-end;
      justify-content: center;

      opacity: 1;
      visibility: unset;

      bottom: 5%;
      transition-duration: 0.3s;
    }

    &:hover {
      .overlay {
        opacity: 1;
        visibility: visible;
        transition-duration: 0.3s;
        svg {
          transform: scale(1.2);
          transition: transform 0.5s ease-in-out;
        }
      }
      .iconGame {
        opacity: 0;
        visibility: hidden;
        transition-duration: 0.3s;
      }
      [data-comp="hover-eff"] {
        opacity: 0.5;
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 133px;
      background: linear-gradient(
        to bottom,
        rgba(13, 13, 13, 0) 2%,
        rgba(0, 0, 0, 0.8) 42%,
        #151111 92%
      );
    }
  `,
  heart: css`
    position: absolute;
    cursor: pointer;
    top: 8px;
    right: 8px;
  `,
  textBox: css`
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;

    text-align: center;
    margin-bottom: 10px;

    h3 {
      color: #fff;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.24);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      ${media.sm} {
        font-size: 12px;
        line-height: unset;
      }
    }
    span {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      ${media.sm} {
        font-size: 10px;
        line-height: unset;
      }
    }
  `,

  icon: css`
    width: 36px;
    height: 36px;
    margin-bottom: 5%;
  `,
  triangleStyle: css`
    width: 0;
    border-left: 80px solid transparent;
    border-right: 80px solid transparent;
    border-top: 120px solid ${theme.colors.main};
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%) scale(1.6);
    filter: blur(2rem);
    z-index: 2;
  `,
  contentImg: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
};
