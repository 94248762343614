import { css } from "@emotion/react";

export const ModalNotiStyle = {
  wrapper: css`
    width: 320px !important;
    height: 185px !important;

    .ant-modal-content {
      padding: 0 !important;
    }
  `,

  mainContent: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
  `,
  header: css`
    display: flex;
    flex-direction: column;
    padding: 20px 20px 0 20px;
    h4 {
      font-size: 16px;
      font-weight: 500;
    }
    span {
      color: #cfcfcf;
      font-size: 12px;
      line-height: 22px;
    }
  `,
  body: css`
    display: flex;
    align-items: center;
    padding: 0 20px;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
  `,
  footer: css`
    height: 50px;
    display: flex;
    justify-content: flex-end;

    div {
      width: 83px;
      height: 34px;
      padding: 6px 12px !important;
      background-color: #337ab7;
      color: white;

      font-weight: normal;
      text-align: center;
      white-space: nowrap;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      border-radius: 4px;

      &:hover {
        background-color: #286090;
      }
    }
  `,
};
