import { css } from "@emotion/react";

export const CockFightingMobileGameStyle = {
  wrapper: css``,

  mainContent: css`
    margin-bottom: 50px;
    padding: 12px 16px;

    display: grid;
    grid-template-columns: repeat(2, 1fr);

    column-gap: 8px;
    row-gap: 6px;
  `,

  item: css`
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    span {
      position: absolute;
      right: 8px;
      top: 47px;

      font-size: 9.9px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      color: white;
    }
  `,
};
