import { css, Theme } from "@emotion/react";
import { theme } from "styles/theme";

export const MenuUserStyle = {
  self: ({ colors }: Theme) => css`
    background-color: ${colors.gray[350]};
    border-radius: 0 0 10px 10px;
    padding-bottom: 20px;

    & .ant-menu {
      background: unset;
      &-item {
        padding: 0 40px;
        color: ${colors.white};
        width: 100%;
        height: 56px;
        margin: 0;
        border-radius: 0;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 2px;

        &:hover {
          color: ${colors.white} !important;
        }
        &-selected {
          background-color: ${theme.colors.main};
        }
      }
    }
  `,
};
