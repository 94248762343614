/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Dispatch, SetStateAction } from "react";
import { isMobile } from "router";
interface Props {
  currentPage: number;
  setCurrentPage?: Dispatch<SetStateAction<number>>;
  handleCurrentPage?: (page: number) => void;
  totalPage: number;
}

export const PaginationCustom = (props: Props) => {
  const { currentPage, setCurrentPage, totalPage, handleCurrentPage } = props;
  const colorBg = isMobile ? "white" : "black";
  const colorText = isMobile ? "black" : "white";
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 4px;
      `}
    >
      <div
        css={css`
          padding: 0 10px;
          font-size: 16px;
          background-color: ${colorBg};
          color: ${colorText};
          height: 25px;
          display: flex;
          align-items: center;
          cursor: pointer;
        `}
        onClick={() => {
          setCurrentPage?.(1);
          handleCurrentPage?.(1);
        }}
      >{`<<`}</div>
      <div
        css={css`
          padding: 0 10px;
          font-size: 16px;
          background-color: ${colorBg};
          color: ${colorText};
          height: 25px;
          display: flex;
          align-items: center;
          cursor: pointer;
        `}
        onClick={() => {
          if (currentPage === 1) return;
          setCurrentPage?.(currentPage - 1);
          handleCurrentPage?.(currentPage - 1);
        }}
      >{`<`}</div>
      <div
        css={css`
          padding: 0 10px;
          font-size: 16px;
          background-color: ${colorBg};
          color: ${colorText};
          height: 25px;
          display: flex;
          align-items: center;
          cursor: pointer;
        `}
      >
        {currentPage} / {totalPage}
      </div>

      <div
        css={css`
          padding: 0 10px;
          font-size: 16px;
          background-color: ${colorBg};
          color: ${colorText};
          height: 25px;
          display: flex;
          align-items: center;
          cursor: pointer;
        `}
        onClick={() => {
          if (currentPage === totalPage) return;
          setCurrentPage?.(currentPage + 1);
          handleCurrentPage?.(currentPage + 1);
        }}
      >{`>`}</div>

      <div
        css={css`
          padding: 0 10px;
          font-size: 16px;
          background-color: ${colorBg};
          color: ${colorText};
          height: 25px;
          display: flex;
          align-items: center;
          cursor: pointer;
        `}
        onClick={() => {
          setCurrentPage?.(totalPage);
          handleCurrentPage?.(totalPage);
        }}
      >{`>>`}</div>
    </div>
  );
};
