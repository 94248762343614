import "./assets/css/input.css";
import "./i18n";

import { ThemeProvider } from "@emotion/react";
import { notification } from "antd";
import { AuthApi } from "api/auth";
import HelmetCustom from "components/common/HelmetCustom";
import { ResponseCode } from "contants/response";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useIP } from "hooks/useIP";
import { useAppLogout } from "hooks/useLogout";
import { useCallback, useEffect, useRef } from "react";
import { Outlet } from "react-router";
import { getAnnouncement } from "store/announcement/announcement.thunk";
import { selectIsAuthenticated, selectToken, selectUsername } from "store/auth";
import { getMemberBalance, getProfile } from "store/user/user.thunk";
import GlobalStyles from "styles/global";
import { theme } from "styles/theme";
import { outletContextType, StatusNotification } from "types";

function App() {
  useIP();
  const onLogout = useAppLogout();
  const dispatch = useAppDispatch();
  const isAuth = useAppSelector(selectIsAuthenticated);

  const token = useAppSelector(selectToken) || localStorage.getItem("token");
  const username =
    useAppSelector(selectUsername) || localStorage.getItem("username");

  const appRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const appActionRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const [api, contextHolder] = notification.useNotification({
    placement: "top",
  });
  const openNotification = useCallback(
    (status: StatusNotification, message: string) => {
      api[status]({
        message,
        description: "",
      });
    },
    [],
  );

  useEffect(() => {
    if (isAuth) {
      appRef.current = setInterval(fetchVerifyToken, 15 * 1000);
      handleInteraction();
    } else {
      clearInterval(appRef.current);
    }
  }, [isAuth]);

  useEffect(() => {
    checkUserLogin();
  }, [username]);

  useEffect(() => {
    dispatch(getAnnouncement({}));
  }, []);

  const checkUserLogin = async () => {
    if (!username) return;

    const isSuccess = await fetchVerifyToken();

    if (!isSuccess) return;

    dispatch(getProfile(username));
    dispatch(getMemberBalance({ username }));
  };

  const fetchVerifyToken = async () => {
    if (!username) return false;
    if (!token) return false;

    try {
      const response = await AuthApi.verifyToken(username, {
        username: username,
        token: token,
      });

      if (response.status !== ResponseCode.SUCCESS) {
        onLogout();
        return false;
      }
      return true;
    } catch (error) {
      return false;
    }
  };
  const handleInteraction = () => {
    if (appActionRef.current) {
      clearTimeout(appActionRef.current);
    }
    if (!isAuth) return;
    appActionRef.current = setTimeout(
      () => {
        onLogout();
      },
      1000 * 60 * 10,
    );
  };
  return (
    <div
      onMouseEnter={() => handleInteraction()}
      onKeyDown={() => handleInteraction()}
    >
      <ThemeProvider theme={theme}>
        <HelmetCustom title={"JMC88"} />
        <GlobalStyles />
        {/* {width > DeviceScreenWidth.MOBILE && <SocialBox />} */}
        <Outlet context={{ openNotification } as outletContextType} />
        {contextHolder}
      </ThemeProvider>
    </div>
  );
}

export default App;
