import {
  ImageContactFbColor,
  ImageContactGmailColor,
  ImageContactLiveChatColor,
  ImageContactMessageColor,
} from "assets/common/images/social";
import {
  ImageSupportAgency,
  ImageSupportChannel,
  ImageSupportComplain,
  ImageSupportCustomerCare,
  ImageSupportFb,
  ImageSupportPhone,
} from "assets/desktop/images/support";

import { PATH } from "./path";

export const listSocial = [
  {
    id: "cskh",
    title: "24/7 care",
    content: "Touch to chat",
    picture: ImageSupportCustomerCare,
    url: PATH.ContactUs,
  },
  {
    id: "fb",
    title: "Facebook",
    content: "24/7 Service",
    picture: ImageSupportFb,
    url: "",
  },
  {
    id: "agency",
    title: "Telegram Agent",
    content: "24/7 Service",
    picture: ImageSupportAgency,
    url: "",
  },
  {
    id: "complain",
    title: "Telegram Complaints",
    content: "24/7 Service",
    picture: ImageSupportComplain,
    url: "",
  },
  {
    id: "phone",
    title: "+84 868888789",
    content: "24/7 Service",
    picture: ImageSupportPhone,
    url: "",
  },
  {
    id: "channel",
    title: "TELEGRAM CHANNEL",
    content: "24/7 Service",
    picture: ImageSupportChannel,
    url: "",
  },
];

export const LIST_CONTACT_US = [
  {
    image: ImageContactFbColor,
    key: 1,
  },
  {
    image: ImageContactMessageColor,
    key: 2,
  },
  {
    image: ImageContactGmailColor,
    key: 3,
  },
  {
    image: ImageContactLiveChatColor,
    key: 4,
  },
];
