import { css } from "@emotion/react";
import ImageMenuActive from "assets/common/images/menu-active.png";
import { theme } from "styles/theme";
export const BottomTabStyle = {
  index: css`
    height: 60px;
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0px;
    border-top: 1px solid ${theme.colors.main};
    background: linear-gradient(180deg, #353535 0%, #111 100%);
    box-shadow: 0px -2px 15px 0px rgba(0, 0, 0, 0.12);
    padding: 2px 0;
    margin: 0;
  `,
  lisMenu: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  itemTab: (isActive: boolean) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    position: relative;
    .icon {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        fill: ${isActive ? theme.colors.main : "#aaa"};
        width: 30px;
        height: 30px;
      }
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 58px;
      width: 20vw;
      height: 20%;
      background: url(${isActive ? `${ImageMenuActive}` : ""}) no-repeat center;
      background-size: contain;
    }

    .label {
      color: ${isActive ? theme.colors.main : "#aaa"};
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }
  `,
};
