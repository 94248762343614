/** @jsxImportSource @emotion/react */

import { FooterProviderBanner, Logo } from "assets/desktop/images";
import { ImageLicense } from "assets/desktop/images/homepage";
import ReadMore from "components/common/ReadMore";
import { ListMenuInfo } from "contants/list-info";
import { LIST_CONTACT_US } from "contants/listSocial";
import { Link } from "react-router-dom";

import { FooterStyles } from "./index.style";

export const Footer = () => {
  return (
    <div css={FooterStyles.index}>
      <div css={FooterStyles.content}>
        <div css={FooterStyles.box1}>
          <div className="left">
            <img src={Logo} alt="" />
            <div className="note">
              <ReadMore
                maxCharCount={190}
                text={`JMC88 is a bookmaker with a legal online betting 
              license issued through the Isle of Man & Cagayan Economic Zone and Free Port. This is an international 
              development organization with a scale spanning across the Asia-Pacific region. With experience and high
              reputation having served more than 10 million players, JMC88 has been affirming its 
              position and brand in the online gaming market. As a bookmaker under the OKVIP group, headquartered in Manila, 
              Philippines - the group is considered the largest in Asia in the online betting market with a huge staff. 
              With proven high reliability, JMC88 is currently one of the bookmakers that is clearly authenticated
                and has a license certified by the international online betting association. 
                JMC88's main concern is the security of personal information development.
                JMC88 guarantees not to disclose customer personal information to any third parties
                  because it uses data encryption with the highest standards. 
                  All personal information is transmitted via Secure Socket (SS 128 bit encryption Standard) and
                  securely in secure management environments that are not accessible to the public network area.
                    All internal and external data is limited, closely monitored and strictly managed,
                  bringing a feeling of absolute safety and security to players.`}
              />
            </div>
          </div>
          <div className="right">
            <p>Information</p>
            <div className="list-info">
              {ListMenuInfo.map((item, index) => {
                return (
                  <Link key={index} to={item.link} className="item-info">
                    <span>{item.label}</span>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>

        <div css={FooterStyles.box3}>
          <div className="image1">
            <p>PLAY RESPONSIBLY</p>
            <img src={ImageLicense} alt="" />
          </div>
          <div className="image2">
            <p>Contact Us</p>
            <div css={FooterStyles.contactUs}>
              {LIST_CONTACT_US.map((item) => (
                <div css={FooterStyles.itemContactUs} key={item.key}>
                  <img src={item.image} alt="" />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div css={FooterStyles.box2}>
          <div className="image">
            <p>Supplier</p>
            {/* <img src={ImageSupplier} alt="" /> */}
            {/* <div css={FooterStyles.girdImg}>
              {listGameFooter.map((itemMap, index) => {
                return <img alt="" src={itemMap} key={index} />;
              })}
            </div> */}
            <img src={FooterProviderBanner} alt="" />
          </div>
        </div>

        <div css={FooterStyles.copyright}>
          Copyright © JMC88, the leading reputable bookmaker in the online
          gaming market online, fast deposit and withdrawal, great incentives,
          is the ideal place for users to participate entertainment, quick
          registration and login, in addition to many game products Attractive,
          please join quickly to experience good service Reserved
        </div>
      </div>
    </div>
  );
};
