/** @jsxImportSource @emotion/react */

import { HPPlayIcon } from "assets/desktop/images/homepage";
import { ModalTypeValue } from "enums/modalType";
import { useAppDispatch } from "hooks/app-hook";
import { useNavigate } from "react-router-dom";
import { setOpenModal } from "store/master";

import { CardGameStyle } from "./style.index";
interface Props {
  picture: string;
  iconGame: string;
  icon: string;
  provider: string;
}

const CardGame: React.FC<Props> = ({
  picture = "",
  iconGame = "",
  icon = "",
  provider = "",
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onClick = () => {
    if (!provider.length) {
      dispatch(setOpenModal(ModalTypeValue.NOTIGAME));
      return;
    }

    navigate(`/slots/${provider}`);
  };

  return (
    <div css={CardGameStyle.wrapper} onClick={onClick}>
      <div className="overlay">
        <div css={CardGameStyle.triangleStyle} className="background-yellow" />
        <div css={CardGameStyle.contentImg}>
          <img src={icon} alt="" />
          <img src={HPPlayIcon} alt="" css={CardGameStyle.icon} />
        </div>
      </div>
      <div className="iconGame">
        <img src={iconGame} alt="" />
      </div>

      <img src={picture} alt="" />
    </div>
  );
};

export default CardGame;
