import { NavigateFunction } from "react-router-dom";
import { ThunkActionDispatch } from "redux-thunk";
import { resetAuth } from "store/auth";
import { resetBank } from "store/bank";
import { resetBankingDetail, resetDeposit } from "store/deposit";
import { resetDepositOrders, resetWithdrawalOrders } from "store/orders";
import { resetUser } from "store/user";
import { resetWithdrawal } from "store/withdraw";

export const handleResetData = (
  dispatch: ThunkActionDispatch<any>,
  navigate: NavigateFunction,
) => {
  dispatch(resetAuth());
  dispatch(resetUser());
  dispatch(resetDeposit());
  dispatch(resetWithdrawalOrders());
  dispatch(resetDepositOrders());
  dispatch(resetBank());
  dispatch(resetBankingDetail());
  dispatch(resetWithdrawal());
  localStorage.clear();
  navigate("/");
};
