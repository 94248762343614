import { css } from "@emotion/react";
import { theme } from "styles/theme";

interface Props {
  backgroundImage?: string;
  isActive: boolean;
}

export const CategoryGameSwiperStyle = {
  categoryGame: {
    index: css`
      position: sticky;
      top: 0;
      z-index: 9;
      /* background: linear-gradient(
        180deg,
        rgba(114, 114, 114, 1) 0%,
        rgba(61, 61, 61, 1) 100%
      ); */
      background: #000;

      .swiper {
        &-button {
          &-next {
            color: #7a7a7a !important;

            pointer-events: unset !important;
            right: -4px !important;
            top: 16px !important;
            &::after {
              font-size: 13px;
            }
          }
          &-prev {
            color: #7a7a7a !important;

            pointer-events: unset !important;
            left: -4px !important;
            top: 16px !important;
            &::after {
              font-size: 13px;
            }
          }
        }
      }
    `,
    item: ({ isActive }: Props) => css`
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      padding: 10px 0;

      span {
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        text-transform: uppercase;

        margin-top: 9px;

        color: ${isActive ? `${theme.colors.main}` : `${theme.colors.white}`};
        width: max-content;
      }
    `,
    icon: ({ isActive, backgroundImage }: Props) => css`
      width: 30px;
      height: 30px;

      background-image: url(${backgroundImage});
      background-position: ${isActive ? "center bottom" : "center top"};
      background-repeat: no-repeat;
      background-size: cover;
      font-size: 30px;
    `,
  },
};
