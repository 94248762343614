import { css } from "@emotion/react";
import { FooterImageBgRegister } from "assets/mobile/images/footer";
import { theme } from "styles/theme";

export const ModalSignUpStyle = {
  modalStyle: css`
    position: fixed;
    width: 100% !important;
    height: 100%;
    left: 0;
    top: 0;
    max-width: 100% !important;

    .ant-modal-content {
      width: 100%;
      height: 100%;
      background-color: transparent;
      padding: 0 !important;

      .ant-modal-body {
        width: 100%;
        height: 100%;
      }
    }
  `,
  closeButton: css`
    position: absolute;
    z-index: 9;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 8px;
    top: 8px;
    cursor: pointer;
    svg {
      fill: ${theme.colors.main};
    }
  `,
  content: css`
    background-image: url(${FooterImageBgRegister});
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  `,
  formContainer: css`
    overflow: auto;
    top: 35vw;
    height: calc(100vh - 35vw);
    padding: 12px 0px;
  `,
  form: css`
    padding: 0 20px;

    .input-col {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-bottom: 12px;

      label {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 4px;
        svg {
          fill: ${theme.colors.main};
        }
        span {
          margin-left: 4px;
          color: ${theme.colors.main};
          font-size: 17px;
          font-weight: 400;
          line-height: 20px;
        }
      }

      .note {
        color: ${theme.colors.neutrals[300]};
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }

      .ant-input-suffix {
        padding-right: 16px;
      }

      input {
        padding-left: 16px;
      }
    }
    .input-pass {
      input {
        padding-left: 16px !important;
      }
    }
  `,
  approve: css`
    display: flex;
    flex-direction: row;
    align-items: flex-start !important;
    justify-content: flex-start;
    font-weight: 700;
    color: ${theme.colors.neutrals[100]};

    .terms-conditions {
      color: ${theme.colors.main};
      text-decoration: underline;
    }
  `,
  recaptcha: css`
    margin: 12px 0;
    text-align: center;
    font-style: italic;
    color: #f00;
  `,
  groupButton: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;

    .tButton {
      height: auto;
      border-radius: 37px;

      span {
        color: #fff;
        text-align: center;
        font-size: 17px;
        font-weight: 400;
        line-height: 46px;
      }
    }
    .btn-login {
      width: 134px;
      padding: 0px 18.14px 0px 18.86px;
      background: #404040;
    }
    .btn-register {
      width: 185px;
      padding: 0px 57.05px 0px 55.95px;
      border-radius: 37px;
      background: ${theme.colors.main};

      &:disabled {
        opacity: 0.7;
      }
    }
  `,

  blockCaptcha: (background: string) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    height: 42px;
    margin-right: 10px;

    background-image: url(${background});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;

    span {
      font-size: 20px;
      opacity: 0.6;
      font-style: italic;
    }
  `,
};
