import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const DropdownGameStyle = {
  index: (isFocused: boolean) => css`
    position: absolute;
    width: 100%;
    background-color: #746148bf;
    z-index: 9;
    left: 0;
    min-width: 1200px;
    display: ${isFocused ? "flex" : "none"};
  `,
  listGame: (isCenter: boolean) => css`
    width: 1200px;
    margin: 0 auto;
    display: flex;
    padding: 10px 0;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: ${isCenter ? "center" : "flex-start"};
    gap: 14px;
  `,
  itemGame: css`
    width: calc((100% - (14px * 4)) / 5);
    height: 116px;
    border-bottom: 4px solid transparent;
    margin-bottom: 4px;
    overflow: hidden;

    cursor: pointer;
    transition: 0.25s;
    &:hover {
      border-color: ${theme.colors.primary[1000]};
      transition: 0.25s;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      border-top-left-radius: 16px;
    }
  `,
};
