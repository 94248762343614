import * as cockLotteryGame from "assets/common/images/game/mobile/lottery";
import { SubGameType } from "types/game";

export const dataLotteryMobile: SubGameType[] = [
  {
    game_code: "",
    picture: cockLotteryGame.mobileCockFightingGW,
    provider_code: "GW",
    type: "LT",
    name: "GW Xổ Số",
    featured: "",
    new_arrive: "",
  },
  {
    game_code: "",
    picture: cockLotteryGame.mobileCockFightingVR,
    provider_code: "VR",
    type: "LT",
    name: "VR Xổ Số",
    featured: "",
    new_arrive: "",
  },
  {
    game_code: "",
    picture: cockLotteryGame.mobileCockFightingTP,
    provider_code: "TP",
    type: "LT",
    name: "TP Xổ Số",
    featured: "",
    new_arrive: "",
  },
];
