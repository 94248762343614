/** @jsxImportSource @emotion/react */

import { Button } from "antd";
import { ReactComponent as DepositIcon } from "assets/common/icons/deposit.svg";
import { ReactComponent as RefreshIcon } from "assets/common/icons/refresh.svg";
import { ReactComponent as WithdrawIcon } from "assets/common/icons/withdraw.svg";
import { icons } from "assets/mobile";
import Container from "components/common/Container";
import { useModalConfirm } from "components/common/ModalConfirm";
import MESSAGE from "contants/message";
import { PATH } from "contants/path";
import { useAppDispatch } from "hooks/app-hook";
import { useAppLogout } from "hooks/useLogout";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectUsername } from "store/auth";
import { selectBalance, selectBalanceLoading } from "store/user";
import { getMemberBalance } from "store/user/user.thunk";

import { MSurplusStyle } from "./index.style";

const MSurplus = () => {
  const dispatch = useAppDispatch();
  const username = useSelector(selectUsername);
  const { total } = useSelector(selectBalance);
  const isLoading = useSelector(selectBalanceLoading);

  const { contextHolder, modalConfig } = useModalConfirm();
  const onLogout = useAppLogout();

  const refreshBalance = () => {
    if (isLoading) return;
    dispatch(getMemberBalance({ username }));
  };

  const handleLogout = () => {
    modalConfig({
      title: MESSAGE.logout.title,
      onSuccess: () => {
        onLogout();
      },
    });
  };

  useEffect(() => {
    if (total) {
      return;
    }
    dispatch(getMemberBalance({ username }));
  }, []);

  return (
    <div css={MSurplusStyle.self}>
      <div css={MSurplusStyle.cardUserInfo}>
        <Container>
          <div css={MSurplusStyle.userInfo}>
            <span>Welcome, {username}</span>
            <Button
              type="text"
              css={MSurplusStyle.signoutBtn}
              onClick={handleLogout}
            >
              Logout {<icons.SignOut css={MSurplusStyle.signOutIcon} />}
            </Button>
          </div>
        </Container>
      </div>
      <Container>
        <div css={MSurplusStyle.paymentCard}>
          <div css={MSurplusStyle.balanceInfo}>
            <span css={MSurplusStyle.balanceValue}>{total}</span>
            <div css={MSurplusStyle.currency}>
              <span>PHP</span>
              <RefreshIcon
                css={MSurplusStyle.refresh}
                onClick={refreshBalance}
                data-loading={isLoading && "loading"}
              />
            </div>
          </div>
          <div css={MSurplusStyle.paymentGroupBtn}>
            <Button
              type="primary"
              css={MSurplusStyle.paymentBtn}
              icon={<DepositIcon style={{ width: "24px" }} />}
              href={PATH.User.Financial}
            >
              Deposit
            </Button>
            <Button
              type="primary"
              css={MSurplusStyle.paymentBtn}
              icon={<WithdrawIcon style={{ width: "24px" }} />}
              href="/user/financial?open=withdraw"
            >
              Withdraw
            </Button>
          </div>
        </div>
      </Container>
      {contextHolder}
    </div>
  );
};

export default MSurplus;
