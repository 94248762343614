/** @jsxImportSource @emotion/react */

import Container from "components/common/Container";
import _map from "lodash/map";
import { Link } from "react-router-dom";

import { MemberMenuStyle } from "./index.style";
import { MEMBER_MENU_MOBILE } from "./menu-list";

const menuItems = _map(MEMBER_MENU_MOBILE, (item) => {
  return {
    ...item,
    icon: <item.icon />,
  };
});

const MemberMenu = () => {
  return (
    <Container>
      <ul css={MemberMenuStyle.self}>
        {_map(menuItems, (item, index) => (
          <li css={MemberMenuStyle.menuItem} key={index}>
            {
              <Link to={item.to || "#"}>
                {item.icon}
                <span css={MemberMenuStyle.menuTitle}>{item.label}</span>
              </Link>
            }
          </li>
        ))}
      </ul>
    </Container>
  );
};

export default MemberMenu;
