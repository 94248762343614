/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Button, Empty, Image } from "antd";
import { gameApi } from "api/game";
import { ImageError } from "assets/common/images";
import LoadingCustom from "components/common/LoadingCustom";
import { PaginationCustom } from "components/common/PaginationCustom";
import Search from "components/desktop/Search";
import SubGameImageLoading from "components/desktop/SubGameImageLoading";
import { sidebarSlot } from "contants/game/desktop/page/slots";
import { ResponseCode } from "contants/response";
import { GameTypeEnum } from "enums/game";
import handleLoginGame from "helpers/handleLoginGame";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useScrollTop } from "hooks/useScrollTop";
import _find from "lodash/find";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { selectUsername } from "store/auth";
import {
  resetCurrentPage,
  resetFilterSubGame,
  selectCurrentPage,
  selectDataSubGame,
  selectFilterSubGame,
  selectIsLoading,
  setCurrentPage,
  setDetailProviderSlot,
  setFilterSubGame,
} from "store/game";
import { getSubGameList } from "store/game/game.thunk";
import { DetailProviderSlotType, SubGameType } from "types/game";

import { SubSlotsGameStyle } from "./style.index";

interface Props {
  detailProvider: DetailProviderSlotType;
}

export const RenderGameSlots = (props: Props) => {
  const { detailProvider } = props;

  const dispatch = useAppDispatch();
  const { provider } = useParams();

  useScrollTop({ dependencyList: [provider] });

  const dataSubGame = useAppSelector(selectDataSubGame);
  const currentPageGame = useAppSelector(selectCurrentPage);
  const filterSubGame = useAppSelector(selectFilterSubGame);
  const isLoading = useAppSelector(selectIsLoading);
  const username = useAppSelector(selectUsername);

  // const [currentPage, setCurrentPage] = useState<number>(1);
  const [isImageLoaded, setImageLoaded] = useState(false);
  const [dataGame, setDataGame] = useState<Array<SubGameType>>([]);

  const handleImageLoaded = () => {
    setImageLoaded(true);
  };

  const getDataGame = async () => {
    const res = await gameApi.getSubGameList({
      provider: provider,
      game_type: GameTypeEnum.Slot,
      page: 1,
      size: 500,
    });

    if (res.data.status === ResponseCode.SUCCESS) {
      setDataGame(res.data.data);
      return;
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetCurrentPage());
    };
  }, []);

  useEffect(() => {
    dispatch(resetFilterSubGame());

    if (!provider) return;

    dispatch(
      getSubGameList({
        provider: provider,
        game_type: GameTypeEnum.Slot,
        page: currentPageGame,
        size: 15,
      }),
    );

    getDataGame();
  }, [provider, currentPageGame]);

  const handlePage = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  const renderListSubGame = () => {
    if (isLoading) return <LoadingCustom width={"100%"} center={true} />;

    const findGameByName = dataGame.filter((itemFilter) =>
      itemFilter.name.toLowerCase().includes(filterSubGame.toLowerCase()),
    );

    if (dataSubGame.data.length === 0 || findGameByName.length === 0)
      return (
        <Empty
          css={css`
            height: 500px !important;
            justify-content: center !important;
            display: flex !important;
            flex-direction: column !important;
          `}
        />
      );

    const dataSubGameList =
      filterSubGame.length > 1 ? findGameByName : dataSubGame.data;

    return (
      <div css={SubSlotsGameStyle.listSubGame}>
        {dataSubGameList.map((subGame: SubGameType) => (
          <div
            css={SubSlotsGameStyle.cardGame(detailProvider)}
            key={subGame.game_code}
          >
            <div css={SubSlotsGameStyle.cardInner}>
              {!isImageLoaded && <SubGameImageLoading />}

              <div css={SubSlotsGameStyle.cardWrap}>
                <Image
                  style={{
                    display: isImageLoaded ? "block" : "none",
                    aspectRatio: 1,
                  }}
                  src={subGame.picture}
                  preview={false}
                  onLoad={handleImageLoaded}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = ImageError;
                  }}
                />

                <div className="overlay">
                  <Button
                    type="primary"
                    onClick={() => handleLoginGame(subGame, username, dispatch)}
                  >
                    Play
                  </Button>
                </div>
              </div>
              <div css={SubSlotsGameStyle.gameName}>
                <p>{subGame.name}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    if (!provider) return;

    const findDataDetailProvider = _find(
      sidebarSlot,
      (item: DetailProviderSlotType) => item.provider_code === provider,
    );

    if (!findDataDetailProvider) return;

    dispatch(setDetailProviderSlot(findDataDetailProvider));
  }, [provider]);

  return (
    <div css={SubSlotsGameStyle.wrapper}>
      <div css={SubSlotsGameStyle.header}>
        <div css={SubSlotsGameStyle.titleSearchWrap}>
          <Image
            src={detailProvider.pictureTitle}
            alt=""
            width={337}
            height={54}
            preview={false}
          />

          <Search
            onChange={(e) => {
              dispatch(setFilterSubGame(e.target.value));
            }}
            color={detailProvider.backgroundColor}
          />
        </div>
      </div>

      <div css={SubSlotsGameStyle.filter(detailProvider)}>
        <div css={SubSlotsGameStyle.listFilter}>
          <div css={SubSlotsGameStyle.itemFilter(detailProvider)}>
            All games
          </div>
        </div>
      </div>

      <div css={SubSlotsGameStyle.listSubGameWrap}>{renderListSubGame()}</div>

      {dataSubGame.data.length > 0 && filterSubGame.length === 0 && (
        <div css={SubSlotsGameStyle.paginate}>
          <PaginationCustom
            currentPage={currentPageGame}
            handleCurrentPage={handlePage}
            totalPage={dataSubGame.totalPages}
          />
        </div>
      )}
    </div>
  );
};
