import {
  CategoryGameType,
  LIST_GAME_CATEGORY_DETAILS,
} from "contants/menu-game";
import { useAppSelector } from "hooks/app-hook";
import _find from "lodash/find";
import { useMemo } from "react";
import { selectCategoryDetails } from "store/game";
import { CategoryDetailsType } from "types/game";

const initialValue: CategoryGameType = {
  id: "",
  name: "",
  path: "",
};

export const useCategoryGameDetail = (_idCategory: string) => {
  return useMemo(() => {
    if (!_idCategory) return initialValue;

    const findCategory = _find(
      LIST_GAME_CATEGORY_DETAILS,
      (category: CategoryGameType) =>
        category.id.includes(_idCategory) ||
        category.path.includes(_idCategory),
    );

    if (!findCategory) {
      return initialValue;
    }

    return findCategory;
  }, [_idCategory]);
};

export const useProviderDetail = (providerId: string) => {
  const dataCategoryDetails = useAppSelector(selectCategoryDetails);

  return useMemo(() => {
    const findCategory = _find(
      dataCategoryDetails,
      (category: CategoryDetailsType) => category.provider_code === providerId,
    );
    if (!findCategory) {
      return null;
    }
    return findCategory;
  }, [providerId, dataCategoryDetails]);
};
