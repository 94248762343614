import * as boardGameMobile from "assets/common/images/game/mobile/board-game";
import { SubGameType } from "types/game";
export const dataCrashMobile: SubGameType[] = [
  {
    game_code: "",
    picture: boardGameMobile.mobileBoardGameT1,
    provider_code: "T1G",
    type: "BG",
    name: "T1G Game",
    featured: "",
    new_arrive: "",
  },
];
