import { css, Theme } from "@emotion/react";
import { theme } from "styles/theme";

export const MenuPaymentStyle = {
  self: css`
    display: flex;
    height: 100px;
    gap: 2px;
    margin: 2px 0;
  `,

  menuItem: ({ colors }: Theme) => css`
    flex: 1;
    height: 100%;
    background-color: ${theme.colors.gray[350]};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-decoration: none;
    color: ${colors.white};
    gap: 10px;
    font-size: 18px;
    font-weight: 400;
    &.active {
      background-color: ${theme.colors.main};
    }
  `,
};
