/** @jsxImportSource @emotion/react */

import { Drawer } from "antd";
import { icons } from "assets/mobile";
import { ReactComponent as BackIcon } from "assets/mobile/icons/reply-solid.svg";
import Container from "components/common/Container";
import { CSSProperties } from "react";

import { MDrawerStyle } from "./index.style";

interface MDrawerProps {
  isOpen?: boolean;
  onClose?: () => void;
  title?: string;
  children?: React.ReactNode;
  bodyStyle?: CSSProperties;
  isBack?: boolean;
  onBack?: () => void;
  isFull?: boolean;
}

const MDrawer = (props: MDrawerProps) => {
  const {
    isOpen,
    onClose,
    title,
    children,
    bodyStyle,
    isBack,
    onBack,
    isFull = false,
  } = props;

  const handleClose = () => {
    onClose && onClose();
  };

  const handleBack = () => {
    onBack && onBack();
  };

  return (
    <div css={MDrawerStyle.self}>
      <Drawer
        title={
          <Container>
            <div css={MDrawerStyle.headerWrapper(isFull)}>
              <div css={MDrawerStyle.header}>
                {isBack && (
                  <BackIcon css={MDrawerStyle.backIcon} onClick={handleBack} />
                )}
                <h1 css={MDrawerStyle.title}>{title}</h1>
                {<icons.closeDrawIcon onClick={handleClose} />}
              </div>
            </div>
          </Container>
        }
        headerStyle={{ padding: "0", backgroundColor: "#000" }}
        placement="bottom"
        closable={false}
        onClose={handleClose}
        open={isOpen}
        height={isFull ? "100vh" : "calc(100vh - 58px)"}
        bodyStyle={
          bodyStyle || {
            backgroundColor: "#000",
            color: "white",
            padding: "16px",
          }
        }
      >
        {children}
      </Drawer>
    </div>
  );
};

export default MDrawer;
