import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const ModalForgetPasswordStyle = {
  modalStyle: css`
    width: 620px !important;

    .ant-modal-content {
      padding: 0 !important;
      background-color: transparent;
    }

    .bgAuthForm {
      width: 252px;
      height: 600px;
    }
  `,
  formContainer: css`
    padding: 16px 30px;
  `,
  formGroup: css`
    margin-top: 50px;
  `,
  formInput: css`
    margin-bottom: 12px;
    label {
      span {
        color: #333;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        margin-left: 2px;
      }
    }
    .note {
      color: #333;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  `,
  valuePass: css`
    color: ${theme.colors.primary[1000]};
    font-weight: 500;
  `,
  buttons: css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    button {
      display: flex;
      width: 172px;
      height: 50px;
      padding: 13.72px 24.65px 14.28px 24.85px;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background: linear-gradient(90deg, #f7ba22 0%, #f49a25 100%);

      span {
        color: #fff;
      }
    }
  `,
  loginRequest: css`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.857px;
    margin-top: 16px;

    span {
      color: #feb000;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  `,
  recaptcha: css`
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  `,
};
