import { css } from "@emotion/react";
import { media } from "styles/breakpoints";

export const ContainerStyle = {
  self: css`
    max-width: 1200px;
    margin: 0 auto;
    /* padding: 0 20px; */
    /* background-color: beige; */
    ${media.sm} {
      max-width: 100%;
      width: 100%;
      padding: 0 16px;
    }
  `,
};
