import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";

import { GetPromotionListResponse } from "./type";

export const retriveApi = {
  async getPromotionList() {
    const { data } = await axiosRequest.post<GetPromotionListResponse>(
      "/",
      payloadHelper("PromotionList", null, null),
    );
    return data;
  },
};
