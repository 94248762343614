/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form, Image, message, Modal } from "antd";
import { RegisterFormType } from "api/auth/type";
import { IconEyeSlashSolid, IconEyeSolid } from "assets/common/icons";
import { ReactComponent as StarIcon } from "assets/common/icons/star-solid.svg";
import {
  ModalLogoVillarreal,
  ModalLogoVillarrealOk,
} from "assets/common/images/modal";
import { Logo } from "assets/desktop/images";
import InputCustomAuth from "components/common/InputCustomAuth";
import CheckboxCustom from "components/desktop/base/CheckboxCustom";
import InputPassword from "components/desktop/base/InputPassword";
import { LIST_BACKGROUND_CAPTCHA } from "contants/captcha";
import {
  REGEX_EMOJI,
  REGEX_FULLNAME_SIGNUP,
  REGEX_PASSWORD,
  REGEX_USERNAME,
} from "contants/regex";
import { ResponseCode } from "contants/response";
import { ModalType, ModalTypeValue } from "enums/modalType";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { registerMemberDetail } from "store/auth/auth.thunk";
import { selectOpenModal, setOpenModal, setOpenModalNoti } from "store/master";
import { theme } from "styles/theme";
import { ApiResponse } from "types/api";
import * as yup from "yup";

import ModalNoti from "../ModalNoti";
import { ModalSignupStyle } from "./index.style";
import ModalPrivacy from "./ModalPrivacy";

interface RegisterTypeForm extends RegisterFormType {
  approve: boolean;
}

const schema = yup
  .object({
    mobile: yup
      .string()
      .trim()
      .required("Please enter your mobile phone number"),
    fullname: yup
      .string()
      .trim()
      .required("Please enter your real name")
      .matches(
        REGEX_FULLNAME_SIGNUP,
        "Start with a letter, no special characters",
      )
      .required("Please enter your real name"),
    username: yup
      .string()
      .trim()
      .lowercase()
      .required("This is a required field")
      .test("emoji", "Do not enter emojis", (username: string): boolean => {
        if (!username) return false;
        const regex = new RegExp(REGEX_EMOJI);
        return !regex.test(username);
      })
      .matches(
        REGEX_USERNAME,
        ` Minimum 6 characters, start with a letter, no special characters.`,
      )
      .required("Please enter your account name"),
    password: yup
      .string()
      .trim()
      .matches(
        REGEX_PASSWORD,
        "More than 6 characters must include letters and numbers",
      )
      .required("Please enter a password"),
    confirm_password: yup
      .string()
      .trim()
      .matches(
        REGEX_PASSWORD,
        "More than 6 characters must include letters and numbers",
      )
      .required("Please confirm your password again")
      .test(
        "empty",
        "Confirm passwords do not match",
        (
          confirm_password: string | undefined,
          testContext: yup.TestContext<yup.AnyObject>,
        ): boolean => {
          if (!testContext.parent.password) return true;
          return testContext.parent.password === confirm_password;
        },
      ),
    email: yup
      .string()
      .email("Invalid email")
      .trim()
      .required("Please enter Email address"),
    approve: yup
      .boolean()
      .required()
      .oneOf([true], "You must be 18 years old to use this website."),
    captcha: yup
      .string()
      .trim()
      .required("You must be 18 years old to use this website."),
  })
  .required();

interface State {
  valueCaptcha: string;
  numberBg: number;
  title: string;
}

export const ModalSignUp = () => {
  const dispatch = useAppDispatch();

  const openModal = useAppSelector(selectOpenModal);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    getValues,
  } = useForm<RegisterTypeForm>({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const [state, setState] = useState<State>({
    valueCaptcha: "",
    numberBg: 0,
    title: "",
  });
  const [valueMobile, setValueMobile] = useState<string>("");

  const [isOpenModalDetail, setIsOpenModalDetail] = useState<boolean>(false);

  const onSubmit = async (data: RegisterTypeForm) => {
    data.mobile = valueMobile;

    const { captcha, ...params } = data;

    if (state.valueCaptcha !== captcha) {
      setState((prev) => ({
        ...prev,
        title: "Verification code error or input error, please try again",
      }));
      return;
    }

    setState((prev) => ({
      ...prev,
      title: "",
    }));

    const res = await dispatch(registerMemberDetail(params));

    const resData = res.payload as ApiResponse;
    if (resData.status !== ResponseCode.SUCCESS) {
      message.error({
        key: "register",
        content: resData.msg,
      });
      return;
    }

    message.success({
      key: "register",
      content: resData.msg,
    });
    reset();
    dispatch(setOpenModal(ModalTypeValue.CLOSE));
  };

  const generateRandomNumbers = () => {
    const newRandomNumbers: number[] = [];

    while (newRandomNumbers.length < 4) {
      const randomNumber = Math.floor(Math.random() * 10);
      newRandomNumbers.push(randomNumber);
    }

    setState((prev) => ({
      ...prev,
      numberBg: Math.floor(Math.random() * 7),
      valueCaptcha: newRandomNumbers.join(""),
    }));
  };

  const renderTitle = () => {
    const form = getValues();

    if (!form?.captcha?.length) {
      setState((prev) => ({
        ...prev,
        title:
          "Confirmation code cannot be empty, please get the confirmation code",
      }));
      return;
    }

    if (errors.captcha || !form.captcha) {
      setState((prev) => ({
        ...prev,
        title: errors.captcha?.message as string,
      }));
      return;
    }
  };

  const resetValue = () => {
    reset();
    setValueMobile("");
  };

  return (
    <Modal
      className="modal__signup"
      open={openModal === ModalTypeValue.REGISTER}
      onCancel={() => {
        reset();
        dispatch(setOpenModal(ModalTypeValue.CLOSE));
      }}
      style={{
        height: "764px",
      }}
      css={ModalSignupStyle.modal}
      footer={<></>}
      centered
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            gap: 10px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          `}
        >
          <Image src={Logo} alt="" height={160} />
          <div
            css={css`
              display: flex;
              gap: 10px;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              padding: 0 20px;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                justify-content: center;
              `}
            >
              <p
                css={css`
                  font-size: 17px;
                  margin: 0 0 10px;
                  color: white;
                `}
              >
                Official partner
              </p>
              <p
                css={css`
                  color: white;
                  font-size: 16px;
                  font-weight: 300;
                `}
              >
                Villarreal CF & OKVIP
              </p>
            </div>

            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <Image
                src={ModalLogoVillarreal}
                alt=""
                height={63}
                width={"100%"}
                preview={false}
              />
              <div
                css={css`
                  border-right: 1px solid white;
                  opacity: 0.4;
                  padding-right: 18px;
                  height: 63px;
                  content: " ";
                `}
              ></div>
              <div
                css={css`
                  padding-left: 18px;
                  max-height: 100px;
                `}
              >
                <Image
                  src={ModalLogoVillarrealOk}
                  alt=""
                  height={63}
                  width={175}
                  preview={false}
                />
              </div>
            </div>
          </div>
        </div>

        <Form onSubmitCapture={handleSubmit(onSubmit)}>
          <div
            css={css`
              margin-top: 20px;
            `}
          >
            <div
              css={css`
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                column-gap: 30px;
                row-gap: 15px;
                padding: 0 15px;

                height: 400px;
                overflow-y: scroll;
                &::-webkit-scrollbar {
                  display: none;
                }
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  gap: 2px;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 4px;
                  `}
                >
                  <StarIcon height={15} width={15} fill="#ff0000" />
                  <p
                    css={css`
                      font-weight: 700;
                      color: ${theme.colors.main};
                    `}
                  >
                    Account name
                  </p>
                </div>
                <div css={ModalSignupStyle.blockInput}>
                  <InputCustomAuth
                    // placeholder={
                    //   "Lớn hơn 5 ký tự,bằng chữ,không thêm các ký tự đặc biệt."
                    // }
                    name={"username"}
                    control={control}
                    margin_bottom="12px"
                    id={"username"}
                    errors={errors.username?.message}
                    validate_status={errors.username ? "error" : "success"}
                    // isPrefix
                    prefix={<></>}
                  />
                </div>
                <p
                  css={css`
                    color: white;
                  `}
                >
                  Greater than 5 characters, in words, no special characters
                  added.
                </p>
              </div>

              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  gap: 2px;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 2px;
                  `}
                >
                  <StarIcon height={15} width={15} fill="#ff0000" />
                  <p
                    css={css`
                      font-weight: 700;
                      color: ${theme.colors.main};
                    `}
                  >
                    Member password
                  </p>
                </div>
                <div css={ModalSignupStyle.blockInput}>
                  <InputPassword
                    // placeholder={"Hơn 6 ký tự phải bao gồm chữ cái và số"}
                    type="password"
                    name={"password"}
                    control={control}
                    margin_bottom="12px"
                    id={"password"}
                    error={errors.password?.message}
                    validate_status={errors.password ? "error" : "success"}
                    iconRender={(visible) =>
                      visible ? (
                        <IconEyeSolid width={20.25} height={18} fill="white" />
                      ) : (
                        <IconEyeSlashSolid
                          width={20.25}
                          height={18}
                          fill="white"
                        />
                      )
                    }
                  />
                </div>
                <p
                  css={css`
                    color: white;
                  `}
                >
                  More than 6 characters must include letters and numbers.
                </p>
              </div>

              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  gap: 2px;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 2px;
                  `}
                >
                  <StarIcon height={15} width={15} fill="#ff0000" />
                  <p
                    css={css`
                      font-weight: 700;
                      color: ${theme.colors.main};
                    `}
                  >
                    Confirm password
                  </p>
                </div>
                <div css={ModalSignupStyle.blockInput}>
                  <InputPassword
                    // placeholder={"Vui lòng xác nhận lại mật khẩu của bạn"}
                    type="password"
                    name={"confirm_password"}
                    control={control}
                    margin_bottom="12px"
                    id={"confirm_password"}
                    error={errors.confirm_password?.message}
                    validate_status={
                      errors.confirm_password ? "error" : "success"
                    }
                    iconRender={(visible) =>
                      visible ? (
                        <IconEyeSolid width={20.25} height={18} fill="white" />
                      ) : (
                        <IconEyeSlashSolid
                          width={20.25}
                          height={18}
                          fill="white"
                        />
                      )
                    }
                  />
                </div>
                <p
                  css={css`
                    height: 27px;
                    color: white;
                  `}
                >
                  Please Confirm Your Password
                </p>
              </div>

              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  gap: 2px;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 4px;
                  `}
                >
                  <StarIcon height={15} width={15} fill="#ff0000" />
                  <p
                    css={css`
                      font-weight: 700;
                      color: ${theme.colors.main};
                    `}
                  >
                    First and last name
                  </p>
                </div>
                <div css={ModalSignupStyle.blockInput}>
                  <InputCustomAuth
                    // placeholder={"Viết họ tên đầy đủ, chữ in hoa không dấu"}
                    name={"fullname"}
                    control={control}
                    margin_bottom="12px"
                    id={"fullname"}
                    errors={errors.fullname?.message}
                    validate_status={errors.fullname ? "error" : "success"}
                    prefix={<></>}
                  />
                </div>
                <p
                  css={css`
                    color: white;
                  `}
                >
                  Write Full Name, Capital Letters Without Accents
                </p>
              </div>

              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  gap: 2px;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 4px;
                  `}
                >
                  <StarIcon height={15} width={15} fill="#ff0000" />
                  <p
                    css={css`
                      font-weight: 700;
                      color: ${theme.colors.main};
                    `}
                  >
                    Phone number
                  </p>
                </div>
                <div css={ModalSignupStyle.blockInput}>
                  <InputCustomAuth
                    // placeholder={"Vui lòng nhập số điện thoại"}
                    value={valueMobile}
                    name={"mobile"}
                    control={control}
                    margin_bottom="12px"
                    id={"mobile"}
                    errors={errors.mobile?.message}
                    validate_status={errors.mobile ? "error" : "success"}
                    prefix={<></>}
                    setValueProps={(value: string) => {
                      if (value.length > 10) return;
                      const regex = new RegExp(/^[0-9]+$/);

                      if (!regex.test(value)) {
                        if (value.length === 0 && value === "")
                          setValueMobile("");
                        return;
                      }

                      setValueMobile(value);
                    }}
                  />
                </div>
                <p
                  css={css`
                    color: white;
                  `}
                >
                  Enter phone number starting with 84, For example: 84868888789
                </p>
              </div>

              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  gap: 2px;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 4px;
                  `}
                >
                  <StarIcon height={15} width={15} fill="#ff0000" />
                  <p
                    css={css`
                      font-weight: 700;
                      color: ${theme.colors.main};
                    `}
                  >
                    Email address
                  </p>
                </div>
                <div css={ModalSignupStyle.blockInput}>
                  <InputCustomAuth
                    // placeholder={
                    //   "Nhập chính xác Email (Quên mật khẩu sẽ được gửi về Email)"
                    // }
                    name={"email"}
                    control={control}
                    margin_bottom="12px"
                    id={"email"}
                    errors={errors.email?.message}
                    validate_status={errors.email ? "error" : "success"}
                    prefix={<></>}
                  />
                </div>
                <p
                  css={css`
                    color: white;
                  `}
                >
                  Fill in correctly to retrieve your password if you forget it
                  (Required).
                </p>
              </div>

              <div>
                <div
                  css={css`
                    display: flex;
                    gap: 4px;
                    align-items: center;
                    margin-top: 20px;
                  `}
                >
                  <StarIcon height={15} width={15} fill="#ff0000" />
                  <p
                    css={css`
                      font-weight: 700;
                      color: ${theme.colors.main};
                    `}
                  >
                    Verification code
                  </p>
                </div>
                <div css={[ModalSignupStyle.blockInput]}>
                  <InputCustomAuth
                    onClick={generateRandomNumbers}
                    // placeholder={"Vui lòng nhập mã xác minh"}
                    name={"captcha"}
                    control={control}
                    margin_bottom="12px"
                    id={"captcha"}
                    prefix={<></>}
                    suffix={
                      state.valueCaptcha && (
                        <div
                          css={ModalSignupStyle.blockCaptcha(
                            LIST_BACKGROUND_CAPTCHA[state.numberBg],
                          )}
                          onClick={generateRandomNumbers}
                        >
                          <span>{state.valueCaptcha}</span>
                        </div>
                      )
                    }
                  />
                </div>
                <p
                  style={{
                    paddingLeft: 15,
                  }}
                  css={css`
                    color: white;
                  `}
                >
                  Please Enter Verification Code
                </p>
              </div>
            </div>

            <div
              css={css`
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 8px;
                flex-direction: column;
              `}
            >
              <CheckboxCustom
                name={"approve"}
                control={control}
                // errors={errors.approve?.message?.toString()}
                css={css`
                  padding: 0 15px;
                  display: flex;
                  align-items: center !important;
                  color: black;
                `}
                validate_status={errors.approve ? "error" : "success"}
              >
                <div
                  css={css`
                    display: flex;
                    gap: 12px;
                    align-items: center;
                    justify-content: center;
                  `}
                >
                  <p
                    css={css`
                      font-weight: 700;
                      color: ${theme.colors.main};
                    `}
                  >
                    Be 18 years old and agree to this website
                  </p>
                  <p
                    onClick={() => setIsOpenModalDetail(true)}
                    css={css`
                      color: #ff0000;
                      font-weight: 400;
                    `}
                  >
                    User registration agreement
                  </p>
                </div>
              </CheckboxCustom>
              <div
                css={css`
                  display: flex;
                  gap: 3px;
                `}
              >
                <Button
                  css={[ModalSignupStyle.button, ModalSignupStyle.linearColor]}
                  htmlType="submit"
                  onClick={() => {
                    setState((prev) => ({
                      ...prev,
                      title: "",
                    }));

                    renderTitle();

                    dispatch(setOpenModalNoti(ModalType.NOTI));
                  }}
                >
                  Register now
                </Button>
                <Button css={ModalSignupStyle.button} onClick={resetValue}>
                  Reset
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
      {!!state.title && <ModalNoti title={state.title} />}
      <ModalPrivacy
        onCancel={() => setIsOpenModalDetail(false)}
        isOpen={isOpenModalDetail}
      />
    </Modal>
  );
};

export default ModalSignUp;
