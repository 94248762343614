import * as slotsGame from "assets/common/images/game/mobile/slots";
import { SubGameType } from "types/game";

export const dataSLotsMobile: SubGameType[] = [
  {
    game_code: "",
    picture: slotsGame.mobileSlotsDGG,
    provider_code: "DGG",
    type: "SL",
    name: "DGG Game",
    featured: "",
    new_arrive: "",
  },
  {
    game_code: "",
    picture: slotsGame.mobileSlotsFTG,
    provider_code: "FTG",
    type: "SL",
    name: "FTG Game",
    featured: "",
    new_arrive: "",
  },
  {
    game_code: "",
    picture: slotsGame.mobileSlotsFC,
    provider_code: "FC",
    type: "SL",
    name: "FC Game",
    featured: "",
    new_arrive: "",
  },
  {
    game_code: "",
    picture: slotsGame.mobileSlotsRSG,
    provider_code: "RSG",
    type: "SL",
    name: "RSG Game",
    featured: "",
    new_arrive: "",
  },
  {
    game_code: "",
    picture: slotsGame.mobileSlotsCQ9,
    provider_code: "CQ9",
    type: "SL",
    name: "CQ9 Game",
    featured: "",
    new_arrive: "",
  },
  {
    game_code: "",
    picture: slotsGame.mobileSlotsPG,
    provider_code: "PGS2",
    type: "SL",
    name: "PG Game",
    featured: "",
    new_arrive: "",
  },

  {
    game_code: "",
    picture: slotsGame.mobileSlotsJILI,
    provider_code: "JILI",
    type: "SL",
    name: "JILI Game",
    featured: "",
    new_arrive: "",
  },

  {
    game_code: "",
    picture: slotsGame.mobileSlotsJDB,
    provider_code: "JDB",
    type: "SL",
    name: "JDB Game",
    featured: "",
    new_arrive: "",
  },
  {
    game_code: "",
    picture: slotsGame.mobileSlotsBNG,
    provider_code: "BNG",
    type: "SL",
    name: "BNG Game",
    featured: "",
    new_arrive: "",
  },
  {
    game_code: "",
    picture: slotsGame.mobileSlotsMG,
    provider_code: "MG",
    type: "SL",
    name: "MG Game",
    featured: "",
    new_arrive: "",
  },

  {
    game_code: "",
    picture: slotsGame.mobileSlotsPP,
    provider_code: "PP",
    type: "SL",
    name: "PP Game",
    featured: "",
    new_arrive: "",
  },
];
