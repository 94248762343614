import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationRootState } from "types";
import { BettingRecordStateType } from "types/bettingrecord";

import { extraReducers } from "./bettingrecord.extraReducers";

export const initialState: BettingRecordStateType = {
  data: [],
  total: 0,
  isLoading: false,
  page: 1,
  isShowSider: false,
};

const bettingRecordSlice = createSlice({
  name: "bettingrecord",
  initialState,
  reducers: {
    updateCurrentPageBettingRecord(
      state: BettingRecordStateType,
      { payload }: PayloadAction<number>,
    ) {
      state.page = payload;
    },
    updateIsShowSider(
      state: BettingRecordStateType,
      { payload }: PayloadAction<boolean>,
    ) {
      state.isShowSider = payload;
    },
    resetPageBettingRecord(state: BettingRecordStateType) {
      state.page = 1;
    },
    resetBettingRecord: () => initialState,
  },
  extraReducers,
});

export const selectDataBettingRecord = (state: ApplicationRootState) =>
  state.bettingRecord.data;

export const selectIsLoadingBettingRecord = (state: ApplicationRootState) =>
  state.bettingRecord.isLoading;

export const selectTotalBettingRecord = (state: ApplicationRootState) =>
  state.bettingRecord.total;

export const selectPageBettingRecord = (state: ApplicationRootState) =>
  state.bettingRecord.page;

export const selectIsShowSider = (state: ApplicationRootState) =>
  state.bettingRecord.isShowSider;

export const { updateCurrentPageBettingRecord, updateIsShowSider } =
  bettingRecordSlice.actions;

export const { resetBettingRecord, resetPageBettingRecord } =
  bettingRecordSlice.actions;

export default bettingRecordSlice.reducer;
