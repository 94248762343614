/** @jsxImportSource @emotion/react */

import { SearchOutlined } from "@ant-design/icons";
import { Empty, Input } from "antd";
import { gameApi } from "api/game";
import LoadingCustom from "components/common/LoadingCustom";
import { PaginationCustom } from "components/common/PaginationCustom";
import CategoryGameSwiper from "components/mobile/CategoryGameSwiper";
import { dataBoardGameMobile } from "contants/game/mobile/contents/board-game";
import { dataCrashMobile } from "contants/game/mobile/contents/crash";
import { dataSLotsMobile } from "contants/game/mobile/contents/slots";
import { LIST_GAME_FILTER_M } from "contants/game-filter";
import {
  CategoryGameType,
  LIST_GAME_CATEGORY_DETAILS,
} from "contants/menu-game";
import { ResponseCode } from "contants/response";
import { SubGameEnum } from "enums/game";
import handleLoginGame from "helpers/handleLoginGame";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useCategoryGameDetail } from "hooks/useCategoryDetail";
import _find from "lodash/find";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { selectUsername } from "store/auth";
import { selectDataSubGame, selectIsLoading } from "store/game";
import { getSubGameList } from "store/game/game.thunk";
import { SubGameType } from "types/game";
import { TypeSubGameProps } from "types/sub-game";

import { SubGameNewMobileStyle } from "./style.index";

const pageSize = 30;

const SubGameNewMobile = () => {
  const { categoryName = "", providerId = "" } = useParams();

  // useScrollTop({ dependencyList: [providerId] });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const dataSubGameList = useAppSelector(selectDataSubGame);
  const isLoading = useAppSelector(selectIsLoading);
  const categoryGameDetail = useCategoryGameDetail(categoryName);
  const username = useAppSelector(selectUsername);

  const [dataGameList, setDataGameList] = useState<Array<SubGameType>>([]);

  const [state, setState] = useState<TypeSubGameProps>({
    activeSortId: "all",
    search: "",
    sortId: "",
    currentPage: 1,
    providerCode: providerId,
    sortTitle: "All Game",
  });

  const [dataSubGame, setDataSubGame] =
    useState<Array<SubGameType>>(dataSLotsMobile);

  useEffect(() => {
    if (categoryName === "board-game") {
      setDataSubGame(dataBoardGameMobile);
      return;
    }
    if (categoryName === "crash-game") {
      setDataSubGame(dataCrashMobile);
      return;
    }
    setDataSubGame(dataSLotsMobile);
  }, [categoryName]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      currentPage: 1,
      activeSortId: "all",
      sortId: "",
      sortTitle: "All Game",
    }));
  }, [providerId]);

  const getDataGame = async () => {
    const res = await gameApi.getSubGameList({
      ...(providerId ? "" : { lobby: SubGameEnum.Lobby }),
      ...(categoryName && {
        game_type:
          categoryGameDetail.id === "BGC" ? "BG" : categoryGameDetail.id,
      }),
      ...(providerId && { provider: providerId }),
      size: 500,
      page: 1,
      featured: state.sortId,
    });

    if (res.data.status === ResponseCode.SUCCESS) {
      setDataGameList(res.data.data);

      const element = document.getElementById("game-scroll");

      if (element) {
        element.scrollIntoView({ behavior: "auto" });
      }

      return;
    }
  };

  useEffect(() => {
    const elementDefaultLayout = document.getElementById(
      "main__default-layout",
    );
    const elementCategoriesGame = document.getElementById("categories-game");

    if (elementDefaultLayout) {
      elementDefaultLayout.addEventListener("scroll", function () {
        if (elementDefaultLayout.scrollTop > 83) {
          elementCategoriesGame?.classList.add("show-categories");
          return;
        }
        elementCategoriesGame?.classList.remove("show-categories");
      });
    }
  }, []);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      search: "",
    }));
    dispatch(
      getSubGameList({
        ...(providerId ? "" : { lobby: SubGameEnum.Lobby }),
        ...(categoryName && {
          game_type:
            categoryGameDetail.id === "BGC" ? "BG" : categoryGameDetail.id,
        }),
        ...(providerId && { provider: providerId }),
        size: pageSize,
        page: state.currentPage,
        featured: state.sortId,
      }),
    );
    getDataGame();
  }, [categoryName, providerId, state.currentPage, state.sortId]);

  // const listFilter = dataSubGameList.data.filter((itemFilter: SubGameType) =>
  //   filterSubGame(itemFilter, state.search),
  // );

  const listFilterGame = dataGameList.filter((itemFilter) =>
    itemFilter.name.toLowerCase().includes(state.search.toLowerCase()),
  );

  const handleSortSubGame = (valueSort: string) => {
    if (valueSort === "hot") {
      setState((prev) => ({
        ...prev,
        sortId: "1",
        activeSortId: "hot",
        sortTitle: "Hot game",
      }));
      return;
    }
    setState((prev) => ({
      ...prev,
      sortId: "",
      activeSortId: "all",
      sortTitle: "All Game",
    }));
  };

  const handleNavigateSubGame = (itemCategory: SubGameType) => {
    const findCategory = _find(
      LIST_GAME_CATEGORY_DETAILS,
      (category: CategoryGameType) => category.id.includes(itemCategory.type),
    );
    navigate(`${findCategory?.path}/${itemCategory.provider_code}`);
    setState((prev) => ({
      ...prev,
      providerCode: itemCategory.provider_code,
    }));
  };

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      search: e.target.value,
    }));
  };

  const renderSubGameList = useCallback(() => {
    if (isLoading) return <LoadingCustom width={"100%"} center={true} />;

    if (dataSubGameList?.data?.length <= 0)
      return <Empty css={SubGameNewMobileStyle.empty} />;

    // if (listFilter.length <= 0)
    //   return <Empty css={SubGameNewMobileStyle.empty} />;
    const dataGame =
      state.search.length > 1 ? listFilterGame : dataSubGameList.data;

    return (
      <div css={SubGameNewMobileStyle.listSubGame}>
        {dataGame?.map((itemSubGame, index) => (
          <div
            onClick={() => {
              handleLoginGame(itemSubGame, username, dispatch);
            }}
            key={index}
            css={SubGameNewMobileStyle.cardSubGame}
          >
            <img src={itemSubGame.picture} alt="" />
            <div css={SubGameNewMobileStyle.nameCardItem}>
              {itemSubGame.name}
            </div>
          </div>
        ))}
      </div>
    );
  }, [isLoading, dataSubGameList, state.search]);

  const renderPagination = () => {
    if (dataSubGameList.data.length <= 0) return;

    if (listFilterGame.length <= 0) return;
    if (state.search.length > 1) return;

    const handlePage = (page: number) => {
      setState((prev) => ({
        ...prev,
        currentPage: page,
      }));
    };

    return (
      <div css={SubGameNewMobileStyle.paginationWrap}>
        {/* <Pagination
          css={SubGameNewMobileStyle.paginationItem}
          current={state.currentPage}
          pageSize={pageSize}
          total={dataSubGameList.totalCount}
          onChange={(page: number) =>
            setState((prev) => ({
              ...prev,
              currentPage: page,
            }))
          }
          showSizeChanger={false}
        /> */}
        <PaginationCustom
          currentPage={state.currentPage}
          handleCurrentPage={handlePage}
          totalPage={dataSubGameList.totalPages}
        />
      </div>
    );
  };

  return (
    <div css={SubGameNewMobileStyle.wrapper}>
      <div id="game-scroll" />
      <CategoryGameSwiper />
      <div css={SubGameNewMobileStyle.filterGame.index}>
        <div css={SubGameNewMobileStyle.filterGame.search}>
          <Input
            onChange={onChange}
            value={state.search}
            placeholder="VD: Dragon Ball"
            suffix={
              <SearchOutlined
                style={{
                  fontSize: "14px",
                  color: "black",
                }}
              />
            }
          />
        </div>
        <div css={SubGameNewMobileStyle.filterGame.boxWrap}>
          {LIST_GAME_FILTER_M.map((item, index) => (
            <div
              key={index}
              css={SubGameNewMobileStyle.boxFilter(
                item.id === state.activeSortId,
              )}
              onClick={() => {
                handleSortSubGame(item.id);
              }}
            >
              <img src={item.icon} alt="" />
              <span>{item.name}</span>
            </div>
          ))}
          {/* <select css={SubGameNewMobileStyle.filterGame.select}>
            <option>Option</option>
            <option> Hot Game </option>
            <option> Other </option>
          </select> */}
        </div>
      </div>
      <div css={SubGameNewMobileStyle.mainContent}>
        <div id="categories-game" css={SubGameNewMobileStyle.categoryGame}>
          {dataSubGame.map((items, index) => (
            <div
              onClick={() => {
                handleNavigateSubGame(items);
              }}
              css={SubGameNewMobileStyle.categoryGameItem(
                providerId === items.provider_code,
              )}
              key={index}
            >
              <img src={items.picture} alt="" />
              <span>{items.name}</span>
            </div>
          ))}
        </div>
        <div css={SubGameNewMobileStyle.subGame}>
          <div css={SubGameNewMobileStyle.titleSubGame}>
            <span>{state.sortTitle}</span>
          </div>
          {renderSubGameList()}

          {renderPagination()}
        </div>
      </div>
    </div>
  );
};

export default SubGameNewMobile;
