import { AuthApi } from "api/auth";
import { ResponseCode } from "contants/response";
import { ModalTypeValue } from "enums/modalType";
import { handleResetData } from "helpers/handleResetData";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { selectToken, selectUsername } from "store/auth";
import { openModal } from "store/master";

import { useAppDispatch, useAppSelector } from "./app-hook";

export const useVerify = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const username =
    useAppSelector(selectUsername) || localStorage.getItem("username") || "";
  const token =
    useAppSelector(selectToken) || localStorage.getItem("token") || "";

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchVerifyToken = useCallback(async () => {
    if (!username || !token) {
      navigate("/");
      dispatch(openModal(ModalTypeValue.LOGIN));
      return;
    }

    try {
      setIsLoading(true);
      const { status } = await AuthApi.verifyToken(username, {
        username,
        token,
      });
      if (status === ResponseCode.SUCCESS) {
        return;
      }
      handleResetData(dispatch, navigate);
    } finally {
      setIsLoading(false);
    }
  }, [username]);

  useEffect(() => {
    fetchVerifyToken();
  }, [fetchVerifyToken]);

  return { isLoading };
};
