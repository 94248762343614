import Loading from "components/desktop/ui/Loading";
import { useVerify } from "hooks/useVerify";
import { Outlet, useOutletContext } from "react-router-dom";
import { outletContextType } from "types";

const ProtectedRoute = ({ children }: { children?: React.ReactNode }) => {
  const { isLoading } = useVerify();
  const { openNotification } = useOutletContext<outletContextType>();
  if (isLoading) return <Loading height={500} center />;

  return (
    <>
      {children || (
        <Outlet context={{ openNotification } as outletContextType} />
      )}
    </>
  );
};

export default ProtectedRoute;
