import { createAsyncThunk } from "@reduxjs/toolkit";
import { messageApi } from "api/message";
import { MessagePayload } from "api/message/type";

export const getMessage = createAsyncThunk(
  "message/getMessage",
  async (dataMessage: MessagePayload, { rejectWithValue }) => {
    try {
      const response = await messageApi.MemberMessage(
        dataMessage.username,
        dataMessage,
      );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
