import { ModalTypeValue } from "enums/modalType";
import { useAppSelector } from "hooks/app-hook";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { selectOpenModal } from "store/master";

import { ModalAddBank } from "./ModalAddBank";
import ModalForgetPassword from "./ModalForgetPassword";
import ModalSignIn from "./ModalSignIn";
import ModalSignUp from "./ModalSignUp";

function ModalGroup() {
  const openModal = useAppSelector(selectOpenModal);

  return (
    <>
      {openModal && (
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_CLIENT_ID_CAPTCHA || ""}
          scriptProps={{
            async: false,
            defer: false,
            nonce: undefined,
          }}
        >
          {openModal === ModalTypeValue.LOGIN && <ModalSignIn />}
          {openModal === ModalTypeValue.REGISTER && <ModalSignUp />}
          {openModal === ModalTypeValue.BANK && <ModalAddBank />}
          {openModal === ModalTypeValue.FORGET_PASSWORD && (
            <ModalForgetPassword />
          )}
        </GoogleReCaptchaProvider>
      )}
      {/* <ModalAnnouncement /> */}
    </>
  );
}

export default ModalGroup;
