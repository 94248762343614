import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { WithDrawalFormSettingTypeResponse } from "api/withdraw/type";
import { NoInfer } from "react-redux";
import { WithdrawStateType } from "types/withdraw";

import { getWithdrawalForm } from "./withdraw.thunk";

export const extraReducers = (
  builder: ActionReducerMapBuilder<NoInfer<WithdrawStateType>>,
) => {
  builder
    .addCase(getWithdrawalForm.pending, (state: WithdrawStateType) => {
      state.isLoading = true;
    })
    .addCase(
      getWithdrawalForm.fulfilled,
      (
        state: WithdrawStateType,
        action: PayloadAction<WithDrawalFormSettingTypeResponse>,
      ) => {
        return {
          ...state,
          isLoading: false,
          dataWithdrawal: action.payload.data,
        };
      },
    );
};
