/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Button, Empty, Image } from "antd";
import { gameApi } from "api/game";
import { ImageError } from "assets/common/images";
import LoadingCustom from "components/common/LoadingCustom";
import { PaginationCustom } from "components/common/PaginationCustom";
import Search from "components/desktop/Search";
import SubGameImageLoading from "components/desktop/SubGameImageLoading";
import { sidebarBoard } from "contants/game/desktop/page/board";
import { ResponseCode } from "contants/response";
import { GameTypeEnum } from "enums/game";
import handleLoginGame from "helpers/handleLoginGame";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import _find from "lodash/find";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { selectUsername } from "store/auth";
import {
  selectCurrentPage,
  selectDataSubGame,
  selectFilterSubGame,
  selectIsLoading,
  setCurrentPage,
  setDetailProviderBoard,
  setFilterSubGame,
} from "store/game";
import { getSubGameList } from "store/game/game.thunk";
import { DetailProviderBoardType, SubGameType } from "types/game";

import { SubBoardGameStyle } from "./style.index";

interface Props {
  detailProvider: DetailProviderBoardType;
}

export const RenderGameBoard = (props: Props) => {
  const { detailProvider } = props;

  const dispatch = useAppDispatch();
  const { provider } = useParams();

  const dataSubGame = useAppSelector(selectDataSubGame);
  const currentPage = useAppSelector(selectCurrentPage);
  const isLoading = useAppSelector(selectIsLoading);
  const username = useAppSelector(selectUsername);
  const filterSubGame = useAppSelector(selectFilterSubGame);

  const [dataGame, setDataGame] = useState<Array<SubGameType>>([]);
  const [isImageLoaded, setImageLoaded] = useState(false);

  const handleImageLoaded = () => {
    setImageLoaded(true);
  };

  const getDataGame = async () => {
    const res = await gameApi.getSubGameList({
      provider: detailProvider.provider_code,
      game_type: GameTypeEnum.BoardGame,
      page: 1,
      size: 500,
    });
    if (res.data.status === ResponseCode.SUCCESS) {
      setDataGame(res.data.data);
      return;
    }
  };

  const handlePage = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  const renderListSubGame = () => {
    if (isLoading) return <LoadingCustom width={"100%"} center={true} />;

    const findGameByName = dataGame.filter((itemFilter) =>
      itemFilter.name.toLowerCase().includes(filterSubGame.toLowerCase()),
    );

    if (dataSubGame.data.length === 0)
      return (
        <Empty
          css={css`
            height: 500px !important;
            justify-content: center !important;
            display: flex !important;
            flex-direction: column !important;
          `}
        />
      );

    const dataSubGameList =
      filterSubGame.length > 1 ? findGameByName : dataSubGame.data;

    return (
      <div css={SubBoardGameStyle.listSubGame}>
        {dataSubGameList.map((subGame: SubGameType) => (
          <div
            css={SubBoardGameStyle.cardGameWrap(detailProvider)}
            key={subGame.game_code}
          >
            <div css={SubBoardGameStyle.cardInner}>
              {!isImageLoaded && <SubGameImageLoading />}

              <div css={SubBoardGameStyle.cardLayout}>
                <Image
                  style={{
                    display: isImageLoaded ? "block" : "none",
                    aspectRatio: 1,
                  }}
                  src={subGame.picture}
                  preview={false}
                  onLoad={handleImageLoaded}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = ImageError;
                  }}
                />

                <div className="overlay">
                  <Button
                    type="primary"
                    onClick={() => handleLoginGame(subGame, username, dispatch)}
                  >
                    Play
                  </Button>
                </div>
              </div>
              <div css={SubBoardGameStyle.gameName}>
                <p>{subGame.name}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    if (!detailProvider.provider_code) return;

    dispatch(
      getSubGameList({
        provider: detailProvider.provider_code,
        game_type: GameTypeEnum.BoardGame,
        page: currentPage,
        size: 15,
      }),
    );
    getDataGame();
  }, [detailProvider, currentPage]);

  useEffect(() => {
    if (!provider) return;

    const findDataDetailProvider = _find(
      sidebarBoard,
      (item: DetailProviderBoardType) => item.provider_code === provider,
    );

    if (!findDataDetailProvider) return;

    dispatch(setDetailProviderBoard(findDataDetailProvider));
  }, [provider]);

  return (
    <div css={SubBoardGameStyle.wrapper}>
      <div css={SubBoardGameStyle.titleSearch}>
        <div css={SubBoardGameStyle.titleSearchSelf}>
          <Image
            src={detailProvider.pictureTitle}
            alt=""
            width={191}
            height={54}
            preview={false}
          />

          <Search
            onChange={(e) => {
              dispatch(setFilterSubGame(e.target.value));
            }}
            color={"#00aadf"}
          />
        </div>
      </div>

      <div css={SubBoardGameStyle.filterGame(detailProvider)}>
        <div css={SubBoardGameStyle.filterList}>
          <div css={SubBoardGameStyle.filterItem(detailProvider)}>
            All games
          </div>
        </div>
      </div>

      <div css={SubBoardGameStyle.listSubGameWrap}>{renderListSubGame()}</div>

      {dataSubGame.data.length > 0 && filterSubGame.length === 0 && (
        <div css={SubBoardGameStyle.paginate}>
          <PaginationCustom
            currentPage={currentPage}
            handleCurrentPage={handlePage}
            totalPage={dataSubGame.totalPages}
          />
        </div>
      )}
    </div>
  );
};
