import { css } from "@emotion/react";

import { Props } from ".";

export const LoadingCustomStyle = {
  self: ({ center, width, height }: Props) => css`
    height: 100%;
    & .ant-spin {
      height: ${height || 500}px;
      width: ${width}px;
      ${center &&
      css`
        display: grid;
        place-items: center;
      `}
    }
  `,
};
