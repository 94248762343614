/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Pagination } from "antd";
import { ReactComponent as StarSolid } from "assets/common/icons/star-solid.svg";
import HelmetCustom from "components/common/HelmetCustom";
import Loading from "components/desktop/ui/Loading";
import MDrawer from "components/mobile/MDrawer";
import { userPath } from "contants/path";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { selectIsLoading } from "store/announcement";
import { selectUsername } from "store/auth";
import { selectDataMessage } from "store/message";
import { getMessage } from "store/message/message.thunk";
import { theme } from "styles/theme";
import { MessageType } from "types/message";

import { MMessageModalDetail } from "./MMessageModal";

const PAGE_SIZE = 10;

export const MMessagePage = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const username = useAppSelector(selectUsername);
  const message = useAppSelector(selectDataMessage);
  const isLoading = useAppSelector(selectIsLoading);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isOpenModalDetail, setIsOpenModalDetail] = useState<boolean>(false);
  const [detailMessage, setDetailMessage] = useState<MessageType>({
    content: "",
    create_date: "",
    title: "",
  });
  const [isOpenDraw, setIsOpenDraw] = useState<boolean>(true);

  const handleClose = () => {
    setIsOpenDraw(false);
    navigate(userPath);
  };

  useEffect(() => {
    if (!username) return;

    dispatch(
      getMessage({
        username,
        size: PAGE_SIZE,
        page: currentPage,
      }),
    );
  }, [username, currentPage]);
  return (
    <div>
      <HelmetCustom title="Internal Mail" />
      <MDrawer
        title={"Mailbox"}
        isOpen={isOpenDraw}
        onClose={handleClose}
        bodyStyle={{
          backgroundColor: "#fff",
        }}
      >
        <div
          css={css`
            border: 1px solid #ddd;
            border-radius: 4px;
          `}
        >
          <div
            css={css`
              padding: 15px;
            `}
          >
            <div
              css={css`
                border-bottom: 1px solid #ddd;
              `}
            >
              <div
                css={css`
                  color: ${theme.colors.main};
                  cursor: pointer;
                  background-color: white;
                  border: 1px solid #ddd;
                  border-bottom-color: transparent;
                  width: 154px;

                  border-radius: 4px 4px 0 0;
                  padding: 10px 15px;

                  display: flex;
                  align-items: center;
                  gap: 10px;
                  font-size: 14px;
                `}
              >
                <StarSolid fill={theme.colors.main} height={15} width={15} />
                All mail
              </div>
            </div>

            <div
              css={css`
                padding-top: 12px;
              `}
            >
              {isLoading ? (
                <Loading center height={250} />
              ) : (
                message.data.map((item: MessageType, index: number) => (
                  <div
                    css={css`
                      display: flex;
                      gap: 8px;
                    `}
                    key={index}
                    onClick={() => {
                      setIsOpenModalDetail(true);
                      setDetailMessage(item);
                    }}
                  >
                    <div
                      css={css`
                        width: 75%;
                        display: flex;
                        flex-direction: column;
                      `}
                    >
                      <div
                        css={css`
                          text-overflow: ellipsis;
                          white-space: nowrap;
                          overflow: hidden;
                          height: 20px;
                          color: ${theme.colors.main};
                          font-weight: 600;
                        `}
                      >
                        {item.title || "--"}
                      </div>
                      <div
                        css={css`
                          height: 20px;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                          overflow: hidden;
                          color: #777777;
                          font-weight: 600;
                          opacity: 0.8;
                        `}
                      >
                        {item.content}
                      </div>
                    </div>
                    <div
                      css={css`
                        font-size: 14px;
                        color: black;
                        font-weight: 600;
                        opacity: 0.6;
                      `}
                    >
                      {item.create_date &&
                        moment(item.create_date).format("YYYY/MM/DD")}
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>

          <div
            css={css`
              padding: 20px 15px 0;
              display: flex;
              justify-content: center;
              align-items: center;

              .ant-pagination-item {
                background-color: #f0f0f0;
              }

              .ant-pagination-item-active {
                background-color: white;
              }

              .ant-pagination-item-link {
                color: black !important;
              }
            `}
          >
            <Pagination
              total={message.totalCount}
              pageSize={message.pageSize}
              onChange={(e) => setCurrentPage(e)}
              current={currentPage}
              size="small"
            />
          </div>
        </div>
      </MDrawer>

      <MMessageModalDetail
        setIsOpenModalDetail={setIsOpenModalDetail}
        isOpenModalDetail={isOpenModalDetail}
        detailMessage={detailMessage}
      />
    </div>
  );
};
