/** @jsxImportSource @emotion/react */

import { HPMarqueeIcon } from "assets/desktop/images/homepage";
import _map from "lodash/map";
import Marquee from "react-fast-marquee";
import { useSelector } from "react-redux";
import { selectAllAnnouncement } from "store/announcement";

import { NotifyMarqueStyle } from "./index.style";

export const NotifyMarque = () => {
  const announcementData = useSelector(selectAllAnnouncement);

  return (
    <section>
      <div css={NotifyMarqueStyle.item}>
        <img loading="lazy" src={HPMarqueeIcon} alt="error" />
        <div css={NotifyMarqueStyle.text}>
          <Marquee pauseOnHover>
            {_map(announcementData, (item) => (
              <div
                css={NotifyMarqueStyle.marqueeItem}
                key={item.id}
                dangerouslySetInnerHTML={{
                  __html: `💥 ${item.title}`,
                }}
              />
            ))}
          </Marquee>
        </div>
      </div>
    </section>
  );
};
