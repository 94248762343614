import * as casinoGame from "assets/common/images/game/mobile/casino";
import { SubGameType } from "types/game";

export const dataCasinoMobile: SubGameType[] = [
  // {
  //   game_code: "",
  //   picture: casinoGame.mobileCasinoXG,
  //   provider_code: "",
  //   type: "LT",
  //   name: "Gx Live",
  //   featured: "",
  //   new_arrive: "",
  // },
  {
    game_code: "",
    picture: casinoGame.mobileCasinoDG,
    provider_code: "",
    type: "LT",
    name: "DG Live",
    featured: "",
    new_arrive: "",
  },
  {
    game_code: "",
    picture: casinoGame.mobileCasinoEzuqi,
    provider_code: "",
    type: "LT",
    name: "Ezuqi Live",
    featured: "",
    new_arrive: "",
  },
  {
    game_code: "",
    picture: casinoGame.mobileCasinoEG,
    provider_code: "",
    type: "LT",
    name: "EVO Live",
    featured: "",
    new_arrive: "",
  },
  {
    game_code: "",
    picture: casinoGame.mobileCasinoM,
    provider_code: "",
    type: "LT",
    name: "MG Live",
    featured: "",
    new_arrive: "",
  },
  {
    game_code: "",
    picture: casinoGame.mobileCasinoSEXY,
    provider_code: "",
    type: "LT",
    name: "SX Live",
    featured: "",
    new_arrive: "",
  },
];
