import { css } from "@emotion/react";
import { theme } from "styles/theme";

interface BackgroundProps {
  gradientColorFrom: string;
  gradientColorTo: string;
}

export const FuncUserStyle = {
  auth: {
    index: css`
      /* background-color: rgba(114, 114, 114, 1); */

      background: #000;
      overflow: hidden;
    `,
    content: css`
      /* padding: 6px; */
      display: flex;
      flex-direction: row;
      /* align-items: center; */
      justify-content: space-between;
      height: 40px;
    `,
    info: css`
      /* width: 30%; */
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding-left: 16px;
      border: 1px solid #c9975a;
      flex: 1;
      .points {
        color: ${theme.colors.main};
        cursor: pointer;
        width: 100%;
        text-align: center;

        display: flex;
        align-items: center;
        gap: 4px;
      }
      .username {
        color: #fff;
        /* margin-top: 6px; */
        font-size: 12px;
      }
    `,
    menu: css`
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      position: relative;
    `,

    imageBlock: css`
      position: absolute;
      top: 0;
      /* left: 0; */
      width: 60%;
      height: 40px;
      z-index: 1;
    `,

    itemMenu: css`
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 0 8px;
      text-decoration: none;
      /* text-transform: uppercase; */
      gap: 6px;
      .icon {
        z-index: 5;
        width: 24px;
        height: 24px;
      }

      p {
        color: black;
        font-size: 10px;
        /* margin-top: 4px; */

        z-index: 2;
      }
      &:first-of-type {
        &::after {
          content: " ";
          position: absolute;
          right: 52%;
          height: 42px;
          width: 1.2px;
          z-index: 5;
          background-color: black;
          transform: rotate(15deg);
        }
      }
    `,
  },

  noLogin: {
    fcUser: {
      index: css`
        display: flex;
        position: relative;
      `,

      itemLogin: css`
        border: 1px solid #c9975a;

        background: #000;
        width: 50%;
        padding: 10px 0;

        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        text-transform: uppercase;
        color: ${theme.colors.white};
      `,

      itemSignUp: css`
        /* background: linear-gradient(88deg, #c9975a 0%, #fff 100%); */
        width: 50%;
        padding: 10px 0;
        position: relative;
        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 4;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          text-transform: uppercase;
          color: ${theme.colors.black};
        }

        .ant-image {
          position: unset;
          z-index: 3;
        }
      `,
      itemBackground: css`
        position: absolute;

        top: 0;
        right: 0;
        width: 110% !important;
        height: 42px !important;
        /* object-fit: contain; */
      `,

      item: ({ gradientColorFrom, gradientColorTo }: BackgroundProps) => css`
        background: linear-gradient(
          90deg,
          ${gradientColorFrom} 0%,
          ${gradientColorTo} 100%
        );
        width: 50%;
        padding: 10px 0;

        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        text-transform: uppercase;
        color: ${theme.colors.white};
      `,
    },
  },
};
