/** @jsxImportSource @emotion/react */

import { NotifyMarque } from "components/desktop/NotiMarque";
import CategoryGameSwiper from "components/mobile/CategoryGameSwiper";
import FuncUser from "components/mobile/FuncUser";
import { dataSportsMobile } from "contants/game/mobile/contents/sports";
import handleLoginGame from "helpers/handleLoginGame";
import { useAppDispatch } from "hooks/app-hook";
import { SwiperBanner } from "pages/desktop/HomePage/SwiperBanner/SwiperPagination";
import { useSelector } from "react-redux";
import { selectUsername } from "store/auth";

import { SportGameMobileStyle } from "./style.index";

const SportsGameMobile = () => {
  const username = useSelector(selectUsername);
  const dispatch = useAppDispatch();
  return (
    <div css={SportGameMobileStyle.wrapper}>
      <NotifyMarque />
      <SwiperBanner />
      <FuncUser />
      <CategoryGameSwiper />
      <div css={SportGameMobileStyle.mainContent}>
        {dataSportsMobile.map((items, index) => (
          <div
            onClick={() => {
              handleLoginGame(items, username, dispatch);
            }}
            css={SportGameMobileStyle.item}
            key={index}
          >
            <img src={items.picture} alt="" />
            <span>{items.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SportsGameMobile;
