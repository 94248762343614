import * as cockFightingGame from "assets/common/images/game/mobile/cock-fighting";
import { SubGameType } from "types/game";

export const dataCockFightingMobile: SubGameType[] = [
  {
    game_code: "",
    picture: cockFightingGame.mobileCockFightingWS168,
    provider_code: "DS88",
    type: "CF",
    name: "DS88 Cock Fighting",
    featured: "",
    new_arrive: "",
  },
];
