import { createSlice } from "@reduxjs/toolkit";
import { ApplicationRootState } from "types";
import { GameStateType } from "types/game";

import { extraReducers } from "./game.extraReducers";
import { reducers } from "./game.reducers";

export const initialState: GameStateType = {
  isLoading: false,
  currentPage: 1,
  filterSubGame: "",
  dataCategoryDetails: [],
  dataCategoryGameAll: [],
  dataCategoryGameFilter: [],
  dataSubGame: {
    data: [],
    totalCount: 0,
    totalPages: 0,
  },
  dataSubGameFilter: [],
  categoryCurrent: "",
  typeGame: "",
  detailProviderSlot: {
    provider_code: "",
    provider_name: "",
    pictureSidebar: "",
    pictureTitle: "",
    backgroundColor: "",
    hoverBackgroundColor: "",
  },
  detailProviderBoard: {
    provider_code: "",
    provider_name: "",
    pictureTopBar: "",
    pictureTitle: "",
    backgroundColor: "",
    hoverBackgroundColor: "",
  },
  detailProviderCrash: {
    provider_code: "",
    provider_name: "",
    pictureTopBar: "",
    pictureTitle: "",
    backgroundColor: "",
    hoverBackgroundColor: "",
  },
};

const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers,
  extraReducers,
});

export const {
  resetDataSubGame,
  resetDataCategoryGameFilter,
  setCategoryCurrent,
  setDetailProviderSlot,
  setDetailProviderBoard,
  setDetailProviderCrash,
  setFilterSubGame,
  setCurrentPage,
  resetCurrentPage,
  resetFilterSubGame,
} = gameSlice.actions;

export const selectIsLoading = (state: ApplicationRootState) =>
  state.game.isLoading;

export const selectDataCategoryGameFilter = (state: ApplicationRootState) =>
  state.game.dataCategoryGameFilter;

export const selectDataGameAll = (state: ApplicationRootState) =>
  state.game.dataCategoryGameAll;

export const selectCategoryDetails = (state: ApplicationRootState) =>
  state.game.dataCategoryDetails;

export const selectDataSubGame = (state: ApplicationRootState) =>
  state.game.dataSubGame;

export const selectDetailProviderSlot = (state: ApplicationRootState) =>
  state.game.detailProviderSlot;

export const selectDetailProviderBoard = (state: ApplicationRootState) =>
  state.game.detailProviderBoard;

export const selectDetailProviderCrash = (state: ApplicationRootState) =>
  state.game.detailProviderCrash;

export const selectCurrentPage = (state: ApplicationRootState) =>
  state.game.currentPage;
export const selectFilterSubGame = (state: ApplicationRootState) =>
  state.game.filterSubGame;

export default gameSlice.reducer;
