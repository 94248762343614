/** @jsxImportSource @emotion/react */

import { Modal } from "antd";
import { ModalTypeValue } from "enums/modalType";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { selectOpenModal, setOpenModal } from "store/master";

import { ModalNotiStyle } from "./style.index";

const ModalNotiGame = () => {
  const dispatch = useAppDispatch();

  const openNotiModal = useAppSelector(selectOpenModal);

  return (
    <Modal
      open={openNotiModal === ModalTypeValue.NOTIGAME}
      footer={null}
      css={ModalNotiStyle.wrapper}
      closable={false}
      centered
      style={{ top: "-35%", left: "2%" }}
    >
      <div css={ModalNotiStyle.mainContent}>
        <div css={ModalNotiStyle.header}>
          <h4>suggest</h4>
          <span>3B178AD9AG12666045DBA5</span>
        </div>
        <div css={ModalNotiStyle.body}>
          <span>
            Currently the lobby is closed. If you have any questions, please
            contact the department Customer care department 24/7
          </span>
        </div>
        <div css={ModalNotiStyle.footer}>
          <div onClick={() => dispatch(setOpenModal(ModalTypeValue.CLOSE))}>
            confirm
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalNotiGame;
