import { createAsyncThunk } from "@reduxjs/toolkit";
import { retriveApi } from "api/retrive";

export const getPromotionList = createAsyncThunk(
  "retrive/getPromotionList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await retriveApi.getPromotionList();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
