import { createSlice } from "@reduxjs/toolkit";
import { ApplicationRootState } from "types";
import { ContactStateType } from "types/contact";

import { extraReducers } from "./contact.extraReducers";

export const initialState: ContactStateType = {
  isLoading: false,
  dataContact: [],
};

const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {},
  extraReducers,
});

export const selectContacts = (state: ApplicationRootState) =>
  state.contact.dataContact;

export const selectIsLoading = (state: ApplicationRootState) =>
  state.contact.isLoading;

export default contactSlice.reducer;
