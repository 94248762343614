/** @jsxImportSource @emotion/react */

import moment from "moment";
import React, { useEffect, useState } from "react";
require("moment/locale/vi");
const RenderTime = () => {
  const [currentTime, setCurrentTime] = useState(moment().toDate());
  useEffect(() => {
    const timerInterval = setInterval(() => {
      setCurrentTime(moment().toDate());
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []);
  return (
    <span>{moment(currentTime).locale("en").format("h:mm:ss A [GMT]Z")}</span>
  );
};

export default RenderTime;
