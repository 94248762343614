import * as casino from "assets/common/images/game/desktop/menu/casino";
import { SubGameType } from "types/game";

export const dataCasino: SubGameType[] = [
  // {
  //   game_code: "",
  //   picture: casino.desktopCasinoXG,
  //   provider_code: "GX",
  //   type: "LC",
  //   name: "",
  //   featured: "",
  //   new_arrive: "",
  // },
  {
    game_code: "",
    picture: casino.desktopCasinoDREAM,
    provider_code: "DG",
    type: "LC",
    name: "",
    featured: "",
    new_arrive: "",
  },

  {
    game_code: "",
    picture: casino.desktopCasinoEzuqi,
    provider_code: "Ezugi",
    type: "LC",
    name: "",
    featured: "",
    new_arrive: "",
  },
  {
    game_code: "",
    picture: casino.desktopCasinoEG,
    provider_code: "EVO",
    type: "LC",
    name: "",
    featured: "",
    new_arrive: "",
  },
  {
    game_code: "",
    picture: casino.desktopCasinoMG,
    provider_code: "MG",
    type: "LC",
    name: "",
    featured: "",
    new_arrive: "",
  },
  {
    game_code: "",
    picture: casino.desktopCasinoSEXY,
    provider_code: "SX",
    type: "LC",
    name: "",
    featured: "",
    new_arrive: "",
  },
  // {
  //   game_code: "",
  //   picture: casino.desktopCasinoWM,
  //   provider_code: "WM",
  //   type: "LC",
  //   name: "",
  //   featured: "",
  //   new_arrive: "",
  // },
  // {
  //   game_code: "",
  //   picture: casino.desktopCasinoAG,
  //   provider_code: "",
  //   type: "LC",
  //   name: "",
  //   featured: "",
  //   new_arrive: "",
  // },

  // {
  //   game_code: "",
  //   picture: casino.desktopCasinoSA,
  //   provider_code: "SA",
  //   type: "LC",
  //   name: "",
  //   featured: "",
  //   new_arrive: "",
  // },
  // {
  //   game_code: "",
  //   picture: casino.desktopCasinoPT,
  //   provider_code: "",
  //   type: "LC",
  //   name: "",
  //   featured: "",
  //   new_arrive: "",
  // },
  // {
  //   game_code: "",
  //   picture: casino.desktopCasinoBG,
  //   provider_code: "",
  //   type: "LC",
  //   name: "",
  //   featured: "",
  //   new_arrive: "",
  // },

  // {
  //   game_code: "",
  //   picture: casino.desktopCasinoP,
  //   provider_code: "PS",
  //   type: "LC",
  //   name: "",
  //   featured: "",
  //   new_arrive: "",
  // },
  // {
  //   game_code: "",
  //   picture: casino.desktopCasinoCALLBET,
  //   provider_code: "",
  //   type: "LC",
  //   name: "",
  //   featured: "",
  //   new_arrive: "",
  // },
  // {
  //   game_code: "",
  //   picture: casino.desktopCasinoM,
  //   provider_code: "",
  //   type: "LC",
  //   name: "",
  //   featured: "",
  //   new_arrive: "",
  // },
  // {
  //   game_code: "",
  //   picture: casino.desktopCasinoPM,
  //   provider_code: "",
  //   type: "LC",
  //   name: "",
  //   featured: "",
  //   new_arrive: "",
  // },
];
