/** @jsxImportSource @emotion/react */

import { ReactComponent as DepositIcon } from "assets/common/icons/deposit.svg";
import { ReactComponent as WithdrawIcon } from "assets/common/icons/withdraw.svg";
import { PATH } from "contants/path";
import { NavLink } from "react-router-dom";

import { MenuPaymentStyle } from "./index.style";

const MenuPayment = () => {
  return (
    <div css={MenuPaymentStyle.self}>
      <NavLink to={PATH.User.Deposit} css={MenuPaymentStyle.menuItem}>
        <DepositIcon />
        Deposit
      </NavLink>
      <NavLink to={PATH.User.Withdraw} css={MenuPaymentStyle.menuItem}>
        <WithdrawIcon />
        Withdraw
      </NavLink>
    </div>
  );
};

export default MenuPayment;
