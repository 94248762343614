/** @jsxImportSource @emotion/react */
import { icons } from "assets/desktop";
import { ModalTypeValue } from "enums/modalType";
import { useAppDispatch } from "hooks/app-hook";
import { openModal } from "store/master";

import { WrapperAuthFormStyle } from "./index.style";

type Props = {
  children?: React.ReactNode;
  title: string;
};

function WrapperAuthForm({ children, title }: Props) {
  const dispatch = useAppDispatch();
  return (
    <div css={WrapperAuthFormStyle.index}>
      <div css={WrapperAuthFormStyle.content}>
        <div css={WrapperAuthFormStyle.leftForm}>
          <div css={WrapperAuthFormStyle.header}>
            <div className="title-box">
              <span>{title}</span>
            </div>
          </div>
          <div css={WrapperAuthFormStyle.form}>{children}</div>
        </div>
        <div className="bgAuthForm" css={WrapperAuthFormStyle.rightBg}></div>
      </div>
      <div
        onClick={() => dispatch(openModal(ModalTypeValue.CLOSE))}
        css={WrapperAuthFormStyle.closeButton}
      >
        <icons.IconClose />
      </div>
    </div>
  );
}

export default WrapperAuthForm;
