import { icons as mobileIcons } from "assets/mobile";
import { PATH } from "contants/path";
import { FunctionComponent, SVGProps } from "react";

interface MenuItem {
  label: string;
  icon: FunctionComponent<
    SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
  to?: string;
}

export const MEMBER_MENU_MOBILE: MenuItem[] = [
  {
    label: "Mailbox",
    icon: mobileIcons.mailIcon,
    to: PATH.User.InternalMail,
  },
  {
    label: "Betting History",
    icon: mobileIcons.historyIcon,
    to: PATH.User.BettingRecord,
  },
  {
    label: "Transactions History",

    icon: mobileIcons.listIcon,
    to: PATH.User.TransactionHistory,
  },
  {
    label: "Event Area",
    icon: mobileIcons.eventIcon,
    to: PATH.User.Event,
  },
  // {
  //   label: "Favourite",
  //   icon: mobileIcons.favouriteIcon,
  //   to: "#",
  // },
  {
    label: "Security",
    icon: mobileIcons.securityIcon,
    to: PATH.User.SecuritySetting,
  },
  {
    label: "Notification",
    icon: mobileIcons.notificationIcon,
    to: PATH.User.Announcement,
  },
  {
    label: "To Return",
    icon: mobileIcons.refundIcon,
    to: PATH.User.Refund,
  },
];
