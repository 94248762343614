import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const SearchStyle = {
  wrapper: (color: string) => css`
    padding: 5px;
    background: ${color || theme.colors.secondary[400]};
    width: 200px;

    &:hover {
      border-color: ${color || theme.colors.secondary[400]} !important;
    }

    .ant-input {
      padding: 8px 4px !important;
      &::placeholder {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: ${theme.colors.black};
      }
      &-suffix {
        padding: 0 5px 0 10px;
      }
    }
  `,
};
