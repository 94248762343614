import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { BettingRecordResponseType } from "api/bettingrecord/type";
import { NoInfer } from "react-redux";
import { BettingRecordStateType } from "types/bettingrecord";

import { getWager } from "./bettingrecord.thunk";

export const extraReducers = (
  builder: ActionReducerMapBuilder<NoInfer<BettingRecordStateType>>,
) => {
  builder
    .addCase(getWager.pending, (state: BettingRecordStateType) => {
      state.isLoading = true;
    })
    .addCase(
      getWager.fulfilled,
      (
        state: BettingRecordStateType,
        action: PayloadAction<BettingRecordResponseType>,
      ) => {
        state.isLoading = false;
        state.data = [...(action.payload.data || [])];
        state.total = action.payload.totalCount;
      },
    );
};
