import { IconHotFilter, IconNewFilter } from "assets/mobile/images/game";

interface TypeGameFilter {
  name: string;
  id: string;
  icon?: string;
}

export const LIST_GAME_FILTER: TypeGameFilter[] = [
  {
    name: "All Game",
    id: "all",
  },
  {
    name: "Hot game",
    id: "hot",
  },
];

export const LIST_GAME_FILTER_M: TypeGameFilter[] = [
  {
    name: "All",
    id: "all",
    icon: IconNewFilter,
  },
  {
    name: "Hot",
    id: "hot",
    icon: IconHotFilter,
  },
];
