import { createAsyncThunk } from "@reduxjs/toolkit";
import { withdrawApi } from "api/withdraw";

export const getWithdrawalForm = createAsyncThunk(
  "withdraw/getWithdrawalForm",
  async (username: string, { rejectWithValue }) => {
    try {
      const dataWithdrawal = await withdrawApi.getWithdrawalForm(username);
      return dataWithdrawal.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
