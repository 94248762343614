import * as board from "assets/common/images/game/desktop/menu/board";
import { SubGameType } from "types/game";

export const dataBoard: SubGameType[] = [
  {
    game_code: "",
    picture: board.desktopBoardJili,
    provider_code: "JILI",
    type: "BG",
    name: "",
    featured: "",
    new_arrive: "",
  },
];
