import { css } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const SwiperBannerStyle = {
  container: () => css`
    position: relative;
  `,
  image: () => css`
    width: 100%;
    height: auto;
    display: block;
    object-fit: contain;

    /* ${media.sm} {
      height: 134px;
    } */
  `,

  swiperPagination: () => css`
    /* width: 25rem; */
    flex: 2.5;
    .swiper-pagination-bullet-active {
      width: 20px !important;
      border-radius: 8px !important;
      background-color: #ffb31a !important;
    }
    .swiper-pagination-bullet {
      transition: 0.3s ease width !important;
      background-color: #fff9;
    }
  `,

  blockNavigation: () => css`
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;
    z-index: 2;
    position: absolute;
    width: 100%;
    top: 0;
    padding: 0 40px;
    transform: translateX(50%);
    right: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${media.sm} {
      padding: 0 15px;
    }
  `,

  iconSwiper: () => css`
    fill: ${theme.colors.white};
    cursor: pointer;
    background-color: ${theme.colors.black};
    border-radius: 50%;
    padding: 10px;
    width: 42px;
    height: 42px;
    opacity: 0.6;

    ${media.md} {
      width: 32px;
      height: 32px;
      background-color: rgba(255, 255, 255, 0.2);
      opacity: 1;
      fill: ${theme.colors.main};
    }

    &:hover {
      opacity: 1;
      background-color: ${theme.colors.red[100]};
      ${media.md} {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  `,
};
