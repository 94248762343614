import * as boardImageTitle from "assets/common/images/game/desktop/page/board/title";
import * as boardImageTopbar from "assets/common/images/game/desktop/page/board/topbar";
import { DetailProviderBoardType } from "types/game";

export const sidebarCrash: DetailProviderBoardType[] = [
  {
    provider_code: "T1G",
    provider_name: "T1G 3d Card Game",
    pictureTopBar: boardImageTopbar.desktopBoardProviderT1G,
    pictureTitle: boardImageTitle.desktopBoardTitleT1G,
    backgroundColor: "linear-gradient(to right, #9bb6fb, #37d7f9)",
    hoverBackgroundColor: "#37d7f9",
  },
];
