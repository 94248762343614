import * as boardImageTitle from "assets/common/images/game/desktop/page/board/title";
import * as boardImageTopbar from "assets/common/images/game/desktop/page/board/topbar";
import { DetailProviderBoardType } from "types/game";

export const sidebarBoard: DetailProviderBoardType[] = [
  // {
  //   provider_code: "V8",
  //   provider_name: "V8 Game Bài 3d",
  //   pictureTopbar: boardImageTopbar.desktopBoardProviderV8,
  //   pictureTitle: boardImageTitle.desktopBoardTitleV8,
  //   backgroundColor: "#000",
  //   hoverBackgroundColor: "#ffd050",
  // },
  // {
  //   provider_code: "RICH88",
  //   provider_name: "RICH88 Game Bài 3d",
  //   pictureTopbar: boardImageTopbar.desktopBoardProviderRICH88,
  //   pictureTitle: boardImageTitle.desktopBoardTitleRICH88,
  //   backgroundColor: "#601982",
  //   hoverBackgroundColor: "#ffd900",
  // },
  // {
  //   provider_code: "KM",
  //   provider_name: "KM Game Bài 3d",
  //   pictureTopbar: boardImageTopbar.desktopBoardProviderKM,
  //   pictureTitle: boardImageTitle.desktopBoardTitleKM,
  //   backgroundColor: "#fda803",
  //   hoverBackgroundColor: "#ffe77a",
  // },
  // {
  //   provider_code: "TP",
  //   provider_name: "TP Game Bài 3d",
  //   pictureTopbar: boardImageTopbar.desktopBoardProviderPT,
  //   pictureTitle: boardImageTitle.desktopBoardTitleTP,
  //   backgroundColor: "#0550bb",
  //   hoverBackgroundColor:
  //     "linear-gradient(to bottom, #11dac9 10%, #389dff 55%, #0550bb 100%)",
  // },
  // {
  //   provider_code: "T1G",
  //   provider_name: "T1G Game Bài 3d",
  //   pictureTopbar: boardImageTopbar.desktopBoardProviderT1G,
  //   pictureTitle: boardImageTitle.desktopBoardTitleT1G,
  //   backgroundColor: "linear-gradient(to right, #9bb6fb, #37d7f9)",
  //   hoverBackgroundColor: "#37d7f9",
  // },
  {
    provider_code: "JILI",
    provider_name: "JILI Card Game 3d",
    pictureTopBar: boardImageTopbar.desktopBoardProviderJILI,
    pictureTitle: boardImageTitle.desktopBoardTitleJILI,
    backgroundColor:
      "linear-gradient(to bottom, #fff468 0, #f3b720 33%, #eaa000 66%)",
    hoverBackgroundColor: "#f3b720",
  },
  // {
  //   provider_code: "MG",
  //   provider_name: "MG Game Bài 3d",
  //   pictureTopbar: boardImageTopbar.desktopBoardProviderMG,
  //   pictureTitle: boardImageTitle.desktopBoardTitleMG,
  //   backgroundColor: "#32b5e9",
  //   hoverBackgroundColor: "linear-gradient(45deg, #21e519, #32b5e9)",
  // },
  // {
  //   provider_code: "FTG",
  //   provider_name: "FTG Game Bài 3d",
  //   pictureTopbar: boardImageTopbar.desktopBoardProviderFTG,
  //   pictureTitle: boardImageTitle.desktopBoardTitleFTG,
  //   backgroundColor: "#64127e",
  //   hoverBackgroundColor: "#64127e",
  // },
];
