import { css, Theme } from "@emotion/react";

export const MemberMenuStyle = {
  self: css`
    padding: 30px 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  `,

  menuItem: css`
    width: 100%;
    height: 130px;
    border-radius: 12px;
    border: 1px solid #999999;
    display: block;
    a,
    button {
      padding: 0 15px;
      width: 100%;
      height: 100%;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;
      border-radius: inherit;
      text-align: center;
      border: none;
    }
  `,
  menuTitle: ({ fontSize }: Theme) => css`
    color: #999999;
    margin-top: 10px;
    font-size: ${fontSize[14]};
  `,
};
