export const ListMenuInfo = [
  {
    label: "About JMC88",
    link: "/info/AboutUS",
  },
  {
    label: "Instructions for depositing money",
    link: "/info/Deposit",
  },
  {
    label: "Terms and condition",
    link: "/info/rule",
  },
  {
    label: "Instructions for withdrawing money",
    link: "/info/Withdrawal",
  },
  {
    label: "Play responsibly",
    link: "/info/ResponsibleGambling",
  },
  {
    label: "Frequently asked questions",
    link: "/info/FAQ",
  },
  {
    label: "Disclaimer",
    link: "/info/Disclaimer",
  },
  {
    label: "Contact",
    link: "/info/Contact",
  },
  {
    label: "Privacy",
    link: "/info/Privacy",
  },
];
