/** @jsxImportSource @emotion/react */

import type { MenuProps } from "antd";
import { Menu } from "antd";
import { ReactComponent as EventIcon } from "assets/desktop/icons/menus/event.svg";
import { ReactComponent as HistoryIcon } from "assets/desktop/icons/menus/history-order.svg";
import { ReactComponent as MailIcon } from "assets/desktop/icons/menus/mail.svg";
import { ReactComponent as NotificationIcon } from "assets/desktop/icons/menus/notification.svg";
import { ReactComponent as RefundIcon } from "assets/desktop/icons/menus/refund.svg";
import { ReactComponent as SecuritySettingIcon } from "assets/desktop/icons/menus/security-setting.svg";
import { ReactComponent as TransactionListIcon } from "assets/desktop/icons/menus/transaction-list.svg";
import { PATH } from "contants/path";
import _map from "lodash/map";
import { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

import { MenuUserStyle } from "./index.style";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group",
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}
const items: MenuItem[] = _map(
  [
    {
      label: "Security settings",
      path: PATH.User.SecuritySetting,
      icon: <SecuritySettingIcon />,
    },
    {
      label: "Latest announcement",
      path: PATH.User.Notifications,
      icon: <NotificationIcon />,
    },
    {
      label: "Internal mail",
      path: PATH.User.InternalMail,
      icon: <MailIcon />,
    },
    {
      label: "Event area",
      path: PATH.User.Event,
      icon: <EventIcon />,
    },
    {
      label: "Transaction Details",
      path: PATH.User.Transactions,
      icon: <TransactionListIcon />,
    },
    {
      label: "Betting History",
      path: PATH.User.OrderHistory,
      icon: <HistoryIcon />,
    },
    {
      label: "To return",
      path: PATH.User.Refund,
      icon: <RefundIcon />,
    },
    // {
    //   label: "Favourite",
    //   path: PATH.User.Favourite,
    //   icon: <FavouriteIcon />,
    // },
  ],
  (item) =>
    getItem(<Link to={item.path}>{item.label}</Link>, item.path, item.icon),
);

const MenuUser = () => {
  const location = useLocation();

  const selectedKeys = useMemo(() => {
    return [location.pathname];
  }, [location]);

  return (
    <div css={MenuUserStyle.self}>
      <Menu selectedKeys={selectedKeys} items={items} />
    </div>
  );
};

export default MenuUser;
