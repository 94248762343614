import { css } from "@emotion/react";

const ModalSignInStyle = {
  wrapper: css`
    .ant-modal-content {
      width: 492px;
      height: 650px;
      border-radius: 48px;
      padding: 20px;
      background-color: #232323;
      border: 5px solid #757575;
      .ant-modal-close {
        top: -10px;
        right: -10px;

        background-color: white;
        border-radius: 50%;
        color: black !important;
      }
    }
  `,
  logo: () => css`
    width: 100%;
    height: 100%;
    object-fit: contain;
  `,
  blockCaptcha: (background: string) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    margin-right: 10px;

    background-image: url(${background});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;

    span {
      font-size: 24px;
      opacity: 0.6;
      font-style: italic;
      color: black;
    }
  `,
  eye: css`
    width: 20.25px;
    height: 18px;
    fill: white;
  `,
  forgetButton: css`
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    .link {
      color: #f00;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-decoration-line: underline;
      cursor: pointer;
    }
  `,
};
export default ModalSignInStyle;
