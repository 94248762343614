import { css, Theme } from "@emotion/react";

export const SelectCustomStyle = {
  self: ({ colors }: Theme) => css`
    & .ant-select {
      width: 100%;
      &-selector {
        height: 34px !important;
        background-color: white !important;
        outline: none !important;
        box-shadow: none !important;
        border-radius: 4px;
        .ant-select-selection-placeholder {
          display: flex;
          align-items: center;
        }
        &:hover {
          border-color: #d9d9d9 !important;
        }
      }
      &-focused {
        &:not(.ant-select-disabled) {
          & .ant-select-selector {
            border-color: #d9d9d9 !important;
          }
        }
      }
      &-selection-item {
        color: black !important;
        display: flex;
        align-items: center;
      }
      &-arrow {
        color: black;
      }
      &-clear {
        background-color: unset !important;
        color: ${colors.white} !important;
      }

      // custom validate error
      &-status-error {
        .ant-select-selector {
          border: 1px solid var(--error-color) !important;
          .ant-select-selection-placeholder {
            opacity: 1;
            color: var(--error-color);
          }
        }
        .ant-select-arrow {
          color: var(--error-color);
        }
      }

      &-disabled {
        opacity: 0.5;
      }
    }
  `,
};
