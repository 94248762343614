import {
  GameIconBai,
  GameIconCasino,
  GameIconCockFighting,
  GameIconDownloadApp,
  GameIconFishing,
  GameIconLottery,
  GameIconPromotions,
  GameIconSlots,
  GameIconSport,
} from "assets/desktop/icons";
import {
  ImageBoardGameMenu,
  ImageCasinoMenu,
  ImageCockFightingMenu,
  ImageFishingMenu,
  ImageLotteryMenu,
  ImageSlotsMenu,
  ImageSportMenu,
} from "assets/mobile/images/game";
import { GameTypeEnum } from "enums/game";

import { PATH, URL_DOWNLOAD_APP } from "./path";
export interface CategoryGameType {
  id: string;
  icon_m?: string;
  name: string;
  path: string;
  icon?: string;
  isHover?: boolean;
}

export const LIST_GAME_CATEGORY_DETAILS: CategoryGameType[] = [
  {
    id: GameTypeEnum.Sport,
    name: "Sports",
    path: PATH.SportGame,
    icon: GameIconSport,
    isHover: true,
  },
  {
    id: GameTypeEnum.LiveCasino,
    name: "Casino",
    path: PATH.LiveGame,
    icon: GameIconCasino,
    isHover: true,
  },
  {
    id: GameTypeEnum.Slot,
    name: "Slots",
    path: PATH.SlotsGame,
    icon: GameIconSlots,
    isHover: true,
  },
  {
    id: GameTypeEnum.Fishing,
    name: "Fishing",
    path: PATH.FishingGame,
    icon: GameIconFishing,
    isHover: true,
  },
  {
    id: GameTypeEnum.BoardGame,
    name: "Board Game",
    path: PATH.BoardGame,
    icon: GameIconBai,
    isHover: true,
  },
  {
    id: GameTypeEnum.CockFighting,
    name: "Cock Fighting",
    path: PATH.CockFightingGame,
    icon: GameIconCockFighting,
    isHover: true,
  },
  {
    id: GameTypeEnum.Crash,
    name: "Game Crash",
    path: PATH.CrashGame,
    icon: GameIconLottery,
    isHover: true,
  },
  {
    id: "PM",
    name: "Promotion",
    path: PATH.Promotion,
    icon: GameIconPromotions,
  },
  {
    id: "DL",
    name: "Download app",
    path: URL_DOWNLOAD_APP,
    icon: GameIconDownloadApp,
  },
  // {
  //   id: "AC",
  //   name: "Agent",
  //   path: PATH.daily,
  //   icon: GameIconAgency,
  // },
];

export const LIST_GAME_CATEGORY_DETAILS_M: CategoryGameType[] = [
  {
    id: GameTypeEnum.Slot,
    name: "Slots",
    path: PATH.SlotsGame,
    icon_m: ImageSlotsMenu,
  },
  {
    id: GameTypeEnum.LiveCasino,
    name: "Casino",
    path: PATH.LiveGame,
    icon_m: ImageCasinoMenu,
  },

  {
    id: GameTypeEnum.Fishing,
    name: "Fishing",
    path: PATH.FishingGame,
    icon_m: ImageFishingMenu,
  },
  {
    id: GameTypeEnum.BoardGame,
    name: "Board Game",
    path: PATH.BoardGame,
    icon_m: ImageBoardGameMenu,
  },
  {
    id: GameTypeEnum.CockFighting,
    name: "Cock Fighting",
    path: PATH.CockFightingGame,
    icon_m: ImageCockFightingMenu,
  },
  {
    id: GameTypeEnum.Crash,
    name: "Crash Game",
    path: PATH.CrashGame,
    icon_m: ImageLotteryMenu,
  },
  {
    id: GameTypeEnum.Sport,
    name: "Sports",
    path: PATH.SportGame,
    icon_m: ImageSportMenu,
  },
];
