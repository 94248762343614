import { css } from "@emotion/react";

export const ModalPrivacyStyle = {
  wrapper: css`
    padding: 30px 0 !important;

    .ant-modal {
      &-content {
        position: relative;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 6px !important;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
        outline: 0;
        padding: unset !important;
        overflow: hidden;
      }
    }
  `,

  mainContent: css``,

  article: css`
    padding: 15px;

    p {
      margin: 0 0 10px;
      /* word-break: break-all; */
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 16px;
      line-height: 1.42857143;
      color: #333333;
      background-color: #fff;
    }
  `,

  header: css`
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;

    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 20px;
    line-height: 1.42857143;
    color: #333333;
    font-weight: 500;
    background-color: #fff;
  `,

  footer: css`
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;

    button {
      display: inline-block;
      margin-bottom: 0;
      width: 91px;
      font-weight: normal;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      cursor: pointer;
      background-image: none;
      border: 1px solid #ccc !important;
      border-radius: 4px;
      user-select: none;

      span {
        font-size: 14px;
        line-height: 1.42857143;
        color: #333;
        background-color: #fff;
        border-color: #ccc;
      }
    }
  `,
};
