import React, { useState } from "react";

interface ReadMoreProps {
  text: string;
  maxCharCount: number;
}

const ReadMore: React.FC<ReadMoreProps> = ({ text, maxCharCount }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const toggleReadMore = () => {
    setIsTruncated(!isTruncated);
  };

  return (
    <div>
      {isTruncated ? text.slice(0, maxCharCount) : text}
      {text.length > maxCharCount && (
        <span className="read-more" onClick={toggleReadMore}>
          {isTruncated ? "...See more" : "Collapse"}
        </span>
      )}
    </div>
  );
};

export default ReadMore;
