import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const ModalSignupStyle = {
  modal: () => css`
    .ant-modal {
      &-content {
        width: 100%;
        height: calc(100% + 40px);

        border-radius: 48px;
        padding: 20px 30px;
        background-color: #232323;
        border: 5px solid #757575;
        .ant-modal-close {
          top: -10px;
          right: -10px;

          background-color: white;
          border-radius: 50%;
          color: black !important;
        }
      }
    }
  `,
  button: () => css`
    border-radius: 5px;
    padding: 6px 12px;
    background-color: #9d9d9d;
    width: 172px;
    height: 50px;
    font-size: 20px;
    color: white;
    border: transparent;
    &:hover {
      color: white !important;
    }
  `,
  linearColor: css`
    background: linear-gradient(to right, #f7ba22, ${theme.colors.main});
  `,
  blockCaptcha: (background: string) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 104px;
    height: 45px;
    background-image: url(${background});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    right: -1px;
    cursor: pointer;
    span {
      font-size: 32px;
      opacity: 0.6;
      font-style: italic;
    }
  `,
  blockInput: css`
    display: flex;

    .input-password {
      margin-bottom: 0 !important;
    }
    & .ant-form-item-control-input {
      .ant-input-password {
        background-color: #505050;
        border-radius: 30px;
        .ant-input-prefix {
          margin-left: 10px;
        }

        .ant-input {
          &::placeholder {
            color: white;
          }
          color: white;
          margin-left: 8px;
          font-size: 16px;
        }

        input {
          background-color: #505050;
          margin-left: 10px !important;
        }
      }

      & .ant-input-affix-wrapper {
        height: 47px !important;
        overflow: hidden;

        border-color: transparent !important;
      }
    }

    .ant-input {
      border-radius: 65px;
      margin-left: -5px !important;
      &::placeholder {
        font-size: 14px;
      }
    }

    .ant-form-item-control-input .ant-input-affix-wrapper input {
      border-radius: 0 !important;
    }
    .ant-form-item-control-input .ant-input-affix-wrapper {
      overflow: inherit !important;
    }
  `,
};
