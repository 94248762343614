import * as BackGroundCapcha from "assets/common/images/captcha";

export const LIST_BACKGROUND_CAPTCHA = [
  BackGroundCapcha.capcha1,
  BackGroundCapcha.capcha2,
  BackGroundCapcha.capcha3,
  BackGroundCapcha.capcha4,
  BackGroundCapcha.capcha7,
  BackGroundCapcha.capcha8,
  BackGroundCapcha.capcha9,
];
