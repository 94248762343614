/** @jsxImportSource @emotion/react */
import "swiper/css";
import "swiper/css/effect-fade";

import { UserApi } from "api/user";
import { icons } from "assets/desktop";
import LoadingCustom from "components/common/LoadingCustom";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "router";
import { Autoplay, EffectFade, Swiper as SwiperType } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { BannerType } from "types/user";

import { SwiperBannerStyle } from "./index.style";

export const SwiperBanner = () => {
  const swiperRef = useRef<SwiperType>();

  const [dataBanner, setDataBanner] = useState<Array<BannerType> | null>(null);
  const [isLoading, setIsloading] = useState<boolean>(true);

  useEffect(() => {
    getData();
  }, []);

  const prevHandler = () => {
    swiperRef.current?.slidePrev();
  };

  const nextHandler = () => {
    swiperRef.current?.slideNext();
  };

  const getData = async () => {
    setIsloading(true);
    const res = await UserApi.getBanner();

    setIsloading(false);
    setDataBanner(res.data);
  };

  const listBannerHome = dataBanner;

  const renderSwiperSlice = listBannerHome?.map((itemMap, index) => {
    return (
      <SwiperSlide key={index}>
        <img
          css={SwiperBannerStyle.image}
          loading="lazy"
          src={isMobile ? itemMap?.mobile_banner : itemMap?.web_banner}
          alt="error"
        />
      </SwiperSlide>
    );
  });

  return (
    <div css={SwiperBannerStyle.container}>
      <div css={SwiperBannerStyle.blockNavigation}>
        <icons.LeftIcon
          css={SwiperBannerStyle.iconSwiper}
          onClick={prevHandler}
        />

        <icons.RightIcon
          css={SwiperBannerStyle.iconSwiper}
          onClick={nextHandler}
        />
      </div>
      <Swiper
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        loop={true}
        simulateTouch={false}
        effect={"fade"}
        pagination={{
          clickable: false,
        }}
        modules={[Autoplay, EffectFade]}
        className="mySwiper"
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        css={SwiperBannerStyle.swiperPagination}
      >
        {isLoading ? (
          <LoadingCustom
            center
            fontSize={32}
            height={isMobile ? 112.69 : 357.55}
          />
        ) : (
          renderSwiperSlice
        )}
      </Swiper>
    </div>
  );
};
