import { LogoutOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { PATH } from "contants/path";
import { Link } from "react-router-dom";
export const listMenuProfileHeader: MenuProps["items"] = [
  {
    key: "Bet history",
    label: <Link to={PATH.User.OrderHistory}>Bet history</Link>,
  },
  {
    key: "Deposit",
    label: <Link to={PATH.User.Deposit}>Deposit</Link>,
  },
  {
    key: "Withdraw",
    label: <Link to={PATH.User.Withdraw}>Withdraw</Link>,
  },
  {
    key: "Transaction Details",
    label: <Link to={PATH.User.Transactions}>Transaction Details</Link>,
  },
  // {
  //   key: "Change withdrawal password",
  //   label: <Link to={"/"}>Change withdrawal password</Link>,
  // },
  // {
  //   key: "Membership center",
  //   label: <Link to={"/"}>Membership center</Link>,
  // },
  {
    key: "logout",
    label: <span>Log out</span>,
    icon: <LogoutOutlined />,
  },
];
