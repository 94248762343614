import { Global } from "@emotion/react";

import { DepositModalOveride, DropDownHeaderOverride } from "./css";
import { reset } from "./reset";
import { variablesCss } from "./variables";

const GlobalStyles = () => {
  return (
    <>
      <Global
        styles={[
          reset,
          variablesCss,
          DepositModalOveride,
          DropDownHeaderOverride,
        ]}
      />
    </>
  );
};

export default GlobalStyles;
