/** @jsxImportSource @emotion/react */

import { ModalTypeValue } from "enums/modalType";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useLocation, useNavigate } from "react-router-dom";
import { selectIsAuthenticated } from "store/auth";
import { openModal } from "store/master";

import { BottomTabStyle } from "./index.style";
import { ItemBottomTab, menuBottomTabs } from "./menu";

function BottomTab() {
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const isAuth = useAppSelector(selectIsAuthenticated);
  const handleClickMenu = (item: ItemBottomTab) => {
    if (!isAuth && item?.link === menuBottomTabs[4]?.link) {
      dispatch(openModal(ModalTypeValue.LOGIN));
      return;
    }
    if (item.key === "APP") {
      return window.open(item.link);
    }
    navigate(item.link);
  };

  return (
    <div css={BottomTabStyle.index}>
      <div css={BottomTabStyle.lisMenu}>
        {menuBottomTabs.map((item, index) => {
          const isActive = pathname === item.link;
          return (
            <div
              css={BottomTabStyle.itemTab(isActive)}
              key={"item-bottom-tab-" + index}
              onClick={() => handleClickMenu(item)}
            >
              <div className="icon">{item.icon}</div>
              <span className="label">{item.label}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default BottomTab;
