import { RouteObject } from "react-router-dom";

import { desktopRoutes } from "./desktopRoutes";
import { mobileRoutes } from "./mobileRoutes";

export const isMobile = /iPhone|iPad|iPod|Android|tablet/i.test(
  navigator.userAgent,
);

const routesConfig: RouteObject[] = isMobile ? mobileRoutes : desktopRoutes;

export default routesConfig;
