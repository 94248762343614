import { createAsyncThunk } from "@reduxjs/toolkit";
import { DepositApi } from "api/deposit";

export const getDepositForm = createAsyncThunk(
  "deposit/getDepositForm",
  async (username: string, { rejectWithValue }) => {
    try {
      const dataDepositForm = await DepositApi.getDepositForm(username);
      return dataDepositForm.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
