import { css } from "@emotion/react";
import { FooterImageBgLogin } from "assets/mobile/images/footer";
import { theme } from "styles/theme";

export const ModalSignInStyle = {
  modalStyle: css`
    position: fixed;
    width: 100% !important;
    height: 100%;
    left: 0;
    top: 0;
    max-width: 100% !important;

    .ant-modal-content {
      width: 100%;
      height: 100%;
      background-color: transparent;
      padding: 0 !important;

      .ant-modal-body {
        width: 100%;
        height: 100%;
      }
    }

    svg {
      fill: ${theme.colors.main};
    }
  `,

  content: css`
    background-image: url(${FooterImageBgLogin});
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  `,
  closeButton: css`
    position: absolute;
    z-index: 9;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 8px;
    top: 8px;
    cursor: pointer;
  `,
  formContainer: css`
    overflow: auto;
    top: 90vw;
    height: calc(100vh - 90vw);
  `,
  form: css`
    padding: 0 20px;

    .input-row {
      position: relative;

      .ant-form-item-explain-error {
        padding-left: 12px;
      }

      .ant-input-suffix {
        padding-right: 16px !important;
      }

      .icon {
        position: absolute;
        z-index: 99;
        height: 24px;
        width: 16px;
        top: calc((50px - 16px) / 2);
        left: 16px;
      }

      .icon-img {
        position: absolute;
        z-index: 99;
        height: 16px;
        width: 16px;
        top: calc((50px - 16px) / 2);
        left: 16px;
      }

      input {
        padding-left: 40px;
      }
    }
    .input-pass {
      .ant-input-affix-wrapper {
        input {
          padding-left: 40px !important;
        }
      }
    }
  `,
  recaptcha: css`
    margin: 12px 0;
    color: #f00;
    text-align: center;
    font-style: italic;
  `,
  note: css`
    width: 80%;
    margin: 0 auto;

    span {
      color: ${theme.colors.white};
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  `,
  buttons: css`
    display: flex;
    flex-direction: row;
    margin-top: 12px;
    justify-content: center;
    gap: 12px;
    .button {
      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 37px;
      height: auto;
      border: 0ch;

      span {
        color: ${theme.colors.white};
        text-align: center;
        font-size: 17px;
        font-weight: 400;
        line-height: 46px;
        text-transform: uppercase;
      }

      &.button-register {
        background: #404040;
        width: 134px;
        padding: 0px 31.55px 0px 30.45px;
        margin-right: 1px;
      }

      &.button-login {
        width: 185px;
        padding: 0px 43.64px 0px 44.36px;
        background: ${theme.colors.main};
      }
    }
  `,
  forgetButton: css`
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    .link {
      color: #f00;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-decoration-line: underline;
      cursor: pointer;
    }
  `,

  blockCaptcha: (background: string) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 46px;

    background-image: url(${background});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;

    span {
      font-size: 20px;
      opacity: 0.6;
      font-style: italic;
    }
  `,
};
