import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { AnnouncementResponse } from "api/announcement/type";
import { ResponseCode } from "contants/response";
import { NoInfer } from "react-redux";
import { AnnouncementType } from "types/announcement";

import { getAnnouncement } from "./announcement.thunk";

export const extraReducers = (
  builder: ActionReducerMapBuilder<NoInfer<AnnouncementType>>,
) => {
  builder
    .addCase(getAnnouncement.pending, (state: AnnouncementType) => {
      state.isLoading = true;
    })
    .addCase(
      getAnnouncement.fulfilled,
      (
        state: AnnouncementType,
        action: PayloadAction<AnnouncementResponse>,
      ) => {
        if (action.payload.status !== ResponseCode.SUCCESS) return;
        state.isLoading = false;

        const dataRes = action.payload.data;

        if (!action.payload.page) {
          state.allData = dataRes;
        }

        const currentPage = action.payload.currentPage;
        state.currentPage = currentPage;
        if (action.payload.type === 1) {
          return;
        }
        // state.data = currentPage > 1 ? [...state.data, ...dataRes] : dataRes;
        state.totalCount = action.payload.totalCount;
        state.totalPages = action.payload.totalPages;
        state.pageSize = action.payload.pageSize;
        state.data = dataRes;
      },
    )
    .addCase(getAnnouncement.rejected, (state: AnnouncementType) => {
      state.isLoading = false;
    });
};
