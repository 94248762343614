import { createSlice } from "@reduxjs/toolkit";
import { ApplicationRootState } from "types";
import { RetriveStateType } from "types/retrive";

import { extraReducers } from "./retrive.extraReducers";

export const initialState: RetriveStateType = {
  promotions: {
    isLoading: false,
    data: null,
  },
};

const retriveSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    resetRetrive: () => initialState,
  },
  extraReducers,
});

export const selectPromotionList = (state: ApplicationRootState) =>
  state.retrive.promotions;

export const { resetRetrive } = retriveSlice.actions;

export default retriveSlice.reducer;
