/** @jsxImportSource @emotion/react */
import { DownOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import { Logo } from "assets/desktop/images";
import { useModalConfirm } from "components/common/ModalConfirm";
import MESSAGE from "contants/message";
import { PATH } from "contants/path";
import { ModalTypeValue } from "enums/modalType";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useAppLogout } from "hooks/useLogout";
import { useState } from "react";
// import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { selectIsAuthenticated, selectUsername } from "store/auth";
import { openModal } from "store/master";
import { selectBalance, selectBalanceLoading, selectProfile } from "store/user";
import { getMemberBalance } from "store/user/user.thunk";

import { HeaderWebsiteStyle as styles } from "./index.style";
import { listMenuProfileHeader } from "./menu";
export const HeaderWebsite = () => {
  const dispatch = useAppDispatch();
  // const { t } = useTranslation();

  const username = useAppSelector(selectUsername);
  const isAuth = useAppSelector(selectIsAuthenticated);
  const profile = useAppSelector(selectProfile);
  const memberBalance = useAppSelector(selectBalance);
  const isLoading = useAppSelector(selectBalanceLoading);
  // const username = useAppSelector(selectUsername);

  const { contextHolder, modalConfig } = useModalConfirm();
  const onLogout = useAppLogout();
  const [isOpen, setIsOpen] = useState(false);

  // useEffect(() => {
  //   if (!username) return;
  //   dispatch(getMemberBalance({ username }));
  // }, [username]);

  const onClickMenu = (key: string) => {
    switch (key) {
      case "logout":
        onLogoutAction();
        break;
      default:
        break;
    }
  };

  const onLogoutAction = () => {
    modalConfig({
      title: MESSAGE.logout.title,
      onSuccess: () => {
        onLogout();
      },
    });
  };

  // const handleRedirect = (path: string) => {
  //   if (!isAuth) {
  //     dispatch(openModal(ModalTypeValue.LOGIN));
  //     return;
  //   }

  //   navigate(path);
  // };

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div css={styles.header}>
      <div css={styles.content}>
        <div css={styles.left}>
          <Link to={PATH.Home}>
            <img src={Logo} alt="" className="logoApp" />
          </Link>
        </div>
        <div css={styles.right}>
          <div css={styles.right_top}>
            {isAuth ? (
              <div css={styles.infoProfile}>
                <div className="info">
                  <p>Account: {profile?.username}</p>
                  <p>
                    Account wallet:
                    <span className="wallet">
                      {memberBalance?.total}
                      <SyncOutlined
                        onClick={() => {
                          if (!profile?.username) return;
                          dispatch(getMemberBalance({ username }));
                        }}
                        spin={isLoading}
                        className="icon-sync"
                      />
                    </span>
                  </p>
                </div>
                <Dropdown
                  className="dropdown"
                  menu={{
                    items: listMenuProfileHeader,
                    triggerSubMenuAction: "click",
                    style: {
                      width: 160,
                    },
                    onClick: (e) => {
                      onClickMenu(e.key);
                    },
                  }}
                  // overlayStyle={{ display: isOpen ? "block" : "none" }}
                  trigger={["click"]}
                >
                  <span onClick={handleDropdownClick}>
                    Information
                    <DownOutlined
                      className={`icon ${isOpen ? "down" : "up"}`}
                    />
                  </span>
                </Dropdown>
              </div>
            ) : (
              <>
                <Button
                  onClick={() => dispatch(openModal(ModalTypeValue.LOGIN))}
                  className="login button-custom"
                >
                  {/* {t("Signin")} */}
                  Login
                </Button>
                <Button
                  onClick={() => dispatch(openModal(ModalTypeValue.REGISTER))}
                  className="register button-custom"
                >
                  {/* {t("Signup")} */}
                  Register
                </Button>
              </>
            )}

            {/* <div className="translate">
              <img src={HPTranslateENIcon} alt="" />
            </div> */}
          </div>
          {/* <div css={styles.right_bottom}>
            <div className="item no-border">
              <span onClick={() => handleRedirect(PATH.User.Deposit)}>
                Deposit
              </span>
            </div>
            <div className="item">
              <span onClick={() => handleRedirect(PATH.User.Withdraw)}>
                Withdraw
              </span>
            </div>
            <div
              className="item"
              css={css`
                margin-right: 24px;
              `}
            >
              <span onClick={() => handleRedirect(PATH.User.Transactions)}>
                Transaction History
              </span>
            </div>

            <RenderTime />
          </div> */}
        </div>
      </div>
      {contextHolder}
    </div>
  );
};
