/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form, message, Modal } from "antd";
import { RegisterFormType } from "api/auth/type";
import { ReactComponent as RefreshIcon } from "assets/common/icons/refresh.svg";
import { icons } from "assets/mobile";
import InputCustomAuth from "components/common/InputCustomAuth";
import CheckboxCustom from "components/desktop/base/CheckboxCustom";
import InputCustom from "components/mobile/Base/InputCustom";
import InputPassword from "components/mobile/Base/InputPassword";
import { LIST_BACKGROUND_CAPTCHA } from "contants/captcha";
import {
  REGEX_EMOJI,
  REGEX_FULLNAME_SIGNUP,
  REGEX_PASSWORD,
  REGEX_USERNAME,
} from "contants/regex";
import { ResponseCode } from "contants/response";
import { ModalType, ModalTypeValue } from "enums/modalType";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { selectIsAuthenticated, selectIsLoading } from "store/auth";
import { registerMemberDetail } from "store/auth/auth.thunk";
import { openModal, setOpenModal, setOpenModalNotiMobile } from "store/master";
import { theme } from "styles/theme";
import { ApiResponse } from "types/api";
import * as yup from "yup";

import ModalNotiMobile from "../ModalNotiMobile";
import ModalRuleRegister from "../ModalRuleRegister";
import { ModalSignUpStyle } from "./index.style";
interface RegisterTypeForm extends RegisterFormType {
  approve: boolean;
}

interface State {
  valueCaptcha: string;
  numberBg: number;
  title: string;
  isOpenModal: boolean;
}
const schema = yup
  .object({
    mobile: yup
      .string()
      .trim()
      .required("Please enter your mobile phone number"),
    fullname: yup
      .string()
      .trim()
      .required("Please enter your real name")
      .matches(
        REGEX_FULLNAME_SIGNUP,
        "Start with a letter, no special characters",
      )
      .required("Please enter your real name"),
    username: yup
      .string()
      .trim()
      .lowercase()
      .required("This is a required field")
      .test("emoji", "Do not enter emojis", (username: string): boolean => {
        if (!username) return false;
        const regex = new RegExp(REGEX_EMOJI);
        return !regex.test(username);
      })
      .matches(
        REGEX_USERNAME,
        `Must be greater than 6 characters, must begin with a letter, do not include special characters.`,
      )
      .required("Please enter your account name"),
    password: yup
      .string()
      .trim()
      .matches(
        REGEX_PASSWORD,
        "More than 6 characters must include letters and numbers",
      )
      .required("Please enter a password"),
    confirm_password: yup
      .string()
      .trim()
      .matches(
        REGEX_PASSWORD,
        "More than 6 characters must include letters and numbers",
      )
      .required("Please confirm your password again")
      .test(
        "empty",
        "Confirm passwords do not match",
        (
          confirm_password: string | undefined,
          testContext: yup.TestContext<yup.AnyObject>,
        ): boolean => {
          if (!testContext.parent.password) return true;
          return testContext.parent.password === confirm_password;
        },
      ),
    email: yup
      .string()
      .email("Invalid email")
      .trim()
      .required("Please enter Email address"),
    approve: yup
      .boolean()
      .required()
      .oneOf([true], "You must be 18 years old to use this website."),
    captcha: yup.string().trim().required(),
  })
  .required();

const ModalSignUp = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsLoading);
  const [valueMobile, setValueMobile] = useState<string>("");

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    getValues,
  } = useForm<RegisterTypeForm>({
    resolver: yupResolver(schema),
    mode: "all",
  });
  const [state, setState] = useState<State>({
    valueCaptcha: "",
    numberBg: 0,
    title: "",
    isOpenModal: false,
  });

  const onSubmit = async (data: RegisterTypeForm) => {
    data.mobile = valueMobile;
    const { captcha, ...params } = data;

    if (state.valueCaptcha !== captcha) {
      setState((prev) => ({
        ...prev,
        title: "Verification code error or input error, please try again",
      }));
      return;
    }

    setState((prev) => ({
      ...prev,
      title: "",
    }));

    const res = await dispatch(registerMemberDetail(params));

    const resData = res.payload as ApiResponse;
    if (resData.status !== ResponseCode.SUCCESS) {
      message.error({
        key: "register",
        content: resData.msg,
      });
      return;
    }

    message.success({
      key: "register",
      content: resData.msg,
    });
    reset();
    dispatch(setOpenModal(ModalTypeValue.CLOSE));
    navigate("/");
  };
  const generateRandomNumbers = () => {
    const newRandomNumbers: number[] = [];

    while (newRandomNumbers.length < 4) {
      const randomNumber = Math.floor(Math.random() * 10);
      newRandomNumbers.push(randomNumber);
    }

    setState((prev) => ({
      ...prev,
      numberBg: Math.floor(Math.random() * 6),
      valueCaptcha: newRandomNumbers.join(""),
    }));
  };

  const renderTitle = () => {
    const form = getValues();

    if (!form.captcha.length) {
      setState((prev) => ({
        ...prev,
        title: "Verification code error or input error, please try again",
      }));
      return;
    }

    if (errors.captcha || !form.captcha) {
      setState((prev) => ({
        ...prev,
        title: errors.captcha?.message as string,
      }));
      return;
    }
  };

  useEffect(() => {
    generateRandomNumbers();

    if (!isAuthenticated) return;

    navigate("/");
  }, []);

  return (
    <Modal
      open
      centered
      footer={<></>}
      focusTriggerAfterClose={false}
      forceRender
      closable={false}
      css={ModalSignUpStyle.modalStyle}
      onCancel={() => dispatch(openModal(ModalTypeValue.CLOSE))}
    >
      <div
        onClick={() => dispatch(openModal(ModalTypeValue.CLOSE))}
        css={ModalSignUpStyle.closeButton}
      >
        <icons.IconFormClose />
      </div>
      <div css={ModalSignUpStyle.content}>
        <div
          css={css`
            flex: 1;
          `}
        />
        <div css={ModalSignUpStyle.formContainer}>
          <Form
            css={ModalSignUpStyle.form}
            onSubmitCapture={handleSubmit(onSubmit)}
            autoComplete="off"
          >
            <div className="input-col">
              <label>
                <icons.StarIconForm />
                <span>Username</span>
              </label>
              <InputCustom
                disabled={isLoading}
                placeholder={""}
                name={"username"}
                control={control}
                margin_bottom="6px"
                role="presentation"
                id={"username"}
                autoComplete="new-password"
                errors={errors.username?.message}
                validate_status={errors.username ? "error" : "success"}
              />
              <p className="note">
                Must Be Greater Than 5 Characters, Must Begin With A Letter, No
                Additional Characters Special.
              </p>
            </div>
            <div className="input-col input-pass">
              <label>
                <icons.StarIconForm />
                <span>Member password</span>
              </label>
              <InputPassword
                disabled={isLoading}
                placeholder={""}
                name={"password"}
                control={control}
                margin_bottom="6px"
                role="presentation"
                id={"password"}
                autoComplete="new-password"
                error={errors.password?.message}
                validate_status={errors.password ? "error" : "success"}
              />
              <p className="note">
                More than 6 characters must include letters and numbers
              </p>
            </div>
            <div className="input-col input-pass">
              <label>
                <icons.StarIconForm />
                <span>Confirm password</span>
              </label>
              <InputPassword
                disabled={isLoading}
                placeholder={""}
                name={"confirm_password"}
                control={control}
                margin_bottom="6px"
                role="presentation"
                id={"confirm_password"}
                autoComplete="new-password"
                error={errors.confirm_password?.message}
                validate_status={errors.confirm_password ? "error" : "success"}
              />
              <p className="note">Please confirm your password again</p>
            </div>
            <div className="input-col">
              <label>
                <icons.StarIconForm />
                <span>First name and last name</span>
              </label>
              <InputCustom
                disabled={isLoading}
                placeholder={""}
                name={"fullname"}
                control={control}
                margin_bottom="6px"
                role="presentation"
                id={"fullname"}
                autoComplete="new-password"
                errors={errors.fullname?.message}
                validate_status={errors.fullname ? "error" : "success"}
              />
              <p className="note">
                Write your full name, in capital letters without accents
              </p>
            </div>
            <div className="input-col">
              <label>
                <icons.StarIconForm />
                <span>Phone number</span>
              </label>
              <InputCustom
                value={valueMobile}
                disabled={isLoading}
                placeholder={""}
                name={"mobile"}
                control={control}
                margin_bottom="6px"
                role="presentation"
                type="number"
                id={"mobile"}
                autoComplete="new-password"
                errors={errors.mobile?.message}
                validate_status={errors.mobile ? "error" : "success"}
                setValueProps={(value: string) => {
                  if (value.length > 10) return;
                  const regex = new RegExp(/^[0-9]+$/);

                  if (!regex.test(value)) {
                    if (value.length === 0 && value === "") setValueMobile("");
                    return;
                  }

                  setValueMobile(value);
                }}
              />
              <p className="note">
                Enter phone number starting with 84，For example 84868888789
              </p>
            </div>
            <div className="input-col">
              <label>
                <icons.StarIconForm />
                <span>Email address</span>
              </label>
              <InputCustom
                disabled={isLoading}
                placeholder={""}
                name={"email"}
                control={control}
                margin_bottom="6px"
                role="presentation"
                id={"email"}
                autoComplete="new-password"
                errors={errors.email?.message}
                validate_status={errors.email ? "error" : "success"}
              />
              <p className="note">
                Email needs to be filled in correctly to assist in retrieving
                your password when you forget it (Obligatory)
              </p>
            </div>
            <div className="input-col">
              <label>
                <icons.StarIconForm />
                <span>Verification code</span>
              </label>
              <div
                css={css`
                  & .ant-form-item-control-input {
                    & .ant-input-affix-wrapper {
                      height: 50px !important;
                      border: 0 !important;
                    }
                  }

                  .ant-input {
                    border-radius: 0 !important;
                    margin-left: 0;
                    margin: 0 !important;
                    padding-left: 0 !important;
                    transform: translateX(-3%);
                  }
                `}
              >
                <InputCustomAuth
                  onClick={generateRandomNumbers}
                  placeholder={"Verification code"}
                  name={"captcha"}
                  control={control}
                  margin_bottom="12px"
                  id={"captcha"}
                  errors={errors.captcha?.message}
                  validate_status={errors.captcha ? "error" : "success"}
                  suffix={
                    <div
                      css={css`
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: flex-end;
                        gap: 4px;

                        svg {
                          width: 16px;
                          height: 16px;
                          fill: ${theme.colors.main} !important;
                          position: absolute;
                          right: 2px;
                          bottom: 50%;
                          transform: translateY(50%);
                        }
                      `}
                    >
                      {state.valueCaptcha && (
                        <div
                          css={ModalSignUpStyle.blockCaptcha(
                            LIST_BACKGROUND_CAPTCHA[state.numberBg],
                          )}
                          onClick={generateRandomNumbers}
                        >
                          <span>{state.valueCaptcha}</span>
                        </div>
                      )}
                      <RefreshIcon />
                    </div>
                  }
                  prefix={<></>}
                />
              </div>
            </div>
            <CheckboxCustom
              disabled={isLoading}
              name={"approve"}
              control={control}
              errors={errors.approve?.message?.toString()}
              css={ModalSignUpStyle.approve}
              validate_status={errors.approve ? "error" : "success"}
            >
              I understand{" "}
              <span
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    isOpenModal: true,
                  }))
                }
                className="terms-conditions"
              >
                User registration agreement
              </span>
            </CheckboxCustom>

            <div css={ModalSignUpStyle.groupButton}>
              <Button
                loading={isLoading}
                onClick={() => {
                  dispatch(openModal(ModalTypeValue.LOGIN));
                }}
                className="tButton btn-login"
              >
                Login
              </Button>
              <Button
                loading={isLoading}
                htmlType="submit"
                className="tButton btn-register"
                onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    title: "",
                  }));
                  renderTitle();
                  dispatch(setOpenModalNotiMobile(ModalType.NOTI_MOBILE));
                }}
              >
                <span>Register</span>
              </Button>
            </div>
          </Form>
        </div>
      </div>
      {!!state.title && <ModalNotiMobile title={state.title} />}
      <ModalRuleRegister
        isOpenModal={state.isOpenModal}
        isCancel={() =>
          setState((ev) => ({
            ...ev,
            isOpenModal: false,
          }))
        }
      />
    </Modal>
  );
};

export default ModalSignUp;
