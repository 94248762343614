import loadable from "@loadable/component";
import App from "App";
import { PATH, userPath } from "contants/path";
import AgentLayout from "layouts/Desktop/AgentLayout";
import DefaultLayout from "layouts/Desktop/DefaultLayout";
import UserLayout from "layouts/Desktop/UserLayout";
import GameBoardPage from "pages/desktop/GamesPage/BoardGame/Games";
import SubCrashGamePage from "pages/desktop/GamesPage/CrashGame/Games";
import GameSlotPage from "pages/desktop/GamesPage/SlotsPage/Game";
import { RouteObject } from "react-router-dom";

import ProtectedRoute from "./protected";

const HomePage = loadable(() => import("pages/desktop/HomePage"));
const SecuritySettingPage = loadable(
  () => import("pages/desktop/SecuritySettingPage"),
);
const HistoryOrderPage = loadable(() => import("pages/desktop/HistoryOrder"));
const AnnouncementPage = loadable(() => import("pages/desktop/Announcement"));
const PromotionPage = loadable(() => import("pages/common/PromotionPage"));
const DepositPage = loadable(() => import("pages/desktop/DepositPage"));
const WithdrawPage = loadable(() => import("pages/desktop/WithdrawPage"));
// const SubGamePage = loadable(() => import("pages/desktop/GamesPage/SubGame"));
const BoardGame = loadable(() => import("pages/desktop/GamesPage/BoardGame"));
const CrashGame = loadable(() => import("pages/desktop/GamesPage/CrashGame"));
const LiveGamePage = loadable(() => import("pages/desktop/GamesPage/LiveGame"));
const FishingPage = loadable(
  () => import("pages/desktop/GamesPage/FishingPage"),
);
const LotteryGamePage = loadable(
  () => import("pages/desktop/GamesPage/LotteryPage"),
);
const SportGame = loadable(() => import("pages/desktop/GamesPage/SportGame"));
const SlotsPage = loadable(() => import("pages/desktop/GamesPage/SlotsPage"));
const ContactUs = loadable(() => import("pages/common/ContactUs"));
const InfoApp = loadable(() => import("pages/desktop/InfoApp"));
const NotFoundPage = loadable(() => import("pages/desktop/NotFoundPage"));
const CockFightingPage = loadable(
  () => import("pages/desktop/GamesPage/CockFightingPage"),
);
const TransactionHistoryPage = loadable(
  () => import("pages/desktop/Transaction"),
);
const InternalMallPage = loadable(() => import("pages/desktop/InternalMall"));
const RefundPage = loadable(() => import("pages/desktop/RefundPage"));
const Agent = loadable(() => import("pages/desktop/Agent"));

export const desktopRoutes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        element: <DefaultLayout />,
        children: [
          {
            path: PATH.Home,
            element: <HomePage />,
          },
          {
            path: PATH.LiveGame,
            element: <LiveGamePage />,
          },
          {
            path: PATH.LotteryGame,
            element: <LotteryGamePage />,
          },
          {
            path: PATH.CategoryGameFilter,
            element: <CockFightingPage />,
          },
          {
            path: "/slots/:provider",
            element: <GameSlotPage />,
          },
          {
            path: PATH.SlotsGame,
            element: <SlotsPage />,
          },
          {
            // path: PATH.FishingGame,
            path: "/fishing/:provider",
            element: <FishingPage />,
          },
          {
            path: PATH.BoardGame,
            element: <BoardGame />,
          },
          {
            path: "/board-game/:provider",
            element: <GameBoardPage />,
          },
          {
            path: PATH.CrashGame,
            element: <CrashGame />,
          },
          {
            path: "/crash-game/:provider",
            element: <SubCrashGamePage />,
          },
          {
            path: PATH.SportGame,
            element: <SportGame />,
          },
          // {
          //   path: PATH.CategoryGame,
          //   element: <SubGamePage />,
          // },
          {
            path: PATH.Promotion,
            element: <PromotionPage />,
          },
          {
            path: PATH.Info,
            element: <InfoApp />,
          },
        ],
      },
      {
        element: <ProtectedRoute />,
        children: [
          {
            path: userPath,
            element: <UserLayout />,
            children: [
              {
                path: PATH.User.Deposit,
                element: <DepositPage />,
              },
              {
                path: PATH.User.Withdraw,
                element: <WithdrawPage />,
              },
              {
                path: PATH.User.SecuritySetting,
                element: <SecuritySettingPage />,
              },
              {
                path: PATH.User.OrderHistory,
                element: <HistoryOrderPage />,
              },
              {
                path: PATH.User.Notifications,
                element: <AnnouncementPage />,
              },
              {
                path: PATH.User.Transactions,
                element: <TransactionHistoryPage />,
              },
              {
                path: PATH.User.Event,
                element: <PromotionPage />,
              },
              {
                path: PATH.User.InternalMail,
                element: <InternalMallPage />,
              },
              {
                path: PATH.User.Refund,
                element: <RefundPage />,
              },

              {
                path: "*",
                element: <h1>404</h1>,
              },
            ],
          },
        ],
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
      {
        path: PATH.ContactUs,
        element: <ContactUs />,
      },
      {
        element: <AgentLayout />,
        children: [
          {
            path: PATH.daily,
            element: <Agent />,
          },
        ],
      },
    ],
  },
];
