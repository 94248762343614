/** @jsxImportSource @emotion/react */

import { Button, Modal } from "antd";

import { ModalPrivacyStyle } from "./style.index";

interface Props {
  isOpen: boolean;
  onCancel: () => void;
}

const ModalPrivacy = ({ isOpen, onCancel }: Props) => {
  return (
    <Modal
      open={isOpen}
      footer={null}
      onCancel={onCancel}
      css={ModalPrivacyStyle.wrapper}
      closable={false}
      centered
      title={null}
      width={598}
    >
      <div css={ModalPrivacyStyle.mainContent}>
        <h4 css={ModalPrivacyStyle.header}>User registration agreement</h4>
        <div css={ModalPrivacyStyle.article}>
          <p>
            Congratulations on becoming a new member of JMC88 - the leading
            reputable bookmaker in the online gaming market, fast deposits and
            withdrawals, great incentives, an ideal place for players to
            participate in entertainment, quick registration and login, There
            are also many attractive game products. Hurry and join to experience
            the number 1 top entertainment service!
          </p>
          <p>
            After you successfully register for a member account, you will be
            able to use all of our company's services. When using those
            services, it means that you agree and comply with the regulations
            set forth by our company such as (Casino game rules, sports betting
            rules, notices, and other items). note) and some related notes. All
            related services such as products, customer service, software
            systems, betting, games, casino, network structure of our company
            are supervised by the Gambling and Entertainment Commission of the
            United States. Philippines. Therefore, when you use our services,
            you must comply with the legal ordinances of your local country. Any
            violation is not within the scope of our service responsibility.
          </p>

          <p>
            For the services that our company has provided, after you agree to
            register, please follow the order of instructions to provide
            personal information in the most accurate and truthful way. ; When
            your personal information is inaccurate, please contact 24/7 Online
            Customer Care to update it to maintain the most accurate and
            complete personal information. If the registered information is
            inaccurate or impersonates another person's information, causing
            harm to that person's rights or violating the law, you must be fully
            responsible before the law for that behavior. When you have provided
            inaccurate personal information or your personal information has
            abnormalities and refuses to update it, leading to a mismatch in the
            personal information initially registered, our company has the right
            to terminate your personal information. terminate your membership
            status and benefits.
          </p>
          <p>
            Members must keep their account and password information
            confidential and are responsible for all operations after the
            account and password are logged in. To ensure members' own rights,
            please do not disclose or provide your account and password to
            others and should not lend or transfer it to others for use in any
            form. We are committed to protecting customer privacy to provide
            safe games. Information collected from this website will help us
            serve you with the best services. We will not sell or rent customer
            information to third parties. Personal information provided by
            customers will be securely encrypted using SSL128 technology and the
            information will be stored very securely. , no leakage when
            operating the system. In case there is an opportunity to come into
            contact with customer personal information with the help of partner
            fees, you must still comply with the privacy terms we set out.
          </p>
          <p>
            Members are strictly prohibited from registering an account multiple
            times, for any reason, we can block or close any account. Every
            player, every address, every email address, every phone number, the
            same payment card/credit card number, and shared computing
            environments (such as Internet cafes, places where public computers
            are used) account, etc.) has only one account data. If a member
            intentionally creates multiple accounts, JMC88 Entertainment
            reserves the right to cancel or freeze the account balance without
            prior notice. Regardless of whether it is an individual or group, if
            there is any threat or abuse towards JMC88, we reserve the right to
            cancel and withdraw player accounts.
          </p>
          <p>
            In order to avoid controversy when betting on games as well as
            football competitions, members are requested to refer to the
            relevant rules in detail, once they have started using the service.
            This deal is considered to have been agreed to with all of our
            regulations.
          </p>
          <p>
            Some country regions have unclear regulations regarding the legality
            of online gambling, and some countries even have regulations
            specifically stating that this type of gambling is illegal. We do
            not intentionally invite any individuals to use our services in
            areas and countries where this type of service is illegal.
            Individuals using this service must clearly determine whether the
            region or country in which the service is being used is illegal or
            not and take full responsibility. Individuals using this service
            should comply with relevant local ordinances. If there is any
            violation, we will not be responsible.
          </p>
          <p>
            When a player's registration information is disputed, to ensure the
            interests of both parties and prevent identity theft, reserves the
            right to require customers to provide us with complete and effective
            files , and determine whether customers are eligible for any of our
            Offers.
          </p>
          <p>In any case, the final decision belongs to JMC88.</p>
        </div>

        <div css={ModalPrivacyStyle.footer}>
          <Button onClick={() => onCancel()}>I understand</Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalPrivacy;
