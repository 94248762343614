import { Theme } from "@emotion/react";

export const theme = {
  colors: {
    white: "#FFFFFF",
    black: "#000000",
    background: "#1c1c1c",
    main: "#C9975A",
    neutrals: {
      50: "#F5F5F9",
      100: "#E5E5E6",
      200: "#CACACE",
      250: "#d4d4d4",
      300: "#AFAFB5",
      350: "#dddddd",
      370: "#c0c0c0",
      380: "#e6e6e6",
      400: "#95959D",
      500: "#7B7B84",
      600: "#636369",
      650: "#adadad",
      700: "#4A4A4F",
      750: "#3a3a3a",
      800: "#313135",
      850: "#333333",
      900: "#1C1C1E",
      950: "#111111",
    },

    primary: {
      50: "#a6a8cc",
      100: "#9196AF",
      200: "#c3b2e5",
      300: "#8385ac",
      500: "#363861",
      600: "#3d4668",
      650: "#1d2841",
      700: "#191b44",
      900: "#050623",
      1000: "#2699FB",
    },
    secondary: {
      50: "#f9e9cb",
      100: "#f9e2b3",
      200: "#f9d389",
      300: "#FFD050",
      400: "#F1C300",
      450: "#FFB627",
      500: "#FFB53E",
      600: "#f4c159",
      650: "#FDC80B",
      700: "#ffb31a",
      800: "#f6a624",
      900: "#f49a25",
      950: "#fc8f00",
      1000: "#ff9002",
      1100: "#f9a631",
      1200: "#ff5f0c",
    },
    gray: {
      0: "#f0f0f0",
      10: "#c8c8ca",
      100: "#AFAFB5",
      150: "#E0E0E0",
      200: "#95959D",
      250: "#3c4048",
      300: "#636369",
      350: "#2b2b2b",
    },

    red: {
      100: "#ff4335",
      150: "#FF000F",
      200: "#BD161D",
    },

    gradient: {
      1: "linear-gradient(261.81deg, #050623 0%, #A6A8CC 100%)",
      2: "linear-gradient(261.81deg, #5C69FF 0%, #801EFB 100%)",
      3: "linear-gradient(261.81deg, #FFB31A 0%, #F9E9CB 100%)",
      4: "linear-gradient(0deg, #fc8f01 0%, #ffb534 100%)",
    },
  },
  fontSize: {
    13: "13px",
    14: "14px",
    15: "15px",
    16: "16px",
    18: "18px",
    20: "20px",
    24: "24px",
    32: "32px",
    36: "36px",
    40: "40px",
    48: "48px",
    64: "64px",
    96: "96px",
  },
} as Theme;
