import { css } from "@emotion/react";

export const CheckboxCustomStyle = {
  container: css`
    .ant-form-item-explain {
      text-align: left;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: blue;
      border-color: blue;
    }
  `,
};
