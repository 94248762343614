import { css } from "@emotion/react";
import { DetailProviderSlotType } from "types/game";

export const SubSlotsGameStyle = {
  wrapper: css`
    background-color: white;
    padding-bottom: 15px;
  `,

  header: css`
    width: 800px;
    margin: 0 auto;
    color: black;
  `,

  filter: (detailProvider: DetailProviderSlotType) => css`
    background: ${detailProvider.backgroundColor};
    height: 50px;
    margin-bottom: 15px;
  `,

  listFilter: css`
    width: 800px;
    display: flex;
    gap: 10px;
    align-items: center;
    height: 100%;
    margin: 0 auto;
  `,

  itemFilter: (detailProvider: DetailProviderSlotType) => css`
    padding: 0 15px;
    border-radius: 15px;
    font-size: 15px;
    line-height: 30px;
    background: ${detailProvider.hoverBackgroundColor};
    color: white;
    cursor: pointer;
  `,

  titleSearchWrap: css`
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,

  listSubGameWrap: css`
    width: 800px;
    margin: 0 auto;
    color: black;
  `,

  listSubGame: css`
    margin-top: 15px;
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 10px;
  `,

  cardGame: (detailProvider: DetailProviderSlotType) => css`
    padding: 8px;
    border: ${`1px solid #000`};
    border-radius: 10px;
    cursor: pointer;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      border-radius: 10px;

      display: flex;
      align-items: center;
      justify-content: center;

      background-color: #0009;

      opacity: 0;
      visibility: hidden;
      transition: 0.3s;
      cursor: pointer;

      button {
        background: ${detailProvider.hoverBackgroundColor};
        span {
          padding: 5px 20px;
          color: white;
        }
        &:hover {
          background: ${detailProvider.hoverBackgroundColor} !important;

          span {
            color: white;
          }
        }
      }
    }

    &:hover {
      border-color: ${detailProvider.hoverBackgroundColor};
      background-color: ${detailProvider.hoverBackgroundColor};
      transition: 0.3s ease-in-out;

      p {
        color: white;
      }

      .overlay {
        opacity: 1;
        visibility: visible;
        transition: 0.3s;

        svg {
          transform: scale(1.2);
          transition: transform 0.34s ease-in-out;
        }
      }
    }
  `,

  cardInner: css`
    display: flex;
    flex-direction: column;
    gap: 4px;
  `,

  cardWrap: css`
    position: relative;
  `,

  gameName: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,

  paginate: css`
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    margin-top: 20px;
  `,
};
