/** @jsxImportSource @emotion/react */

import { Checkbox, ConfigProvider, Form } from "antd";
import { CheckboxProps } from "antd/es/checkbox";
import { ValidateStatus } from "antd/es/form/FormItem";
import { Controller } from "react-hook-form";
import { Control, FieldValues } from "react-hook-form/dist/types";

import { CheckboxCustomStyle } from "./index.style";

interface CheckboxCustomProps extends CheckboxProps {
  name?: string;
  padding?: string;
  control?: Control<FieldValues | any>;
  validate_status?: ValidateStatus;
  errors?: string;
  width?: string;
}

const CheckboxCustom = (props: CheckboxCustomProps) => {
  const { name = "", control, errors, validate_status, width, padding } = props;
  return (
    <div css={CheckboxCustomStyle.container}>
      {control ? (
        <Form.Item
          help={errors}
          validateStatus={validate_status}
          style={{
            padding: padding ? padding : "10px 0",
            width: width ? width : "100%",
          }}
        >
          <Controller
            name={name}
            control={control}
            defaultValue={false}
            render={({ field: { onChange, value } }) => (
              <ConfigProvider
                theme={{
                  token: {
                    // colorPrimary: "red",
                  },
                }}
              >
                <Checkbox
                  {...props}
                  onChange={(value) => onChange(value.target.checked)}
                  checked={value}
                >
                  {props.children}
                </Checkbox>
              </ConfigProvider>
            )}
          />
        </Form.Item>
      ) : (
        <Checkbox {...props}>{props.children}</Checkbox>
      )}
    </div>
  );
};

export default CheckboxCustom;
