export enum GameTypeEnum {
  LiveCasino = "LC",
  Slot = "SL",
  Sport = "SB",
  Lottery = "LT",
  BoardGame = "BG",
  Fishing = "FH",
  Board = "BG",
  CockFighting = "CF",
  Crash = "BGC",
}

export enum SubGameEnum {
  Lobby = 1,
}
