/** @jsxImportSource @emotion/react */

import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import { ReactComponent as RefreshIcon } from "assets/common/icons/refresh.svg";
import { useAppDispatch } from "hooks/app-hook";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectUsername } from "store/auth";
import { selectBalance, selectBalanceLoading } from "store/user";
import { getMemberBalance } from "store/user/user.thunk";

import { SurplusStyle } from "./index.style";

const Surplus = () => {
  const dispatch = useAppDispatch();
  const username = useSelector(selectUsername);
  const { total } = useSelector(selectBalance);
  const isLoading = useSelector(selectBalanceLoading);

  const refreshBalance = () => {
    if (isLoading) return;
    dispatch(getMemberBalance({ username }));
  };

  useEffect(() => {
    if (total) {
      return;
    }
    dispatch(getMemberBalance({ username }));
  }, []);

  return (
    <div css={SurplusStyle.self}>
      <Avatar
        size={60}
        icon={<UserOutlined style={{ color: "#545454" }} />}
        style={{ backgroundColor: "#fff" }}
        src={null}
        alt="avatar-user"
      />
      <div css={SurplusStyle.username}>Account {username}</div>
      <div css={SurplusStyle.surplus}>
        <span>Surplus: {total}</span>
        <RefreshIcon
          css={SurplusStyle.refresh}
          onClick={refreshBalance}
          data-loading={isLoading && "loading"}
        />
      </div>
    </div>
  );
};

export default Surplus;
