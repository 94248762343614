import { mobileSportUG } from "assets/common/images/game/mobile/sports";
import { SubGameType } from "types/game";

export const dataSportsMobile: SubGameType[] = [
  {
    game_code: "",
    picture: mobileSportUG,
    provider_code: "PIN",
    type: "SB",
    name: "PINNACLE sport",
    featured: "",
    new_arrive: "",
  },
];
