export enum ModalTypeValue {
  LOGIN = "LOGIN",
  REGISTER = "REGISTER",
  FORGET_PASSWORD = "FORGET_PASSWORD",
  CLOSE = "",
  BANK = "Bank",
  ANNOUNCEMENT = "Announcement",
  MOBILE_NEWS = "MOBILE_NEWS",
  NOTIGAME = "NOTIGAME",
}
export enum ModalType {
  CLOSE_NOTI = "",
  NOTI = "NOTI",
  NOTI_MOBILE = "NOTI_MOBILE",
  CLOSE_NOTI_MOBILE = "",
  CLOSE_FORGOT = "",
  FORGOT = "FORGOT",
}
