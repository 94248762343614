/** @jsxImportSource @emotion/react */

import { AuthApi } from "api/auth";
import BottomTab from "components/mobile/BottomTab";
import { HeaderMobile } from "components/mobile/Header";
import ModalGroup from "components/mobile/ModalGroup";
import { ResponseCode } from "contants/response";
import { useAppSelector } from "hooks/app-hook";
import { useAppLogout } from "hooks/useLogout";
import { useCallback, useEffect, useRef } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import { selectToken, selectUsername } from "store/auth";
import { outletContextType } from "types";

import { LayoutStyle } from "./index.style";
const DefaultLayout = () => {
  const { openNotification } = useOutletContext<outletContextType>();

  const username = useAppSelector(selectUsername);
  const token = useAppSelector(selectToken);

  const onLogout = useAppLogout();

  const appRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const fetchVerifyToken = useCallback(async () => {
    if (!username) return;
    if (!token) return;

    const response = await AuthApi.verifyToken(username, {
      username: username,
      token: token,
    });

    if (response.status !== ResponseCode.SUCCESS) {
      onLogout();
      return;
    }
  }, [username, token]);

  useEffect(() => {
    if (!username) {
      clearInterval(appRef.current);
      return;
    }

    appRef.current = setInterval(fetchVerifyToken, 30000);
  }, [username, token]);

  return (
    <div css={LayoutStyle.root}>
      <HeaderMobile />
      <main css={LayoutStyle.main} id="main__default-layout main">
        <Outlet context={{ openNotification } as outletContextType} />
      </main>
      <ModalGroup />
      <BottomTab />
    </div>
  );
};

export default DefaultLayout;
