import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const FishingGameMobileStyle = {
  wrapper: css`
    margin-bottom: 60px;
  `,

  empty: css`
    height: 500px !important;
    justify-content: center !important;
    display: flex !important;
    flex-direction: column !important;

    .ant-empty-description {
      color: ${theme.colors.white} !important;
    }
  `,
  listSubGame: css`
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 12px;
    column-gap: 7px;

    padding: 8px 10px;
  `,

  cardSubGame: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    img {
      width: 100%;
      aspect-ratio: 1 / 1;
      object-fit: cover;
    }
  `,

  nameCardItem: css`
    width: 100%;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14.4px;

    color: ${theme.colors.white};
    text-align: center;

    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  `,

  titleSubGame: css`
    margin-bottom: 5px;
    padding: 0 5px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      background: ${theme.colors.red[150]};
      height: 2px;
      width: 360px;

      bottom: 0px;
      left: 15px;
    }
    span {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;

      color: ${theme.colors.white};
      background-color: ${theme.colors.red[150]};
      border-radius: 10px;
      padding: 8px 5px;
    }
  `,

  filterGame: {
    index: css`
      padding: 11.69px 7.8px;
      display: flex;
      flex-direction: column;
      gap: 8px;
    `,

    boxWrap: css`
      display: flex;
      gap: 6px;
    `,

    search: css`
      input {
        border-radius: 4px !important;
        &::placeholder {
          border-color: ${theme.colors.white} !important;
        }
      }
      .ant-input-affix-wrapper {
        input {
          padding: 0 6px;
        }
      }
    `,
    select: css`
      flex: 1;

      border-radius: 5px;
      color: #757575;
    `,
  },

  boxFilter: (isSelected: boolean) => css`
    display: flex;
    align-items: center;

    padding: 8px 17px;
    border-radius: 4px;
    background-color: ${theme.colors.secondary[450]};
    opacity: ${isSelected ? "1" : "0.4"};
    span {
      color: ${theme.colors.white};
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 10px;
    }
  `,
};
