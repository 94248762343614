import { css } from "@emotion/react";

export const HeaderStyle = {
  index: css`
    background: rgba(17, 17, 17, 1);
  `,
  content: css`
    position: relative;
    .logo {
      text-align: center;
      padding: 10px 0;
      img {
        width: 140px;
        height: 100%;
        object-fit: contain;
      }
    }
    .download {
      position: absolute;
      top: 0;
      right: 0;

      svg {
        fill: aqua !important;
      }
    }
  `,
};
