import * as fishing from "assets/common/images/game/desktop/menu/fishing";
import { SubGameType } from "types/game";

export const dataFishing: SubGameType[] = [
  {
    game_code: "",
    picture: fishing.desktopFishingJILI,
    provider_code: "JILI",
    type: "FH",
    name: "",
    featured: "",
    new_arrive: "",
  },
  {
    game_code: "",
    picture: fishing.desktopFishingJDB,
    provider_code: "JDB",
    type: "FH",
    name: "",
    featured: "",
    new_arrive: "",
  },
  {
    game_code: "",
    picture: fishing.desktopFishingCQ9,
    provider_code: "CQ9",
    type: "FH",
    name: "",
    featured: "",
    new_arrive: "",
  },
  {
    game_code: "",
    picture: fishing.desktopFishingFC,
    provider_code: "FC",
    type: "FH",
    name: "",
    featured: "",
    new_arrive: "",
  },
];
