import loadable from "@loadable/component";
import App from "App";
import { PATH, userPath } from "contants/path";
import DefaultLayout from "layouts/Mobile/DefaultLayout";
import MUserLayout from "layouts/Mobile/MUserLayout";
import CasinoGameMobile from "pages/mobile/CasinoGame";
import { CockFightingMobileGame } from "pages/mobile/CockFightingGame";
import FishingGame from "pages/mobile/FishingGame";
import LotteryMobile from "pages/mobile/LotteryGame";
import { MMessagePage } from "pages/mobile/MMessagePage";
import SportsGameMobile from "pages/mobile/SportsGame";
import SubGameNewMobile from "pages/mobile/SubGameNew";
import { RouteObject } from "react-router-dom";

import ProtectedRoute from "./protected";

const HomePage = loadable(() => import("pages/mobile/Homepage"));
const FinancialPage = loadable(() => import("pages/mobile/FinancialPageNew"));
const Announcement = loadable(() => import("pages/mobile/Announcement"));
const ContactUs = loadable(() => import("pages/common/ContactUs"));
// const CategoryGame = loadable(
//   () => import("pages/mobile/GamePage/CategoryGame"),
// );
const TransactionHistory = loadable(
  () => import("pages/mobile/TransactionHistory"),
);
const MSecuritySettingPage = loadable(
  () => import("pages/mobile/MSecuritySettingPage"),
);
const BettingHistory = loadable(() => import("pages/mobile/BettingHistory"));
const PromotionPage = loadable(() => import("pages/common/PromotionPage"));
const SigninPage = loadable(() => import("pages/mobile/SigninPage"));
const SignupPage = loadable(() => import("pages/mobile/SignupPage"));
const RefundPage = loadable(() => import("pages/mobile/RefundPage"));
const MForgotPassByPhone = loadable(
  () => import("pages/mobile/MForgotPassByPhone"),
);
const ForgetPassword = loadable(() => import("pages/mobile/ForgetPassword"));
const AgentPage = loadable(() => import("pages/mobile/AgentPage"));

export const mobileRoutes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        element: <DefaultLayout />,
        children: [
          {
            path: "/",
            element: <HomePage />,
          },
          {
            path: PATH.daily,
            element: <AgentPage />,
          },
          {
            path: PATH.User.Announcement,
            element: <Announcement />,
          },
          {
            path: PATH.User.Financial,
            element: <FinancialPage />,
          },
          // {
          //   path: PATH.CategoryGameFilter,
          //   element: <CategoryGame />,
          // },
          // {
          //   path: PATH.CategoryGame,
          //   element: <CategoryGame />,
          // },
          {
            path: PATH.CategoryGame,
            element: <SubGameNewMobile />,
          },
          {
            path: PATH.SportGame,
            element: <SportsGameMobile />,
          },
          {
            path: PATH.CockFightingGame,
            element: <CockFightingMobileGame />,
          },
          {
            path: PATH.LiveGame,
            element: <CasinoGameMobile />,
          },
          {
            path: PATH.FishingGame,
            element: <FishingGame />,
          },
          {
            path: PATH.LotteryGame,
            element: <LotteryMobile />,
          },

          {
            path: PATH.Promotion,
            element: <PromotionPage />,
          },
          {
            element: <ProtectedRoute />,
            children: [
              {
                path: userPath,
                element: <MUserLayout />,
                children: [
                  {
                    path: PATH.User.SecuritySetting,
                    element: <MSecuritySettingPage />,
                  },
                  {
                    path: PATH.User.BettingRecord,
                    element: <BettingHistory />,
                  },
                  {
                    path: PATH.User.TransactionHistory,
                    element: <TransactionHistory />,
                  },
                  {
                    path: PATH.User.Event,
                    element: <PromotionPage />,
                  },
                  {
                    path: PATH.User.InternalMail,
                    element: <MMessagePage />,
                  },
                  {
                    path: PATH.User.Refund,
                    element: <RefundPage />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: PATH.ContactUs,
        element: <ContactUs />,
      },
      {
        path: PATH.Account.login,
        element: <SigninPage />,
      },
      {
        path: PATH.Account.register,
        element: <SignupPage />,
      },
      {
        path: PATH.forgotPassword,
        element: <MForgotPassByPhone />,
      },
      {
        path: PATH.Account.forgetPassword,
        element: <ForgetPassword />,
      },
    ],
  },
];
