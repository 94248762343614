import { useAppSelector } from "hooks/app-hook";
import { selectDetailProviderCrash } from "store/game";

import { RenderGameBoard } from "./RenderGameBoard";

const SubCrashGamePage = () => {
  const detailProvider = useAppSelector(selectDetailProviderCrash);

  return <RenderGameBoard detailProvider={detailProvider} />;
};

export default SubCrashGamePage;
